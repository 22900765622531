/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { ComponentType } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, compose, Dispatch, AnyAction } from 'redux';
import { isEmpty } from 'lodash';
import { compile } from 'path-to-regexp';

import { Incident, Service } from 'types';
import { getIncident, getIncidentMetaInfo, IncidentMetaInfo, incidentActions } from 'state';
import { INCIDENT_STATUS_TYPES, ROUTE_URLS } from 'utils';
import { FooterActions, Button, ButtonGroup } from 'components';
import { useEnsureOperationalServices } from '../../../IncidentEdit/hooks';

import styles from './Actions.module.scss';

const editIncidentPath = compile(ROUTE_URLS.incidentEdit);

type StateToProps = {
  incidentMetaInfo: IncidentMetaInfo;
  incident: Incident;
};

type DispatchToProps = {
  actions: {
    deleteIncident: ({ id: Id }) => Promise<Incident>;
    publishIncident: ({ id: Id }) => Promise<Incident>;
    archiveIncident: ({ id: Id }) => Promise<Incident>;
    fetchIncident: ({ id: Id }) => Promise<Incident>;
    updateIncident: ({ id: Id }) => Promise<Incident>;
  };
};

const Actions = ({
  actions: { archiveIncident, publishIncident, deleteIncident, fetchIncident, updateIncident },
  incident: { id, services, draft, status },
  incidentMetaInfo: { isSaving, isDeleting },
  incident,
  history: { push },
}: StateToProps & DispatchToProps & RouteComponentProps) => {
  const ensureOperationalServices = useEnsureOperationalServices();

  const publish = () => {
    publishIncident({ id }).then(() => fetchIncident({ id }));
  };

  const performDelete = () => {
    deleteIncident({ id }).then(() => push(ROUTE_URLS.incidents));
  };

  const edit = () => {
    push(editIncidentPath({ id }));
  };

  const archive = () => {
    const performArchive = () => archiveIncident({ id }).then(() => fetchIncident({ id }));

    const performArchiveWithUpdate = (services?: Service[]) => {
      const values = services ? { ...incident, services } : incident;

      updateIncident({ id, ...values }).then(performArchive);
    };

    ensureOperationalServices(incident, performArchiveWithUpdate, performArchive, false);
  };

  const areServicesEmpty = isEmpty(services);
  const isArchived = status === INCIDENT_STATUS_TYPES.ARCHIVED;
  const isDisabled = isSaving || isDeleting;

  return (
    <FooterActions>
      <div className={styles.container}>
        <div className={styles.buttonWrapper}>
          <ButtonGroup isHidden={draft} placement="top">
            <Button
              variant="outlined"
              className={styles.button}
              onClick={() => push(ROUTE_URLS.incidents)}
              disabled={isDisabled}
              isLoading={isSaving}
            >
              <Trans i18nKey="COMMON.CLOSE" />
            </Button>

            {draft && (
              <div className={styles.buttonWrapper}>
                <Button
                  variant="outlined"
                  className={styles.button}
                  onClick={performDelete}
                  disabled={isDisabled}
                  isLoading={isDeleting}
                >
                  <Trans i18nKey="COMMON.DELETE" />
                </Button>
              </div>
            )}
          </ButtonGroup>
        </div>

        {!isArchived && (
          <div className={styles.buttonWrapper}>
            {!draft && (
              <Button
                variant="outlined"
                className={styles.button}
                onClick={archive}
                isLoading={isSaving}
                disabled={isDisabled}
              >
                <Trans i18nKey="COMMON.ARCHIVE" />
              </Button>
            )}

            <Button
              variant={draft ? 'outlined' : 'contained'}
              className={styles.button}
              onClick={edit}
              isLoading={isSaving}
              disabled={isDisabled}
            >
              <Trans i18nKey="COMMON.EDIT" />
            </Button>

            {draft && (
              <Button
                variant="contained"
                className={styles.button}
                onClick={publish}
                isLoading={isSaving}
                disabled={areServicesEmpty || isDisabled}
              >
                <Trans i18nKey="COMMON.PUBLISH" />
              </Button>
            )}
          </div>
        )}
      </div>
    </FooterActions>
  );
};

const mapStateToProps = state => ({
  incidentMetaInfo: getIncidentMetaInfo(state),
  incident: getIncident(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators<(dispatch: Dispatch) => void, any>(incidentActions, dispatch),
});

export default compose<any>(
  withRouter,
  connect<StateToProps, DispatchToProps, RouteComponentProps>(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Actions) as ComponentType;
