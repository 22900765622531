/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: helpers.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';

import { formatName, formatTime } from 'utils';
import { ContentWithTooltip } from 'components';

const getAllServicesNames = (services = [], newServices = []) => {
  const servicesNames =
    [...services, ...newServices].map(({ name, region }) => `(${region}) ${name}`).join(', ') ||
    '-';

  return <ContentWithTooltip>{servicesNames}</ContentWithTooltip>;
};

export const FIELDS_MAP = [
  {
    name: 'id',
    trans: 'INCIDENT.SUMMARY.FIELDS.ID',
  },
  {
    name: 'services',
    trans: 'INCIDENT.SUMMARY.FIELDS.SERVICES',
    renderer: getAllServicesNames,
  },
  {
    name: 'createdAt',
    trans: 'INCIDENT.SUMMARY.FIELDS.CREATED_ON',
    renderer: formatTime,
  },
  {
    name: 'createdBy',
    trans: 'INCIDENT.SUMMARY.FIELDS.CREATED_BY',
    renderer: formatName,
  },
  {
    name: 'updatedAt',
    trans: 'INCIDENT.SUMMARY.FIELDS.UPDATED_ON',
    renderer: formatTime,
  },
  {
    name: 'updatedBy',
    trans: 'INCIDENT.SUMMARY.FIELDS.UPDATED_BY',
    renderer: formatName,
  },
];
