/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: getPasswordRules.js
 *
 * @author: Panasonic, developer
 *
 */

import { memoize } from 'lodash';

import { passwordRules } from 'utils';

const { upperCaseChar, lowerCaseChar, numericChar, specialChar } = passwordRules;

export const getPasswordRules = memoize((password: string) => [
  {
    label: 'COMMON.MUST_HAVE',
    rules: [
      {
        name: 'VALIDATION_RULES.MIN_CHARACTERS',
        nameParams: { count: 8 },
        checked: password.length >= 8,
      },
    ],
  },
  {
    label: 'COMMON.MEET_CRITERIA',
    labelParams: { count: 3 },
    rules: [
      {
        name: 'VALIDATION_RULES.UPPER_CASE',
        checked: upperCaseChar.test(password),
      },
      {
        name: 'VALIDATION_RULES.LOWER_CASE',
        checked: lowerCaseChar.test(password),
      },
      {
        name: 'VALIDATION_RULES.DIGITS',
        nameParams: { count: 1 },
        checked: numericChar.test(password),
      },
      {
        name: 'VALIDATION_RULES.SPECIAL_CHARACTERS',
        nameParams: { count: 1 },
        checked: specialChar.test(password),
      },
    ],
  },
]);
