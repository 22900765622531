/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { Trans } from 'react-i18next';
import { object } from 'prop-types';

const ThContent = ({ column }) => (
  <span>
    <Trans>{column.name}</Trans>
  </span>
);

ThContent.propTypes = { column: object.isRequired };

export default ThContent;
