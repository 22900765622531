/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { useCallback, useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Form, reduxForm, InjectedFormProps, getFormValues } from 'redux-form';
import { get, omit } from 'lodash';
import { parse } from 'query-string';

import {
  requiredValidator,
  spacesValidator,
  minLengthValidator,
  passwordStrengthValidator,
  passwordsMatchValidator,
  ROUTE_URLS,
} from 'utils';
import { resetPasswordCreator } from 'state';
import { Field, PasswordInputField, SimplePage, Button, Popper, Checklist } from 'components';
import { getPasswordRules } from './getPasswordRules';

import styles from './ResetPassword.module.scss';

const FORM = 'resetPassword';
const minLength = minLengthValidator(8);
const getSetPasswordFormValues = getFormValues(FORM);

const usePasswordRules = formValues => {
  const password = get(formValues, 'password', '');

  const [anchorEl, setAnchorEl] = useState(null);
  const [rules, setRules] = useState([]);

  // @ts-ignore
  useEffect(() => setRules(getPasswordRules(password)), [password, setRules]);

  const onPasswordFocus = useCallback(({ currentTarget }) => setAnchorEl(currentTarget), []);
  const onPasswordBlur = useCallback(() => setAnchorEl(null), []);

  return {
    anchorEl,
    onPasswordFocus,
    onPasswordBlur,
    isOpenPopper: Boolean(anchorEl),
    rules,
  };
};

interface Credentials {
  password: string;
  token: string;
}

interface Props {
  resetPassword: (params: Credentials) => Promise<void>;
  onSubmitSuccess: () => any;
}

const ResetPassword = ({
  invalid,
  submitting,
  handleSubmit,
  resetPassword,
  formValues,
}: InjectedFormProps<Props>) => {
  const { anchorEl, onPasswordFocus, onPasswordBlur, isOpenPopper, rules } = usePasswordRules(
    formValues,
  );

  return (
    <SimplePage title="AUTH.RESET_PASSWORD.TITLE" description="AUTH.RESET_PASSWORD.HINT">
      <Form
        onSubmit={handleSubmit(values => resetPassword(omit(values, 'confirmPassword')))}
        className={styles.form}
      >
        <Field
          name="password"
          type="password"
          onFocus={onPasswordFocus}
          onBlur={onPasswordBlur}
          placeholder="COMMON.PASSWORD"
          component={PasswordInputField}
          validate={[requiredValidator, passwordStrengthValidator, spacesValidator, minLength]}
        />

        <Popper anchorEl={anchorEl} open={isOpenPopper} className={styles.popper} disablePortal>
          <Checklist rulesSections={rules} />
        </Popper>

        <Field
          name="confirmPassword"
          type="password"
          placeholder="COMMON.PASSWORD_CONFIRM"
          component={PasswordInputField}
          validate={[requiredValidator]}
        />

        <Button
          variant="contained"
          type="submit"
          className={styles.btn}
          disabled={invalid}
          isLoading={submitting}
        >
          <Trans i18nKey="COMMON.SUBMIT" />
        </Button>
      </Form>
    </SimplePage>
  );
};

const mapStateToProps = (state, { location: { search } }) => ({
  formValues: getSetPasswordFormValues(state),
  initialValues: {
    password: '',
    ...parse(search),
  },
});

const mapDispatchToProps = (dispatch, { history }) => ({
  resetPassword: bindActionCreators(resetPasswordCreator, dispatch),
  onSubmitSuccess: () => history.push(ROUTE_URLS.login),
});

export default compose(
  connect(
    mapStateToProps,
    // @ts-ignore
    mapDispatchToProps,
  ),
  reduxForm({
    form: FORM,
    validate: passwordsMatchValidator,
  }),
  // @ts-ignore
)(ResetPassword);
