/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import styles from './Input.module.scss';

export type InputProps = {
  name?: string;
  id?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  value?: string | number;
  disabled?: boolean;
  onChange?: (e?: any) => void;
  onFocus?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  onKeyPress?: (e?: any) => void;
  min?: number;
  classes?: {
    inputField?: string;
    root?: string;
    label?: string;
    labelRoot?: string;
    description?: string;
    error?: string;
    errorRoot?: string;
  };
};

function Input({ placeholder = '', className, ...rest }: InputProps) {
  const inputClassName = classNames(styles.input, className, {
    [styles.inputDisabled]: rest.disabled,
  });
  const { t } = useTranslation();
  const inputPlaceholder = i18next.exists(placeholder) ? t(placeholder) : placeholder;

  return <input {...rest} placeholder={inputPlaceholder} className={inputClassName} />;
}

export default Input;
