import React from 'react';
import { Trans } from 'react-i18next';

import { Column } from 'types';
import { DateCellWithTime } from 'components';

const columns: Column[] = [
  {
    Header: 'INCIDENTS.COLUMNS.UPDATED_ON',
    accessor: 'updatedAt',
    Cell: DateCellWithTime,
    width: 400,
    style: { paddingLeft: '220px' },
    headerStyle: { paddingLeft: '220px' },
  },
  {
    Header: 'INCIDENTS.COLUMNS.STATUS',
    id: 'status',
    accessor: ({ status }) => <Trans>{`INCIDENTS.STATUSES.${status}`}</Trans>,
    width: 140,
  },
  {
    Header: 'INCIDENTS.COLUMNS.DESCRIPTION',
    accessor: 'description',
  },
];

export default columns;
