/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ToolAction } from 'types';
import { invokeOrGet } from 'utils';
import MenuItem from '../MenuItem';
import Button from '../Button';
import Divider from '../Divider';

import styles from './MenuActionButton.module.scss';

function MenuActionButton(props: ToolAction) {
  const { text, onClick, to, hasDivider, isAvailable, disabled, className } = props;
  const BtnCmp = onClick ? Button : Link;

  if (!invokeOrGet(isAvailable)) return null;

  return (
    <Fragment key={text}>
      <MenuItem>
        <BtnCmp
          fullSize
          centered={false}
          upperCase={false}
          className={classNames(styles.wrapper, className)}
          disabled={invokeOrGet(disabled)}
          onClick={onClick}
          // @ts-ignore
          to={to}
        >
          <Trans i18nKey={text} />
        </BtnCmp>
      </MenuItem>

      {hasDivider && <Divider />}
    </Fragment>
  );
}

MenuActionButton.defaultProps = {
  onClick: undefined,
  to: null,
  hasDivider: false,
  isAvailable: true,
  disabled: false,
  className: '',
};

export default MenuActionButton;
