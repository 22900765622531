/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

// @flow

import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

type Props = {
  to?: string;
  color: 'primary' | 'secondary';
  children: any;
  disabled?: boolean;
};

const Breadcrumb = ({ children, color, disabled, to }: Props) => {
  const classes = classnames(styles.link, {
    [styles.disabled]: disabled,
  });

  return to ? (
    <Link className={classes} to={to}>
      {children}
    </Link>
  ) : (
    <span className={styles.text}>{children}</span>
  );
};

export default Breadcrumb;
