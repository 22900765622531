/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: modals.ts
 *
 * @author: Panasonic, developer
 */

export const MODAL_TYPES = {
  CONFIRM_DIALOG: 'CONFIRM_DIALOG',
  PROMPT_DIALOG: 'PROMPT_DIALOG',
  FORM_DIALOG: 'FORM_DIALOG',
};
