/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component, createRef } from 'react';
import classNames from 'classnames';

import { isOverflowed } from 'utils';
import { Ref } from 'types';
import Tooltip from '../Tooltip';

type Props = {
  className?: string;
  children?: any;
  withTruncate?: boolean;
};

type State = {
  title: string;
};

class ContentWithTooltip extends Component<Props, State> {
  static defaultProps = {
    className: '',
    withTruncate: true,
    children: null,
  };

  contentRef: Ref<HTMLSpanElement> = createRef();

  state = {
    title: '',
  };

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ title: this.getTitle() });
  }

  componentDidUpdate() {
    const title = this.getTitle();

    if (this.state.title !== title) {
      this.setState({ title });
    }
  }

  getTitle() {
    const contentNode = this.contentRef.current;

    return (contentNode && isOverflowed(contentNode) && contentNode.innerText) || '';
  }

  get titleElement() {
    const { title } = this.state;

    return title ? <div>{title}</div> : '';
  }

  render() {
    const { className, withTruncate, children, ...rest } = this.props;
    const classes = classNames('-block', className, { '-truncate': withTruncate });

    return (
      <span {...rest} className={classes}>
        <Tooltip title={this.titleElement} interactive>
          <span
            className={classNames('-block', { '-truncate': withTruncate })}
            ref={this.contentRef}
          >
            {this.props.children}
          </span>
        </Tooltip>
      </span>
    );
  }
}

export default ContentWithTooltip;
