/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: SimplePage.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { OnecLogoIcon } from 'media';
import Button from '../Button';
import LabelWithDescription from '../LabelWithDescription';

import styles from './SimplePage.module.scss';

type Props = {
  title?: string;
  description?: string;
  children?: any;
  withHeader?: boolean;
  withFooter?: boolean;
  btnTitle?: string;
  onClick?: () => any;
};

export const SimplePage = ({
  children,
  title,
  description,
  btnTitle,
  onClick,
  withHeader = false,
  withFooter = false,
}: Props) => (
  <div
    className={classNames(styles.wrapper, {
      [styles.withHeader]: withHeader,
      [styles.withFooter]: withFooter,
    })}
  >
    <div className={styles.card}>
      <OnecLogoIcon width={80} height={80} />

      {!!title && (
        <LabelWithDescription
          label={title}
          description={description}
          classes={{ label: styles.label, description: styles.description, root: styles.labelRoot }}
        />
      )}

      {onClick && (
        <Button className={styles.button} onClick={onClick} variant="contained">
          <Trans i18nKey={btnTitle} />
        </Button>
      )}

      {children && <div className={styles.childrenWrapper}>{children}</div>}
    </div>
  </div>
);
