/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */
import { isUndefined } from 'lodash';

export const errorMessageSingle = 'ERRORS.ERR_02';

export const requiredValidator = val => !val && errorMessageSingle;

export const definedValidator = val => isUndefined(val) && errorMessageSingle;
