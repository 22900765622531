/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import { ServiceGroupStatus } from 'types';
import { texts } from 'utils';
import { TitleWithDescription } from 'components';
import StatusIndicator from '../StatusIndicator';

import styles from './ServiceGroupInfo.module.scss';

const { services } = texts.healthStatusPage.list;

interface Props {
  name: string;
  version?: string;
  className?: string;
  status: ServiceGroupStatus;
  internal: boolean;
}

const ServiceGroupInfo = ({ className, name, version, status, internal }: Props) => {
  const inner = (
    <Fragment>
      <TitleWithDescription
        title={services[name]}
        description={internal ? version || 'SERVICES.VERSION_UNAVAILABLE' : ''}
        classes={{ title: styles.title }}
      />
      <StatusIndicator status={status} className={styles.status} />
    </Fragment>
  );

  return internal ? (
    <div className={styles.serviceInfo}>{inner}</div>
  ) : (
    <Grid item className={classNames(className, styles.item)} xs={6}>
      {inner}
    </Grid>
  );
};

ServiceGroupInfo.defaultProps = {
  className: undefined,
  internal: false,
};

export default ServiceGroupInfo;
