/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { ComponentType } from 'react';
import classNames from 'classnames';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { MenuProps } from '@material-ui/core/Menu';
import { PopoverOrigin } from '@material-ui/core/Popover';

import Menu from '../Menu';

import styles from './MenuWithAnchor.module.scss';

// eslint-disable-next-line no-undef
type Props = Partial<MenuProps> & {
  disabled?: boolean;
  children?: any;
  className?: string;
  color?: string;
  onClick?: () => void;
  onClose?: () => void;
  interactive?: string;
  placement?: PopoverOrigin;
  component?: ComponentType<any>;
  componentProps?: any;
  classes: {
    paper?: string;
    iconButton?: string;
  };
};
type State = {
  anchorEl: EventTarget | null;
};

class MenuWithAnchor extends React.Component<Props, State> {
  static menuListProps = {
    disablePadding: true,
  };

  static defaultProps = {
    disabled: false,
    className: '',
    interactive: undefined,
    onClick: () => {},
    onClose: () => {},
    color: 'default',
    component: undefined,
    children: undefined,
    componentProps: undefined,
    classes: {},
    placement: {
      vertical: 'top',
      horizontal: 'right',
    },
  };

  state = {
    anchorEl: null,
  };

  get children() {
    const { children } = this.props;

    return Array.isArray(children) ? children : [children];
  }

  get anchor() {
    const {
      disabled,
      color,
      classes: { iconButton },
    } = this.props;
    const anchor = this.children.find(component => component && component.key === 'anchor');

    return (
      anchor || (
        <div color={color} className={classNames(iconButton, { [styles.disabled]: disabled })}>
          <DotsHorizontalIcon />
        </div>
      )
    );
  }

  get content(): any[] {
    return this.children.filter(component => component && component.key !== 'anchor');
  }

  handleClick = ({ currentTarget: anchorEl }: any) => {
    if (this.props.disabled) return;

    if (this.props.onClick) {
      this.props.onClick();
    }

    this.setState(() => ({ anchorEl }));
  };

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState(() => ({ anchorEl: null }));
  };

  handleCloseInside = () => {
    const { interactive } = this.props;

    if (!interactive) {
      this.handleClose();
    }
  };

  render() {
    const {
      className,
      color,
      onClick,
      onClose,
      classes,
      placement,
      component: Component,
      componentProps,
      interactive,
      ...rest
    } = this.props;
    const { anchorEl } = this.state;

    const paperClass = classNames(styles.paper, classes.paper);

    return (
      <div className={className}>
        <section onClick={this.handleClick} role="presentation" data-test="MenuWithAnchor/Anchor">
          {this.anchor}
        </section>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={placement}
          transformOrigin={placement}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          MenuListProps={MenuWithAnchor.menuListProps}
          classes={{ paper: paperClass }}
          {...rest}
        >
          <section onClick={this.handleCloseInside} className="-outline-none" role="presentation">
            {Component ? (
              <Component {...componentProps} onClose={this.handleClose} />
            ) : (
              this.content
            )}
          </section>
        </Menu>
      </div>
    );
  }
}

export default MenuWithAnchor;
