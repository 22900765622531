/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import { Menu, withStyles } from '@material-ui/core';

export default withStyles({
  paper: {
    boxSizing: 'border-box',
    minWidth: '160px',
    boxShadow: '0 5px 10px 0 rgba(170, 170, 170, 0.1)',
    borderRadius: 0,
    border: 'solid 1px #eeeeee',
  },
})(Menu);
