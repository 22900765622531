/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useState, useEffect, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { take, isEmpty, size, get } from 'lodash';

import { NoOptionsIcon } from 'media';
import { getValueFromMixed } from 'utils';
import Search from '../Search';
import MenuItem from '../MenuItem';
import EmptyState from '../EmptyState';

import styles from './OptionsPanel.module.scss';

type Props = {
  options: any[];
  initialQuery?: string;
  withSearch?: boolean;
  onSelect?: (e?: any) => void;
  itemWrapper?: any;
  classes?: { root?: string; list?: string };
  tools?: any;
  maxInitialCount?: number;
};

const OptionsPanel = ({
  options,
  maxInitialCount,
  withSearch = false,
  itemWrapper: Item = MenuItem,
  classes = {},
  tools: Tools,
  onSelect,
  initialQuery = '',
}: Props) => {
  const showSearch = withSearch && (!maxInitialCount || size(options) > maxInitialCount);

  const [filteredOptions, setFilteredOptions] = useState(options);
  const [query, setQuery] = useState(initialQuery);

  const getFilteredOptions = useCallback(
    (queryString = query) => {
      const pattern = new RegExp(String(queryString), 'i');
      const allOptions = maxInitialCount ? take(options, maxInitialCount) : options;

      const filtered = queryString
        ? options.filter(option => pattern.test(String(getValueFromMixed(option, 'key'))))
        : allOptions;

      setFilteredOptions(filtered);
      setQuery(queryString);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  useEffect(() => getFilteredOptions(), [options, getFilteredOptions]);

  return (
    <div className={classes.root}>
      {showSearch && (
        <div className={styles.searchWrapper}>
          <Search initialQuery={query} onChange={getFilteredOptions} className={styles.search} />
        </div>
      )}
      <div className={classes.list}>
        {isEmpty(options) && (
          <EmptyState
            classes={{
              root: styles.emptyState,
              label: styles.emptyStateLabel,
            }}
            icon={NoOptionsIcon}
            label="COMMON.NO_OPTIONS_AVAILABLE"
            isIconWrapped={false}
          />
        )}
        {filteredOptions.map(option => (
          <Item
            label={getValueFromMixed(option, 'key')}
            key={getValueFromMixed(option, 'value')}
            value={getValueFromMixed(option, 'value')}
            onClick={onSelect}
            searchPattern={query}
            disabled={get(option, 'disabled')}
          >
            {getValueFromMixed(option, 'key')}
          </Item>
        ))}
        {query && isEmpty(filteredOptions) && (
          <div className={styles.noResults}>
            <Trans i18nKey="COMMON.NO_SEARCH_RESULTS" />
          </div>
        )}
      </div>
      {!!Tools && (
        <div className={styles.tools}>
          <Tools />
        </div>
      )}
    </div>
  );
};

export default OptionsPanel;
