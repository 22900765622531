/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { createSelector } from 'reselect';

import { Service } from 'types';
import {
  URLS,
  makeApiAction,
  selectorMakers,
  getSuccessType,
  getFailedType,
  getFetchType,
  getStopFetchingType,
  getStartFetchingType,
} from 'utils';

/*
 * TYPES
 * */
const serviceDetailsModuleName = 'servicesDetails';
const descriptor = 'SERVICES_DETAILS';

const FETCH_SERVICE_DETAILS = getFetchType(descriptor);
const FETCH_SERVICE_DETAILS_SUCCESS = getSuccessType(FETCH_SERVICE_DETAILS);
const FETCH_SERVICE_DETAILS_FAILED = getFailedType(FETCH_SERVICE_DETAILS);
const STOP_SERVICE_DETAILS_FETCHING = getStopFetchingType(descriptor);
const START_SERVICE_DETAILS_FETCHING = getStartFetchingType(descriptor);

/*
 * ACTIONS
 * */
const fetchServiceDetails = makeApiAction({
  baseUrl: URLS.serviceDetails,
  processingDescriptor: descriptor,
  initialActionType: FETCH_SERVICE_DETAILS,
});

/*
 * REDUCER
 * */

export interface ServiceDetailsInfo {
  services: Record<string, Service>;
  isLoading: Record<string, boolean>;
  error: any;
}

const initialValues = {
  services: {},
  isLoading: {},
  error: null,
};

function serviceDetailsReducer(
  state: ServiceDetailsInfo = initialValues,
  { type, payload, params }: any,
) {
  switch (type) {
    case START_SERVICE_DETAILS_FETCHING:
      return {
        ...state,
        isLoading: { ...state.isLoading, [params.id]: true },
      };

    case STOP_SERVICE_DETAILS_FETCHING:
      return {
        ...state,
        isLoading: { ...state.isLoading, [params.id]: false },
      };

    case FETCH_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, [params.id]: true },
        services: { ...state.services, [params.id]: payload },
      };

    case FETCH_SERVICE_DETAILS_FAILED:
      return {
        ...state,
        services: {},
        error: payload,
      };

    default:
      return state;
  }
}

/*
 * SELECTORS
 * */

const getState = selectorMakers.makeGetState<ServiceDetailsInfo>(serviceDetailsModuleName);
const getServiceDetailsInfo = createSelector(
  getState,
  state => state,
);

export {
  serviceDetailsModuleName,
  serviceDetailsReducer,
  getServiceDetailsInfo,
  fetchServiceDetails,
};
