/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: useEnsureOperationalServices.ts
 *
 * @author: Panasonic, developer
 */

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { IncidentStatus, ServiceStatus } from 'types';
import { modalActions } from 'state';
import { MODAL_TYPES } from 'utils';

export const useEnsureOperationalServices = () => {
  const dispatch = useDispatch();
  const openModal = useCallback(config => dispatch(modalActions.openModal(config)), [dispatch]);

  return async (
    formValues,
    performAction,
    defaultAction = performAction,
    shouldBeResolved = true,
  ) => {
    const isResolved = !shouldBeResolved || formValues.status === IncidentStatus.Resolved;
    const isAllServicesOperational = formValues.services.every(
      ({ status }) => status === ServiceStatus.Operational,
    );

    if (isResolved && !isAllServicesOperational) {
      const updatedServices = formValues.services.map(service => ({
        ...service,
        status: ServiceStatus.Operational,
      }));

      try {
        await openModal({
          title: 'INCIDENT.GENERAL.CONFIRM_DIALOG.TITLE',
          description: 'INCIDENT.GENERAL.CONFIRM_DIALOG.DESCRIPTION',
          modalType: MODAL_TYPES.CONFIRM_DIALOG,
        });
        performAction(updatedServices);
      } catch (e) {
        // e
      }
    } else {
      defaultAction();
    }
  };
};
