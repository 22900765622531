/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import classNames from 'classnames';
import Sticky from 'react-sticky-el';

import { isPositionStickySupported } from 'utils';

import styles from './StickyWrapper.module.scss';

const isStickySupported = isPositionStickySupported();

type Props = {
  mode?: 'top' | 'bottom';
  children: any;
  classes?: {
    root?: string;
  };
};

const StickyWrapper = ({ children, mode = 'bottom', classes = {} }: Props) =>
  isStickySupported ? (
    <div className={classNames(styles.sticky, classes.root, styles[mode])}>{children}</div>
  ) : (
    <Sticky mode={mode} className={classNames(styles.stickyFallback, classes.root)}>
      {children}
    </Sticky>
  );

export default StickyWrapper;
