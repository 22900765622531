import React from 'react';

import { ServerErrorIcon } from 'media';

import styles from './ErrorWrapper.module.scss';

interface Props {
  children: any;
  hasError: boolean;
  title: string;
  description: string;
}

export const ErrorWrapper = ({ children, hasError, title, description }: Props) =>
  hasError ? (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <ServerErrorIcon />
      </div>

      <span className={styles.title}>{title}</span>

      <span className={styles.description}>{description}</span>
    </div>
  ) : (
    children
  );
