/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import { MenuItem, withStyles } from '@material-ui/core';

const styles = {
  root: {
    padding: '0 10px',
    height: '40px',
    fontSize: '13px',
  },
};

export default withStyles(styles)(MenuItem);
