/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

export const passwordRules = {
  lowerCaseChar: /(?=.*[a-z])/,
  upperCaseChar: /(?=.*[A-Z])/,
  numericChar: /(?=.*[0-9])/,
  specialChar: /(?=.[!@#$%^&])/,
};

const minStrength = 3;

export const passwordStrengthValidator = password => {
  const strength = Object.values(passwordRules).reduce(
    (result, pattern) => (pattern.test(password) ? result + 1 : result),
    0,
  );

  return strength < minStrength && 'ERRORS.ERR_04';
};
