/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component, createRef } from 'react';
import { PopoverOrigin } from '@material-ui/core/Popover';
import classNames from 'classnames';
import { get, castArray } from 'lodash';

import Button from '../Button';
import MenuWithAnchor from '../MenuWithAnchor';
import Expander from '../Expander';

import styles from './ButtonGroup.module.scss';

type Props = {
  isHidden?: boolean;
  placement?: 'top' | 'bottom';
  children: any;
  classes: {
    root?: string;
    menuPaper?: string;
  };
};

type State = {
  isOpened: boolean;
};

class ButtonGroup extends Component<Props, State> {
  static defaultProps = {
    isHidden: true,
    placement: 'bottom',
    classes: {
      root: '',
      menuPaper: '',
    },
  };

  wrapper = createRef<HTMLDivElement>();

  state = {
    isOpened: false,
  };

  get transformOrigin(): PopoverOrigin {
    const { placement } = this.props;

    return {
      vertical: placement === 'top' ? 'bottom' : 'top',
      horizontal: 'right',
    };
  }

  get anchorOrigin(): PopoverOrigin {
    const { placement } = this.props;

    return {
      vertical: placement === 'top' ? 'top' : 'bottom',
      horizontal: 'right',
    };
  }

  handleAnchorClick = () => {
    this.setState(() => ({ isOpened: true }));
  };

  handleClose = () => {
    this.setState(() => ({ isOpened: false }));
  };

  render() {
    const { isHidden, children: childrenSrc, placement, classes } = this.props;
    const { isOpened } = this.state;
    const children = castArray(childrenSrc).filter(Boolean);

    if (!children.length) return null;

    const hasHiddenBtns = children.length > 1;
    const popoverClasses = {
      paper: classNames(
        styles.menu,
        placement === 'top' ? styles.placementTop : styles.placementBottom,
        classes.menuPaper,
      ),
    };

    const wrapperClass = classNames(styles.wrapper, classes.root);

    if (isHidden) {
      const [visibleButton, ...hiddenButtons] = children;
      const { className, onClick, to, isLoading, ...expanderProps } = visibleButton.props;
      const expanderClassName = classNames(styles.expanderWrapper, {
        [styles.containedExpander]: expanderProps.variant === 'contained',
      });

      return (
        <div className={wrapperClass} ref={this.wrapper}>
          {visibleButton}
          {hasHiddenBtns && (
            <MenuWithAnchor
              onClose={this.handleClose}
              onClick={this.handleAnchorClick}
              PopoverClasses={popoverClasses}
              anchorOrigin={this.anchorOrigin}
              transformOrigin={this.transformOrigin}
              marginThreshold={0}
              getContentAnchorEl={null}
            >
              <Button key="anchor" className={expanderClassName} {...expanderProps}>
                <Expander type="menu" isExpanded={isOpened} />
              </Button>
              <div
                className={styles.hiddenButtonsContainer}
                style={{ width: get(this.wrapper.current, 'offsetWidth', 'auto') }}
              >
                {hiddenButtons}
              </div>
            </MenuWithAnchor>
          )}
        </div>
      );
    }

    return <div className={wrapperClass}>{children}</div>;
  }
}

export default ButtonGroup;
