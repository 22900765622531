/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import classNames from 'classnames';

import { getLabelValue } from 'utils';
import LabelWithDescription from '../../LabelWithDescription';

import styles from './SummaryHeader.module.scss';

type Props = {
  data: any;
  className?: string;
  i18nMapper: {
    // eslint-disable-next-line no-undef
    labels?: any;
    // eslint-disable-next-line no-undef
    values?: any;
  };
  title: {
    label?: string;
    description?: string;
  };
  classes: {
    root?: string;
    item?: string;
    value?: string;
    bottom?: string;
  };
};

function SummaryHeader(props: Props) {
  const { data, classes, i18nMapper, title } = props;
  const wrapperClassName = classNames(styles.wrapper, classes.root);
  const hasTitle = title.label || title.description;

  return (
    <section className={wrapperClassName}>
      {hasTitle && (
        <LabelWithDescription
          {...title}
          classes={{
            root: styles.top,
            label: styles.title,
            description: styles.description,
          }}
        />
      )}
      <div className={styles.items}>
        {Object.entries(data).map(([key, value]: [string, any]) => (
          <LabelWithDescription
            key={key}
            label={getLabelValue(value)}
            description={i18nMapper.labels[key]}
            classes={{
              root: classNames(styles.item, classes.item),
              label: classNames(styles.value, classes.value),
            }}
          />
        ))}
      </div>
    </section>
  );
}

SummaryHeader.defaultProps = {
  classes: {
    root: undefined,
    item: undefined,
    value: undefined,
  },
  title: {
    label: undefined,
    description: undefined,
  },
};

export default SummaryHeader;
