/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

import FormField, { FormFieldProps } from '../FormField';

import styles from './TextareaField.module.scss';

type Props = FormFieldProps &
  WithTranslation & {
    placeholder: string;
    className: string;
    wrapperClassName: string;
    // TODO: use classes prop
    inputClassName: string;
    maxLength: number;
    viewMode: boolean;
  };

function TextareaField(props: Props) {
  const {
    t,
    placeholder = '',
    input,
    className,
    wrapperClassName,
    inputClassName,
    maxLength,
    viewMode = false,
  } = props;
  const textareaClassname = classNames(styles.default, inputClassName, {
    [styles.view]: viewMode,
  });
  const textareaId = `textarea-field-${input.name}`;

  return (
    <FormField {...props} fieldId={textareaId} className={wrapperClassName}>
      {viewMode ? (
        <div className={classNames(textareaClassname, className)}>{input.value || '-'}</div>
      ) : (
        <textarea
          {...input}
          placeholder={t(placeholder)}
          className={textareaClassname}
          id={textareaId}
          maxLength={maxLength}
          data-test="textarea-field"
        />
      )}
    </FormField>
  );
}

export default withTranslation()(TextareaField);
