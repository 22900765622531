/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import classnames from 'classnames';

import LabelWithDescription from '../../../LabelWithDescription';

import styles from './FormPanelHeader.module.scss';

export type FormPanelHeaderProps = {
  label: string;
  labelArgs?: any;
  description?: string;
  descriptionArgs?: any;
  isFilled?: boolean;
};

type Props = FormPanelHeaderProps & {
  classes: {
    root?: string;
    label?: string;
    description?: string;
  };
};

const FormPanelHeader = (props: Props) => {
  const {
    classes = {},
    label,
    labelArgs = {},
    description,
    descriptionArgs = {},
    isFilled = false,
  } = props;
  const headerClasses = classnames(styles.header, classes.root, isFilled && styles.filled);
  const labelClasses = classnames(styles.label, classes.label);
  const descriptionClasses = classnames(styles.description, classes.description);

  return (
    <div className={headerClasses}>
      <LabelWithDescription
        label={label}
        labelArgs={labelArgs}
        description={description}
        descriptionArgs={descriptionArgs}
        classes={{ label: labelClasses, description: descriptionClasses }}
      />
    </div>
  );
};

export default FormPanelHeader;
