/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { createSelector } from 'reselect';
import { get } from 'lodash';

import { Incident } from 'types';
import {
  getFailedType,
  getSuccessType,
  getStartFetchingType,
  getStopFetchingType,
  getFetchType,
  makeApiAction,
  selectorMakers,
  URLS,
} from 'utils';

/*
 * TYPES
 * */
export const incidentModuleName = 'incident';
const descriptor = 'INCIDENT';

const INCIDENT_FETCH = getFetchType(descriptor);
const INCIDENT_FETCH_SUCCESS = getSuccessType(INCIDENT_FETCH);
const INCIDENT_FETCH_FAILED = getFailedType(INCIDENT_FETCH);
const START_INCIDENT_FETCHING = getStartFetchingType(descriptor);
const STOP_INCIDENT_FETCHING = getStopFetchingType(descriptor);

const createDescriptor = 'CREATE_INCIDENT';

const CREATE_INCIDENT_FAILED = getFailedType(createDescriptor);
const CREATE_INCIDENT_SUCCESS = getSuccessType(createDescriptor);

const deleteDescriptor = 'CREATE_INCIDENT';

const DELETE_INCIDENT_FAILED = getFailedType(deleteDescriptor);
const DELETE_INCIDENT_SUCCESS = getSuccessType(deleteDescriptor);

const INCIDENT_RESET = 'INCIDENT_RESET';

/*
 * ACTIONS
 * */

export const incidentActions = {
  createIncident: makeApiAction({
    baseUrl: URLS.incidents.root,
    processingDescriptor: createDescriptor,
    initialActionType: createDescriptor,
    method: 'post',
  }),
  updateIncident: makeApiAction({
    baseUrl: URLS.incidents.incident,
    processingDescriptor: createDescriptor,
    initialActionType: createDescriptor,
    method: 'put',
  }),
  deleteIncident: makeApiAction({
    baseUrl: URLS.incidents.incident,
    processingDescriptor: deleteDescriptor,
    initialActionType: deleteDescriptor,
    method: 'delete',
  }),
  archiveIncident: makeApiAction({
    baseUrl: URLS.incidents.archive,
    processingDescriptor: createDescriptor,
    initialActionType: createDescriptor,
    method: 'post',
  }),
  publishIncident: makeApiAction({
    baseUrl: URLS.incidents.publish,
    processingDescriptor: createDescriptor,
    initialActionType: createDescriptor,
    method: 'post',
  }),
  fetchIncident: makeApiAction({
    baseUrl: URLS.incidents.incidentCurrentState,
    processingDescriptor: descriptor,
    initialActionType: INCIDENT_FETCH,
  }),
  incidentReset: () => dispatch =>
    dispatch({
      type: INCIDENT_RESET,
    }),
};

/*
 * REDUCER
 * */

export interface IncidentMetaInfo {
  isSaving: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  incident: Incident;
  error: any;
}

const initialValues: IncidentMetaInfo = {
  isSaving: false,
  isDeleting: false,
  isLoading: false,
  incident: {
    draft: true,
  },
  error: null,
};

export function incidentReducer(state = initialValues, { type, payload }: any) {
  switch (type) {
    case createDescriptor:
      return {
        ...state,
        isSaving: true,
      };

    case deleteDescriptor:
      return {
        ...state,
        isDeleting: true,
      };

    case CREATE_INCIDENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
      };

    case DELETE_INCIDENT_FAILED:
    case INCIDENT_FETCH_FAILED:
    case CREATE_INCIDENT_FAILED:
      return {
        ...state,
        error: payload,
        isSaving: false,
        isDeleting: false,
      };

    case START_INCIDENT_FETCHING:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_INCIDENT_FETCHING:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_INCIDENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };

    case INCIDENT_FETCH_SUCCESS:
      return {
        ...state,
        incident: payload,
      };

    case INCIDENT_RESET:
      return {
        ...initialValues,
      };

    default:
      return state;
  }
}

/*
 * SELECTORS
 * */
export const getIncidentMetaInfo = selectorMakers.makeGetEntitiesState<IncidentMetaInfo>(
  incidentModuleName,
);

export const getIncident = createSelector(
  getIncidentMetaInfo,
  state => get(state, 'incident'),
);
