import jwtDecode from 'jwt-decode';

import localStorage from '../localStorage';

export const KEYS = {
  TOKEN: 'TOKEN',
  RAT: 'RAT',
  TOKEN_EXPIRATION_DATE: 'TOKEN_EXPIRATION_DATE',
};

export function setAuthTokensAndHeaders({ token, refreshToken, tokenExpirationDate }) {
  localStorage.setItem(KEYS.TOKEN, token);
  localStorage.setItem(KEYS.TOKEN_EXPIRATION_DATE, tokenExpirationDate);
  if (refreshToken) localStorage.setItem(KEYS.RAT, refreshToken);
}

export function removeAuthTokensAndHeaders() {
  Object.values(KEYS).forEach(key => localStorage.removeItem(key));
}

export function getAccessToken() {
  return localStorage.getItem(KEYS.TOKEN);
}

export function getAccessTokenExist() {
  return !!getAccessToken();
}

export function processToken(token, refreshToken) {
  try {
    const { exp: tokenExpirationDate, rat } = jwtDecode(token);

    setAuthTokensAndHeaders({ token, refreshToken: refreshToken || rat, tokenExpirationDate });
  } catch (e) {}
}

export function getProfileDataAndProcessTokens(response) {
  const {
    headers: { authorization: token, 'x-refresh-token': refreshToken },
  } = response;

  try {
    processToken(token, refreshToken);
  } catch (e) {}
}
