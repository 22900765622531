import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import { history } from 'utils';
import makeDefaultRootReducer from './rootReducer';
import { refreshToken } from './middlewares';
import rootSaga from './rootSaga';

// @ts-ignore
const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devtools || compose;

export default (preloadedState?: Object, { makeRootReducer = makeDefaultRootReducer } = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [thunk, sagaMiddleware, refreshToken, routerMiddleware(history)];
  const reducer = makeRootReducer(history);
  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};
