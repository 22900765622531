/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Incident } from 'types';
import { EmptyStateIcon } from 'media';
import { getIncident } from 'state';
import { FormPanel, Table } from 'components';
import columns from './columns';

import styles from './Services.module.scss';

type Props = {
  incident?: Incident;
};

class IncidentServices extends Component<Props> {
  getNoDataProps = () => ({
    label: 'INCIDENT.SERVICES.EMPTY.TITLE',
    description: 'INCIDENT.SERVICES.EMPTY.HINT_VIEW',
    icon: EmptyStateIcon,
    classes: {
      root: styles.emptyState,
      description: styles.emptyStateDescription,
    },
  });

  render() {
    const { incident } = this.props;
    const services = get(incident, 'services', []);

    return (
      <FormPanel label="INCIDENT.SERVICES.STATUS.TITLE" description="INCIDENT.SERVICES.STATUS.HINT">
        <Table
          data={services}
          columns={columns}
          getNoDataProps={this.getNoDataProps}
          minRows={services.length}
        />
      </FormPanel>
    );
  }
}

const mapStateToProps = state => ({
  incident: getIncident(state),
});

// @ts-ignore
export default connect(mapStateToProps)(IncidentServices);
