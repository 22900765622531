/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: rootSaga.ts
 *
 * @author: Panasonic, developer
 */

import { call, all, spawn } from 'redux-saga/effects';

import { modalSaga } from './ui';

const sagas = [modalSaga];

export default function* rootSaga() {
  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (err) {
            console.log(err);
          }
        }
      }),
    ),
  );
}
