/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { Incident, Service } from 'types';
import { EmptyStateIcon } from 'media';
import { MODAL_TYPES } from 'utils';
import { getIncident, getNewServices, modalActions, incidentAvailableServicesActions } from 'state';
import { FormPanel, Button, Table } from 'components';
import ServicesAddForm from '../ServicesAddForm';
import columns from './columns';

import styles from './Services.module.scss';

type Props = {
  actions: {
    openModal: (params) => any;
    addNewService: (payload: any) => void;
  };
  newServices: Service[];
  incident: Incident;
};

const IncidentServices = ({
  actions: { addNewService },
  incident: { services },
  newServices,
  actions: { openModal },
}: Props) => {
  useEffect(() => {
    !isEmpty(services) && addNewService(services);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openServiceAddModal = () => {
    openModal({
      modalType: MODAL_TYPES.FORM_DIALOG,
      title: 'INCIDENT.SERVICES.ADD',
      confirmLabel: 'COMMON.ADD',
      formComponent: ServicesAddForm,
    });
  };

  const openServiceEditModal = (service?: object) => {
    openModal({
      modalType: MODAL_TYPES.FORM_DIALOG,
      title: 'INCIDENT.SERVICES.EDIT',
      confirmLabel: 'COMMON.SAVE',
      formComponent: ServicesAddForm,
      formProps: {
        isEdit: true,
        initialValues: {
          name: `(${get(service, 'region')}) ${get(service, 'name')}`,
          status: get(service, 'status'),
          id: get(service, 'id'),
        },
      },
    });
  };

  const getCustomTools = useCallback(
    () =>
      !isEmpty(newServices) && (
        <div className={styles.addButtonWrapper}>
          <Button onClick={openServiceAddModal} variant="contained" className={styles.addButton}>
            <Trans i18nKey="INCIDENT.SERVICES.ADD" />
          </Button>
        </div>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newServices],
  );

  const getNoDataProps = () => ({
    label: 'INCIDENT.SERVICES.EMPTY.TITLE',
    description: 'INCIDENT.SERVICES.EMPTY.HINT',
    icon: EmptyStateIcon,
    classes: {
      root: styles.emptyState,
      description: styles.emptyStateDescription,
    },
    actionButton: () => (
      <Button onClick={openServiceAddModal} variant="contained" className={styles.addButton}>
        <Trans i18nKey="INCIDENT.SERVICES.ADD" />
      </Button>
    ),
  });

  return (
    <FormPanel label="INCIDENT.SERVICES.STATUS.TITLE" description="INCIDENT.SERVICES.STATUS.HINT">
      <Table
        customTools={getCustomTools}
        data={newServices}
        columns={columns}
        getNoDataProps={getNoDataProps}
        minRows={newServices.length}
        editService={openServiceEditModal}
        getTdProps={({ editService }) => ({ editService })}
      />
    </FormPanel>
  );
};

const mapStateToProps = state => ({
  newServices: getNewServices(state),
  incident: getIncident(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      ...incidentAvailableServicesActions,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(IncidentServices);
