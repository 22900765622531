import { createSelector } from 'reselect';
import { pick } from 'lodash';

import { Incident, Meta } from 'types';
import {
  URLS,
  paginationMetaParamNames,
  makeApiAction,
  selectorMakers,
  getSuccessType,
  getFetchType,
  getStopFetchingType,
  getStartFetchingType,
} from 'utils';

/*
 * TYPES
 * */
const incidentsModuleName = 'incidents';
const descriptor = 'INCIDENTS';

const FETCH_INCIDENTS = getFetchType(descriptor);
const FETCH_INCIDENTS_SUCCESS = getSuccessType(FETCH_INCIDENTS);
const STOP_INCIDENTS_FETCHING = getStopFetchingType(descriptor);
const START_INCIDENTS_FETCHING = getStartFetchingType(descriptor);

/*
 * ACTIONS
 * */

const incidentsActions = {
  fetchIncidents: makeApiAction({
    baseUrl: URLS.incidents.root,
    processingDescriptor: descriptor,
    initialActionType: FETCH_INCIDENTS,
  }),
  fetchPublicIncidents: makeApiAction({
    baseUrl: URLS.incidents.public,
    processingDescriptor: descriptor,
    initialActionType: FETCH_INCIDENTS,
  }),
  fetchIncidentsHistory: makeApiAction({
    baseUrl: URLS.incidents.history,
    processingDescriptor: descriptor,
    initialActionType: FETCH_INCIDENTS,
  }),
};

/*
 * REDUCER
 * */

interface IncidentsInfo {
  data: Incident[];
  meta: Meta;
  isFetching: boolean;
}

const initialValues: IncidentsInfo = {
  data: [],
  meta: {
    pageNumber: 0,
    pageSize: 10,
    totalItemsCount: 0,
    totalPages: 0,
    itemsOnPage: 0,
  },
  isFetching: false,
};

function incidentsReducer(state: IncidentsInfo = initialValues, { type, payload }: any) {
  switch (type) {
    case START_INCIDENTS_FETCHING:
      return {
        ...state,
        isFetching: true,
      };

    case STOP_INCIDENTS_FETCHING:
      return {
        ...state,
        isFetching: false,
      };

    case FETCH_INCIDENTS_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

/*
 * SELECTORS
 * */

const getState = selectorMakers.makeGetState<IncidentsInfo>(incidentsModuleName);

const getIncidents = createSelector(
  getState,
  state => state.data,
);

const getIncidentsMeta = createSelector(
  getState,
  state => state.meta,
);

const getIncidentsQueryParams = createSelector(
  getState,
  state => pick(state.meta, paginationMetaParamNames),
);

const getIncidentsIsFetching = createSelector(
  getState,
  state => state.isFetching,
);

export {
  incidentsModuleName,
  incidentsReducer,
  incidentsActions,
  getIncidents,
  getIncidentsMeta,
  getIncidentsQueryParams,
  getIncidentsIsFetching,
};
