/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: dom.ts
 *
 * @author: omPanasonic, developer
 */

/**
 * tests if browser supports 'position: sticky' or 'position: -webkit-sticky' css rule and returns 'true' if it does
 * @return {boolean}
 */
export const isPositionStickySupported = () => {
  const testElement = document.head.style;

  ['sticky', '-webkit-sticky'].forEach(position => {
    testElement.position = position;
  });

  const isStickySupported = !!testElement.position;

  testElement.position = '';

  return isStickySupported;
};
