import { compact, isEmpty, keys, get } from 'lodash';
import { Dispatch, AnyAction } from 'redux';
import { stringify } from 'query-string';

import API from '../../API';
import { REFRESHING_TOKEN } from 'state/types';

interface MakeApiActionConfig {
  baseUrl: string;
  initialActionType: string;
  method?: 'get' | 'post' | 'put' | 'delete';
  processingDescriptor?: string;
  shouldRefetchToken?: boolean;
  requestOptions?: Object;
  transformRequest?: (payload: any, meta: Object, requestParams?: Object) => any[];
  transformResponse?: (response: any, meta?: Object, params?: any) => any;
  transformError?: (error: any, dispatch: Dispatch<AnyAction>) => any;
  makeUrl?: (params: Object) => string;
}

export const formatRequestString = ({ baseUrl, params }) => {
  const urlKeys = keys(params);
  let urlFormatted = baseUrl;

  urlKeys.forEach(key => {
    urlFormatted = urlFormatted.replace(`:${key}`, params[key]);
  });

  return urlFormatted;
};

const stringifyQueryString = (url, { queryParams = {} } = {}) =>
  !isEmpty(queryParams) ? `${url}?${stringify(queryParams)}` : url;

const defaultTransformRequest = (payload: any, requestParams?: Object) =>
  compact([payload, requestParams]);

const defaultTransformResponse = (response?: any) => response.data;

const defaultTransformError = (error: any) => error;

export const getFetchType = (descriptor?: string) => `${descriptor}_FETCH`;
export const getStartFetchingType = (descriptor?: string) => `START_${descriptor}_FETCHING`;
export const getStopFetchingType = (descriptor?: string) => `STOP_${descriptor}_FETCHING`;
export const getSuccessType = (actionType: string) => `${actionType}_SUCCESS`;
export const getFailedType = (actionType: string) => `${actionType}_FAILED`;

export default (config: MakeApiActionConfig, commonMeta?: Object) => {
  const {
    baseUrl,
    method = 'get',
    initialActionType,
    processingDescriptor,
    transformRequest = defaultTransformRequest,
    transformResponse = defaultTransformResponse,
    transformError = defaultTransformError,
    requestOptions,
    shouldRefetchToken = true,
  } = config;

  const startFetchingType = getStartFetchingType(processingDescriptor);
  const stopFetchingType = getStopFetchingType(processingDescriptor);
  const successType = getSuccessType(initialActionType);
  const failedType = getFailedType(initialActionType);

  return (params: any) => async (dispatch: Dispatch<any>) => {
    const meta = { ...commonMeta };

    dispatch({
      params,
      meta,
      type: initialActionType,
    });

    try {
      if (processingDescriptor) dispatch({ type: startFetchingType, meta, params });
      const newUrl = formatRequestString({ baseUrl, params });
      const url = stringifyQueryString(newUrl, params);

      const requestParams = transformRequest(params, meta, requestOptions);
      // @ts-ignore
      const response = await API[method](url, ...requestParams);
      const processedResponse = transformResponse(response, meta, params);

      dispatch({
        params,
        meta,
        type: successType,
        payload: processedResponse,
      });

      // added for use `then` in promises
      return get(response, 'data');
    } catch (error) {
      dispatch({
        meta,
        error,
        type: failedType,
        params,
      });

      const isUnauthorizedError = get(error, 'response.status') === 401;

      if (isUnauthorizedError && shouldRefetchToken) {
        dispatch({
          type: REFRESHING_TOKEN,
          payload: {
            shouldRefetchToken,
          },
        });
      }

      transformError(error, dispatch);

      // added for use `catch` in promises
      throw error;
    } finally {
      if (processingDescriptor) dispatch({ meta, type: stopFetchingType, params });
    }
  };
};
