/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './TrComponent.module.scss';

type Props = {
  className?: string;
  children?: ReactNode;
  expanded?: boolean;
};

function TrComponent(props: Props) {
  const { className, children, expanded, ...rest } = props;
  const trClassNames = classnames(className, styles.tr, 'rt-tr', expanded && styles.expanded);

  return (
    <div data-test="tr-component" className={trClassNames} role="row" {...rest}>
      {children}
    </div>
  );
}

TrComponent.defaultProps = {
  className: '',
  children: '',
  expanded: false,
};

export default TrComponent;
