/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component } from 'react';
import { Form, reduxForm, InjectedFormProps } from 'redux-form';
import { Trans } from 'react-i18next';
import { size } from 'lodash';

import { makeValidate } from 'utils';
import DialogBoilerplate, { CommonDialogPropTypes } from '../DialogBoilerplate';
import TextareaField from '../../Forms/TextareaField';
import CustomField from '../../Forms/CustomField';

import styles from './PromptDialog.module.scss';

type CommonProps = InjectedFormProps & CommonDialogPropTypes;

type PromptDialogProps = CommonProps & {
  placeholder?: string;
  descriptionBlocks?: string[];
  label?: string;
  validators?: any[];
  maxLength?: number;
  submit(): any;
};

class PromptDialog extends Component<PromptDialogProps> {
  static defaultProps = {
    placeholder: '',
    descriptionBlocks: [],
    label: '',
    validators: [],
  };

  validate: any;

  constructor(props: PromptDialogProps) {
    super(props);

    this.validate = makeValidate(this.props.validators);
  }

  onSubmit = ({ prompt }: any) => {
    const { onConfirm } = this.props;

    if (onConfirm) onConfirm(prompt);
  };

  render() {
    const { placeholder, label, submit, handleSubmit, descriptionBlocks, maxLength } = this.props;

    return (
      <DialogBoilerplate {...this.props} onConfirm={submit}>
        {descriptionBlocks && !!size(descriptionBlocks) && (
          <div className={styles.description}>
            {descriptionBlocks.map(description => (
              <p key={description} className={styles.descriptionLine}>
                <Trans i18nKey={description} values={this.props} />
              </p>
            ))}
          </div>
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <CustomField
            name="prompt"
            wrapperClassName={styles.field}
            component={TextareaField}
            label={label}
            validate={this.validate}
            placeholder={placeholder}
            maxLength={maxLength}
            inputClassName={styles.input}
          />
        </Form>
      </DialogBoilerplate>
    );
  }
}

// @ts-ignore
export default reduxForm({ form: 'dialog' })(PromptDialog);
