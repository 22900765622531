/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { BreadcrumbType } from 'types';
import Breadcrumb from './Breadcrumb';
import ContentWithTooltip from '../ContentWithTooltip';

import styles from './Breadcrumbs.module.scss';

type Props = {
  color: 'primary' | 'secondary';
  separator?: '/' | '|' | ' ';
  className?: string;
  breadcrumbs: BreadcrumbType[];
  useTitleAsBreadcrumb: boolean;
  pageTitle?: string;
};

class Breadcrumbs extends Component<Props> {
  static defaultProps = {
    color: 'primary',
    separator: '/',
    className: '',
    breadcrumbs: [],
  };

  renderBreadcrumb(breadcrumb: BreadcrumbType, index: number, breadcrumbs: BreadcrumbType[]) {
    const { color, separator, useTitleAsBreadcrumb, pageTitle } = this.props;
    const isLastElement = index === breadcrumbs.length - 1;
    const title = isLastElement && useTitleAsBreadcrumb ? pageTitle : breadcrumb.title;

    return (
      <span key={breadcrumb.title} data-test="breadcrumb-link" className={styles.breadcrumbLink}>
        <Breadcrumb to={breadcrumb.path} color={color} disabled={isLastElement}>
          <div className={styles.title}>
            <ContentWithTooltip>
              <Trans i18nKey={title} />
            </ContentWithTooltip>
          </div>
        </Breadcrumb>

        {!isLastElement && (
          <span className={classNames(styles.separator, [styles.separator[color]])}>
            {separator}
          </span>
        )}
      </span>
    );
  }

  render() {
    const { className, breadcrumbs } = this.props;

    return (
      breadcrumbs.length > 1 && (
        <div className={className}>
          {breadcrumbs.map((breadcrumb, i, arr) => this.renderBreadcrumb(breadcrumb, i, arr))}
        </div>
      )
    );
  }
}

export default Breadcrumbs;
