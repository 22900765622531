/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: columns.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import { Trans } from 'react-i18next';

import { Column } from 'types';
import { DateCellWithTime } from 'components';
import { formatName } from 'utils';

const columns: Column[] = [
  {
    Header: 'INCIDENTS.COLUMNS.UPDATED_ON',
    accessor: 'updatedAt',
    Cell: DateCellWithTime,
    isSticky: true,
    width: 200,
  },
  {
    Header: 'INCIDENTS.COLUMNS.STATUS',
    id: 'status',
    accessor: ({ status }) => <Trans>{`INCIDENTS.STATUSES.${status}`}</Trans>,
    isSticky: true,
    width: 140,
  },
  {
    Header: 'INCIDENTS.COLUMNS.SERVICES',
    id: 'services',
    accessor: ({ services }) =>
      services.map(({ name, region }) => `(${region}) ${name}`).join(', '),
  },
  {
    Header: 'INCIDENTS.COLUMNS.DESCRIPTION',
    accessor: 'description',
    width: 400,
  },
  {
    Header: 'INCIDENTS.COLUMNS.UPDATED_BY',
    id: 'updatedBy',
    accessor: ({ updatedBy }) => formatName(updatedBy),
  },
];

export default columns;
