import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import Routes from '../../routes';
import { history } from 'utils';
import { i18nInit } from 'services';
import { configureStore } from 'state';
import { Layout, ModalRoot } from 'components';

import './App.scss';

const store = configureStore();

class App extends Component {
  render() {
    return (
      <I18nextProvider i18n={i18nInit}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Layout>
              <Routes />
            </Layout>

            <ModalRoot />
          </ConnectedRouter>
        </Provider>
      </I18nextProvider>
    );
  }
}

export default App;
