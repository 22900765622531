export const translatePropsNames = ['i18n', 'i18nOptions', 'reportNS', 't', 'tReady', 'defaultNS'];

export const SORTING_STATES = {
  ASC: 'asc',
  DESC: 'desc',
};

export const TABLE_PAGE_SIZES = {
  MIN: 5,
  DEFAULT: 10,
  LARGE: 25,
  MAX: 50,
};
