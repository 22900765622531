/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Trans } from 'react-i18next';

import { PaginatedTable, PageHeader, Button } from 'components';
import { Meta, Incident } from 'types';
import {
  incidentsActions,
  getIncidents,
  getIncidentsMeta,
  getIncidentsIsFetching,
  getIncidentsQueryParams,
} from 'state';
import { ROUTE_URLS } from 'utils';
import { EmptyStateIcon } from 'media';
import columns from './columns';
import IncidentsSubTable from '../HealthStatus/components/IncidentsSubTable';

import styles from './Incidents.module.scss';

type Props = RouteComponentProps<any> & {
  actions: {
    fetchIncidentsHistory: (params: any) => void;
  };
  meta: Meta;
  incidents: Incident[];
  isFetching: boolean;
  incidentsQueryParams: any;
  isAuthorized: boolean;
};

const IncidentsHistory = ({
  actions: { fetchIncidentsHistory },
  incidents,
  meta,
  isFetching,
  incidentsQueryParams,
  history: { push },
}: Props) => {
  const fetchData = tableState => {
    fetchIncidentsHistory({ queryParams: { ...incidentsQueryParams, ...tableState } });
  };

  const getManageIncidentsButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        className={styles.actionButton}
        onClick={() => push(ROUTE_URLS.login)}
      >
        <Trans i18nKey="INCIDENTS.MANAGE_INCIDENTS" />
      </Button>
    );
  };

  const getNoDataProps = () => ({
    label: 'INCIDENTS.NO_DATA.LABEL',
    description: 'INCIDENTS.NO_DATA.DESCRIPTION',
    icon: EmptyStateIcon,
    actionButton: getManageIncidentsButton(),
  });

  return (
    <div className="full-width">
      <PageHeader
        label="INCIDENTS.PAGE_HEADER.LABEL"
        description="INCIDENTS.PAGE_HEADER.DESCRIPTION"
        showBreadcrumbs={false}
      />

      <div className="route-container">
        <PaginatedTable
          data={incidents}
          meta={meta}
          columns={columns}
          onTableStateChange={fetchData}
          loading={isFetching}
          SubComponent={({ original: { states } }) => <IncidentsSubTable data={states} />}
          getNoDataProps={getNoDataProps}
          entityTools={getManageIncidentsButton}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  incidents: getIncidents(state),
  meta: getIncidentsMeta(state),
  isFetching: getIncidentsIsFetching(state),
  incidentsQueryParams: getIncidentsQueryParams(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators<(dispatch: Dispatch) => void, any>(incidentsActions, dispatch),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(IncidentsHistory);
