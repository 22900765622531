/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { withTranslation, WithTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  get,
  isFunction,
  isUndefined,
  find,
  max,
  isNumber,
  mapValues,
  keyBy,
  keys,
  pick,
  reduce,
  add,
} from 'lodash';

import { Column } from 'types';
import EmptyState from '../../EmptyState';
import TablePagination from '../TablePagination';
import ThComponent from '../ThComponent';
import TdComponent from '../TdComponent';
import ThContent from '../ThContent';
import Expander from '../../Expander';
import TrComponent from '../TrComponent';
import TableComponent from '../TableComponent';
import { Spinner } from '../../Spinner';
import { ReactComponent as NoResultsFoundIcon } from '../noResultsFoundIcon.svg';

import styles from './Table.module.scss';

export const getResizedStickyColumnsWidth = (columns: Column[], resized?: any[]) => {
  const stickyColumns = columns.map(({ id, accessor, width, minWidth }) => ({
    id: id || accessor,
    value: find([width, minWidth, 0], isNumber),
  }));
  const stickyColumnsValues = mapValues(keyBy(stickyColumns, 'id'), 'value');
  const resizedColumns = mapValues(keyBy(resized, 'id'), 'value');
  const resizedStickyColumns = pick(
    { ...stickyColumnsValues, ...resizedColumns },
    keys(stickyColumnsValues),
  );

  return reduce(resizedStickyColumns, add, 0);
};

export const getPrevColumnsWidth = (
  columns: Column[],
  resized: Record<string, any>[],
  currentIndex: number,
) => {
  const prevColumns = columns.slice(0, currentIndex);

  return getResizedStickyColumnsWidth(prevColumns, resized);
};

const LoadingComponent = ({ loading }: { loading: boolean }) =>
  loading ? <Spinner withBackdrop /> : null;

Object.assign(ReactTableDefaults, {
  TableComponent,
  TrComponent,
  ThComponent,
  TdComponent,
  ExpanderComponent: Expander,
  PaginationComponent: TablePagination,
  NoDataComponent: EmptyState,
  LoadingComponent,
});

const noSearchResultsProps = {
  label: 'COMMON.NO_SEARCH_RESULTS',
  description: 'COMMON.NO_SEARCH_FILTER_RESULTS_DESCRIPTION',
  icon: NoResultsFoundIcon,
};

type GetterType = (data?: any, rowInfo?: any, column?: Column) => any;

const getFilteredColumns = (filterProp, columns) =>
  columns.filter(column => column[filterProp] && (column.show || isUndefined(column.show)));

const getStickyColumnsOffsets = (stickyColumns: any[], resized: any[] = []) =>
  stickyColumns.map(({ id, accessor }, index, arr) => ({
    id: id || accessor,
    value: getPrevColumnsWidth(arr, resized, index),
  }));

// eslint-disable-next-line no-undef
export type Props = Partial<WithTranslation> & {
  columns: Column[];
  getTableProps?: GetterType;
  getTbodyProps?: GetterType;
  getTrGroupProps?: GetterType;
  getTdProps?: GetterType;
  getTrProps?: GetterType;
  getNoDataProps?: GetterType;
  getTheadProps?: GetterType;
  getTheadThProps?: GetterType;
  getLoadingProps?: GetterType;
  minRows?: number;
  showPagination?: boolean;
  sortable?: boolean;
  className?: string;
  isSubTable?: boolean;
  SubComponent?: (x?: any) => any;
  extendColumns?: (x: any) => any;
  customTools?: () => any;
  showHeader?: boolean;
  classes: {
    table?: string;
    tableRoot?: string;
    td?: string;
    tr?: string;
    thead?: string;
    tbody?: string;
    emptyState?: string;
  };
  data: any[];
  loading?: boolean;
};

type State = {
  stickyColumnsOffsets: { id: any; value: number }[];
  stickyColumnsOffsetLeft: number;
  stickyColumnsOffsetRight: number;
  resized: any[];
  columns: any[];
  stickyColumns: any[];
};

class Table extends Component<Props, State> {
  static defaultProps = {
    getTableProps: ({ 'data-test': dataTest }) => ({ 'data-test': dataTest }),
    getTbodyProps: () => ({}),
    getTrGroupProps: () => ({}),
    getTdProps: () => ({}),
    getTrProps: () => ({}),
    getNoDataProps: () => ({}),
    getTheadProps: () => ({}),
    getTheadThProps: () => ({}),
    getLoadingProps: () => ({}),
    minRows: 20,
    showPagination: false,
    sortable: false,
    className: '',
    isSubTable: false,
    SubComponent: undefined,
    customTools: undefined,
    showHeader: true,
    classes: {},
  };

  static defaultMinColumnWidth = 40;
  getters: any;

  constructor(props) {
    super(props);

    const {
      t,
      getTdProps,
      getTrProps,
      getTableProps,
      getTbodyProps,
      getTrGroupProps,
      getNoDataProps,
      getTheadProps,
      getTheadThProps,
      getLoadingProps,
      showHeader,
      classes: { table, td, tr, thead, tbody, emptyState },
    } = this.props;

    const trClass = classnames(styles.tr, tr);
    const tdClass = classnames(styles.td, td);
    const theadClass = classnames(styles.thead, thead);
    const tableClass = classnames(styles.table, table);
    const emptyStateClass = classnames(styles.emptyState, emptyState);
    const columns = this.extendColumns();
    const stickyColumns = getFilteredColumns('isSticky', columns);
    const stickyColumnsLast = getFilteredColumns('isStickyLast', columns);

    this.state = {
      stickyColumns,
      stickyColumnsOffsets: getStickyColumnsOffsets(stickyColumns),
      stickyColumnsOffsetLeft: getResizedStickyColumnsWidth(stickyColumns),
      stickyColumnsOffsetRight: getResizedStickyColumnsWidth(stickyColumnsLast),
      resized: [],
      columns,
    };

    this.getters = {
      getTheadProps: tableProps => ({
        className: theadClass,
        style: {
          display:
            !showHeader || (!tableProps.data.length && !tableProps.loading) ? 'none' : 'flex',
        },
        ...(getTheadProps && getTheadProps(tableProps)),
      }),
      getNoDataProps: ({ loading, label, description, icon, meta, actionButton, ...rest }) => {
        const isInSearch = get(meta, 'search', '');

        return {
          label: label || 'COMMON.NO_DATA_LABEL',
          description: description || 'COMMON.NO_DATA_DESCRIPTION',
          icon,
          actionButton,
          isReady: !loading,
          classes: { root: emptyStateClass },
          ...((isInSearch && noSearchResultsProps) || (getNoDataProps && getNoDataProps(rest))),
        };
      },
      getTheadThProps: (tableProps, rowInfo, column) => ({
        sorting: tableProps.sorted.find(({ id }) => id === column.id),
        ...(getTheadThProps && getTheadThProps(tableProps, rowInfo, column)),
        columnOffset: this.getColumnOffset(column),
      }),
      getTdProps: (tableProps, rowInfo, column) => {
        const onClick = (_e, handleOriginal) => {
          if (!handleOriginal) return;

          if (column.disabled) {
            if (!column.disabled(rowInfo)) handleOriginal();
          } else {
            handleOriginal();
          }
        };

        return {
          t,
          className: classnames(tdClass, {
            [styles.expandable]: column.expander,
          }),
          ...(getTdProps && getTdProps(tableProps)),
          onClick,
          withTruncate: column.withTruncate,
          columnOffset: this.getColumnOffset(column),
        };
      },
      getTrProps: ({ onTrClick, ...tableProps }, row) => ({
        expanded: row && !!tableProps.expanded[row.index],
        className: trClass,
        onClick: onTrClick && (() => onTrClick(row.index)),
        ...(getTrProps && getTrProps(tableProps, row)),
      }),
      getTableProps: tableProps => ({
        ...tableProps,
        className: classnames(tableClass, !tableProps.data.length && styles.noData),
        ...(getTableProps && getTableProps(tableProps)),
        stickyColumnsOffsetLeft: this.state.stickyColumnsOffsetLeft,
        stickyColumnsOffsetRight: this.state.stickyColumnsOffsetRight,
      }),
      getTrGroupProps: tableProps => ({
        className: styles.trGroup,
        ...(getTrGroupProps && getTrGroupProps(tableProps)),
      }),
      getTbodyProps: tableProps => ({
        className: classnames(styles.tbody, tbody, {
          [styles.tbodyNoHead]: !showHeader && tableProps.data.length,
        }),
        ...(getTbodyProps && getTbodyProps(tableProps)),
      }),
      getLoadingProps: tableProps => ({
        withBackdrop: true,
        ...(getLoadingProps && getLoadingProps(tableProps)),
      }),
    };
  }

  componentDidUpdate(prevProps) {
    const { columns } = this.props;
    const { resized } = this.state;
    const stickyColumns = getFilteredColumns('isSticky', columns);
    const stickyColumnsLast = getFilteredColumns('isStickyLast', columns);

    if (prevProps.columns !== columns) {
      this.setState(() => ({
        stickyColumns,
        stickyColumnsOffsets: getStickyColumnsOffsets(stickyColumns, resized),
        stickyColumnsOffsetLeft: getResizedStickyColumnsWidth(stickyColumns, resized),
        stickyColumnsOffsetRight: getResizedStickyColumnsWidth(stickyColumnsLast, resized),
        columns: this.extendColumns(),
      }));
    }
  }

  getColumnOffset = ({ id, accessor }) => {
    const columnId = id || accessor;
    const columnOffset = find(this.state.stickyColumnsOffsets, ['id', columnId]);

    return get(columnOffset, 'value');
  };

  extendColumns() {
    const { isSubTable, columns } = this.props;

    /*
      HACK:AS to add translations to table header cells because currently (07/2018)
      it's not possible to pass table props to header cell content
    */
    return columns.map((config, index, cols) => {
      const headerText = !isFunction(config.Header)
        ? {
            Header: ThContent,
            name: config.Header,
          }
        : {};
      let columnClassNames = {};
      let getProps;

      const columnClassName = classnames(
        styles.stickyColumn,
        isSubTable && styles.stickyColumnSubTable,
      );

      if (config.isSticky) {
        const isNextSticky = get(cols, `[${index + 1}].isSticky`);

        columnClassNames = {
          headerClassName: classnames(columnClassName, config.headerClassName, {
            [styles.withBorderRight]: !isNextSticky,
          }),
          className: classnames(columnClassName, config.className, {
            [styles.withBorderRight]: !isNextSticky,
          }),
        };
      }

      if (config.isStickyLast) {
        columnClassNames = {
          headerClassName: classnames(
            columnClassName,
            styles.stickyColumnLastTh,
            config.headerClassName,
          ),
          className: classnames(columnClassName, styles.stickyColumnLast, config.className),
        };
      }

      if (!config.Header) {
        getProps = () => ({ emptyCellValue: ' ' });
      }

      return {
        ...config,
        ...columnClassNames,
        ...headerText,
        getProps: config.getProps || getProps || (() => ({})),
      };
    });
  }

  handleResize = newResized => {
    const { stickyColumns } = this.state;
    const resized = newResized.map(resizedColumn => ({
      ...resizedColumn,
      value: max([resizedColumn.value, Table.defaultMinColumnWidth]),
    }));

    this.setState(() => ({
      stickyColumnsOffsets: getStickyColumnsOffsets(stickyColumns, resized),
      stickyColumnsOffsetLeft: getResizedStickyColumnsWidth(stickyColumns, resized),
      resized,
    }));
  };

  render() {
    const {
      t,
      className,
      isSubTable,
      customTools,
      classes: { tableRoot },
      ...restProps
    } = this.props;
    const tableClassNames = classnames(
      className,
      styles.tableWrapper,
      isSubTable && styles.subTable,
      !!this.props.SubComponent && styles.wrapperTable,
      tableRoot,
    );

    return (
      <section className={styles.container}>
        {customTools && customTools()}
        <ReactTable
          className={tableClassNames}
          {...restProps}
          noDataText=""
          t={t}
          {...this.getters}
          columns={this.state.columns}
          onResizedChange={this.handleResize}
          resized={this.state.resized}
        />
      </section>
    );
  }
}

// @ts-ignore
export default withTranslation()(Table);
