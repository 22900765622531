/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import Highlighter from 'react-highlight-words';
import { castArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { MenuItemProps } from '@material-ui/core/MenuItem';

import MenuItem from '../../../MenuItem';

import styles from './Option.module.scss';

type Props = {
  children: string;
  searchPattern: string;
  onClick: (value: MenuItemProps['value']) => void;
  value: MenuItemProps['value'];
};

const Option = ({ children, searchPattern, onClick, value, ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      {...rest}
      value={value}
      onClick={() => onClick(value)}
      classes={{ root: styles.menuItem }}
    >
      <Highlighter
        highlightClassName={styles.highlight}
        searchWords={castArray(searchPattern)}
        textToHighlight={t(children)}
      />
    </MenuItem>
  );
};

export default Option;
