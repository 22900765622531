/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 *
 */

import { Tooltip, withStyles } from '@material-ui/core';

export default withStyles({
  tooltip: {
    margin: '10px 0',
    padding: 10,
    maxWidth: 360,
    backgroundColor: '#333333',
    color: '#eeeeee',
    fontSize: 12,
    wordBreak: 'break-all',
  },
})(Tooltip);
