/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { ComponentType } from 'react';
import classNames from 'classnames';

import Input from '../Input';
import FormField from '../FormField';

import styles from './InputField.module.scss';

interface InputFieldProps {
  wrapperClassName?: string;
  className?: string;
  id?: string;
  viewMode?: boolean;
  name?: string;
  emptyViewMode?: string;
  errorWithoutTouched?: boolean;
  placeholder?: string;
  placeholderParams?: Object;
  input: {
    name: string;
    value: string;
  };
}

const InputField: ComponentType<InputFieldProps> = (props: InputFieldProps) => {
  const {
    wrapperClassName,
    className,
    input,
    id,
    viewMode = false,
    emptyViewMode = '-',
    errorWithoutTouched,
    ...rest
  } = props;
  const inputId = `input-${id || rest.name || ''}`;

  return (
    <FormField {...props} className={wrapperClassName} fieldId={inputId}>
      {viewMode ? (
        <div className={classNames(styles.view, className)}>{input.value || emptyViewMode}</div>
      ) : (
        <Input id={id} {...rest} {...input} className={className} />
      )}
    </FormField>
  );
};

export default InputField;
