/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component } from 'react';
import classnames from 'classnames';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { Trans } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import SelectWithLabel from '../../Forms/SelectWithLabel';
import PageJump from './PageJump';

import styles from './TablePagination.module.scss';

type Props = {
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  pages: number;
  page: number;
  className: string;
  showPageSizeOptions: boolean;
  showPageJump: boolean;
  pageSizeOptions: any[];
  pageSize: number;
  totalItemsCount: number;
  isSubTable: boolean;
  classes: {
    paginationRoot?: string;
    paginationControl?: string;
  };
  meta: any;
};

class TablePagination extends Component<Props> {
  static defaultProps = {
    pages: 1,
    page: 0,
    className: '',
    onPageChange: () => {},
    onPageSizeChange: () => {},
    showPageSizeOptions: false,
    pageSizeOptions: [],
    showPageJump: false,
    totalItemsCount: 0,
    isSubTable: false,
    classes: {},
    meta: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      this.props.onPageChange(this.props.page);
    }
  }

  onPageChange = ({ selected }) => {
    this.changePage(selected);
  };

  // HACK: workaround to display consistent page range for first and last active pages https://github.com/AdeleD/react-paginate/issues/270
  // there is incorrect page range (pageRangeDisplayed - 1) when one of last two pages is active
  get pageRange() {
    const {
      page,
      meta: { totalPages },
    } = this.props;

    return totalPages - page <= 2 ? 4 : 3;
  }

  changePage = page => {
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  };

  changePageSize = e => {
    const { onPageSizeChange } = this.props;

    onPageSizeChange(Number(e.target.value));
  };

  render() {
    const {
      page,
      pages,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      className,
      totalItemsCount,
      isSubTable,
      classes: { paginationRoot, paginationControl },
      meta: { totalPages },
    } = this.props;

    const expectedMax = (page + 1) * pageSize;
    const minOnPage = expectedMax - pageSize + 1;
    const maxOnPage = Math.min(expectedMax, totalItemsCount);

    const wrapperClass = classnames(
      styles.wrapper,
      className,
      isSubTable && styles.subTable,
      paginationRoot,
    );
    const pageSizeClass = classnames(styles.pageSize, paginationControl);

    return (
      <div className={wrapperClass}>
        {showPageSizeOptions && (
          // @ts-ignore
          <SelectWithLabel
            className={pageSizeClass}
            options={pageSizeOptions}
            onChange={this.changePageSize}
            value={pageSize}
            classes={{
              root: styles.pageSizeSelect,
            }}
          />
        )}

        <span className={styles.summary}>
          <Trans values={{ minOnPage, maxOnPage, totalItemsCount }}>TABLES.PAGES_SUMMARY</Trans>
        </span>

        <ReactPaginate
          pageCount={totalPages}
          onPageChange={this.onPageChange}
          pageRangeDisplayed={this.pageRange}
          marginPagesDisplayed={1}
          forcePage={page}
          previousLabel={<ChevronLeftIcon />}
          nextLabel={<ChevronRightIcon />}
          containerClassName={styles.pagination}
          pageClassName={styles.page}
          previousClassName={styles.previousPage}
          nextClassName={styles.nextPage}
          activeClassName={styles.activePage}
          breakClassName={styles.breakPage}
          pageLinkClassName={styles.pageLink}
          previousLinkClassName={styles.previousLink}
          nextLinkClassName={styles.nextLink}
          disabledClassName={styles.disabled}
        />

        {showPageJump && <PageJump page={page} totalPages={pages} onChange={this.changePage} />}
      </div>
    );
  }
}

export default TablePagination;
