/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import { ServiceGroupStatus } from 'types';
import { TitleWithDescription } from 'components';
import StatusIndicator from '../StatusIndicator';

import styles from './ServiceInfo.module.scss';

interface Props {
  name: string;
  version: string;
  commitSha: string;
  buildTime: string;
  className?: string;
  status: ServiceGroupStatus;
}

const ServiceInfo = ({ className, name, version, commitSha, buildTime, status }: Props) => (
  <Grid item className={classNames(className, styles.item)} xs={6}>
    <TitleWithDescription
      title={name}
      description={`${
        version ? `${version} (${commitSha} ${buildTime})` : 'SERVICES.VERSION_UNAVAILABLE'
      }`}
      classes={{ title: styles.title }}
    />

    <StatusIndicator status={status} className={styles.status} />
  </Grid>
);

ServiceInfo.defaultProps = {
  className: undefined,
};

export default ServiceInfo;
