import React from 'react';
import { Trans } from 'react-i18next';
import { map } from 'lodash';

import { SERVICE_GROUP_STATUS_TYPES } from 'utils';
import { Text } from 'components';
import StatusIndicator from '../StatusIndicator/StatusIndicator';

import styles from './Legend.module.scss';

function Legend() {
  return (
    <div className={styles.wrapper}>
      {map(SERVICE_GROUP_STATUS_TYPES.LIST, ({ key, value }) => (
        <div className={styles.item} key={value}>
          <StatusIndicator status={value} />
          <Text className={styles.text}>
            <Trans i18nKey={key} />
          </Text>
        </div>
      ))}
    </div>
  );
}

export default Legend;
