/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect, RouteComponentProps } from 'react-router';

import { getDisplayName, ROUTE_URLS } from 'utils';
import { getAuthorizationInfo } from 'state';

interface RouteWithAuthProps extends RouteComponentProps {
  isAuthorized: boolean;
  children: any;
}

type Config = {
  canViewRoute?: boolean;
};

const mapStateToProps = state => ({
  isAuthorized: getAuthorizationInfo(state, 'isAuthorized'),
});

export default function withAuthorization(Component: ComponentType, { canViewRoute }: Config = {}) {
  const RouteWithAuth = ({ isAuthorized, ...restProps }: RouteWithAuthProps) => {
    const to = {
      state: { from: restProps.location.pathname },
      pathname: ROUTE_URLS.login,
    };

    return canViewRoute || isAuthorized ? <Component {...restProps} /> : <Redirect to={to} />;
  };

  RouteWithAuth.displayName = `RouteWithAuth(${getDisplayName(Component)})`;

  return compose(connect(mapStateToProps))(RouteWithAuth);
}
