/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { castArray, isEmpty, isObject, map, toPlainObject } from 'lodash';

import styles from './Errors.module.scss';

type Props = {
  global?: boolean;
  errors?: null | string | string[];
  contained?: boolean;
  className?: string;
};

const Errors = ({ errors, contained = false, global = false, className }: Props) => {
  if (isEmpty(errors)) {
    return null;
  }

  const errorsArray = isObject(errors) ? map(errors) : castArray(errors);
  const classes = classNames({
    [styles.contained]: contained,
    [styles.global]: global,
  });

  return (
    <div className={classes}>
      {map(errorsArray, (error: string) => {
        const [main, ...rest] = error.split('|');

        return (
          <div
            key={main}
            className={classNames(styles.error, className, {
              [styles.globalError]: global,
            })}
          >
            <Trans values={toPlainObject(rest)}>{main}</Trans>
          </div>
        );
      })}
    </div>
  );
};

export default Errors;
