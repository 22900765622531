/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { FieldProps } from 'redux-form';

import FormField from '../FormField';
import PasswordInput from '../PasswordInput';

export type PasswordFieldProps = FieldProps;

const PasswordField = (props: PasswordFieldProps) => {
  const { id, input, shouldShowErrorMessage, ...rest } = props;
  const inputId = `input-${id || input.name || ''}`;

  return (
    <FormField
      {...rest}
      fieldId={inputId}
      input={input}
      shouldShowErrorMessage={shouldShowErrorMessage}
    >
      <PasswordInput {...rest} {...input} id={inputId} />
    </FormField>
  );
};

export default PasswordField;
