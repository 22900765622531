/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: serviceStatuseTypes.ts
 *
 * @author: Panasonic, developer
 */

const OPERATIONAL = 'UP';
const OUTAGE = 'DOWN';
const UNDER_MAINTENANCE = 'UNDER_MAINTENANCE';

const LIST = [
  {
    value: OPERATIONAL,
    key: 'SERVICES.STATUSES.OPERATIONAL',
  },
  {
    value: OUTAGE,
    key: 'SERVICES.STATUSES.OUTAGE',
  },
  {
    value: UNDER_MAINTENANCE,
    key: 'SERVICES.STATUSES.UNDER_MAINTENANCE',
  },
];

export const SERVICE_STATUS_TYPES = {
  OPERATIONAL,
  OUTAGE,
  UNDER_MAINTENANCE,

  LIST,
};
