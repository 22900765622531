import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import classNames from 'classnames';

import { Region } from 'types';
import { texts } from 'utils';
import { TitleWithDescription } from 'components';

import styles from './RegionTabs.module.scss';

const regions: Region[] = [Region.US, Region.EU];

interface TabConfig {
  region: Region;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  'aria-selected': boolean;
}

const CustomTab = ({ region, onClick, 'aria-selected': selected }: TabConfig) => (
  <TitleWithDescription
    title={texts.healthStatusPage.tabs[region].title}
    description={texts.healthStatusPage.tabs[region].description}
    onClick={onClick}
    classes={{ root: styles.item, title: classNames(selected && styles.active, styles.title) }}
  />
);

const RegionTabs = ({
  currentTab,
  onChange,
}: {
  currentTab: Region;
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
}) => (
  <Tabs
    value={currentTab}
    onChange={onChange}
    variant="fullWidth"
    indicatorColor="primary"
    classes={{ root: styles.wrapper, indicator: styles.indicator }}
  >
    {regions.map(title => (
      // @ts-ignore
      <Tab value={title} key={title} component={CustomTab} region={title} />
    ))}
  </Tabs>
);

export default RegionTabs;
