/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useMemo, useCallback } from 'react';
import { TFunction } from 'i18next';

import { Id } from 'types';
import { ToolsWithActions } from 'components';

type Props = {
  original: any;
  tdProps: {
    rest: {
      t: TFunction;
      editService: (id: Id) => void;
    };
  };
};

const ServicesTools = ({
  original,
  tdProps: {
    rest: { editService },
  },
}: Props) => {
  const edit = useCallback(() => {
    editService(original);
  }, [editService, original]);

  const actions = useMemo(
    () => [
      {
        text: 'COMMON.EDIT',
        onClick: edit,
      },
    ],
    [edit],
  );

  return <ToolsWithActions data-test="services-table-tools" actions={actions} />;
};

export default ServicesTools;
