import { upperFirst, camelCase, isString, isNil } from 'lodash';

export const toPascalCase = (str: string = '') => upperFirst(camelCase(str));

const shouldTrim = (value: string) =>
  value && isString(value) && (value.includes('  ') || value === ' ');

export const trim = (value: string) => (shouldTrim(value) ? value.trim() : value);

export const removeSpecialCharacters = (str = '') =>
  str.replace(/[[\]{}()*+?\\^$|#]/g, '').replace(/ +/g, ' ');

export const getLabelValue = (value: any) => {
  switch (true) {
    case isNil(value):
    case typeof value === 'string' && !value:
      return '-';
    default:
      return String(value);
  }
};
