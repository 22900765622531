/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React, { useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { number, func } from 'prop-types';
import { isNaN } from 'lodash';

import { isEnterKeyPress } from 'utils';
import Input from '../../../Forms/Input';

import styles from './PageJump.module.scss';

const getSafePage = (value, totalPages, defaultPage) => {
  if (value === '') return value;

  const page = parseInt(value, 10);
  const safePage = isNaN(page) ? defaultPage : page;

  return Math.min(Math.max(safePage, 1), totalPages - 1);
};

const PageJump = ({ page, totalPages, onChange }) => {
  const [value, setValue] = useState(page + 1);
  const [prevPage, setPrevPage] = useState(page);

  const changePage = ({ target }) => {
    const safeValue = getSafePage(target.value, totalPages + 1, page + 1);

    setValue(safeValue);
  };

  const applyPage = useCallback(
    (event?: any) => {
      if (event) {
        event.preventDefault();
      }

      const safeValue = value || page + 1;

      setValue(safeValue);
      onChange(safeValue - 1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  const goToPageKeyPress = event => {
    if (isEnterKeyPress(event)) applyPage();
  };

  if (prevPage !== page) {
    setPrevPage(page);
    setValue(page + 1);
  }

  return (
    <div className={styles.pageJump}>
      <span>
        <Trans>COMMON.JUMP_TO</Trans>
      </span>
      <Input
        className={styles.pageJumpInput}
        onChange={changePage}
        value={value}
        onBlur={applyPage}
        onKeyPress={goToPageKeyPress}
      />
    </div>
  );
};

PageJump.propTypes = {
  page: number.isRequired,
  totalPages: number.isRequired,
  onChange: func.isRequired,
};

export default PageJump;
