/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Form, reduxForm, InjectedFormProps } from 'redux-form';

import { requiredValidator, ROUTE_URLS } from 'utils';
import { loginCreator } from 'state';
import { Field, InputField, PasswordInputField, SimplePage, Button, Errors } from 'components';

import styles from './Login.module.scss';

interface Credentials {
  username: string;
  password: string;
}

interface Props {
  login: (params: Credentials) => Promise<void>;
  onSubmitSuccess: () => any;
}

const Login = ({ invalid, submitting, handleSubmit, login, error }: InjectedFormProps<Props>) => (
  <SimplePage title="AUTH.LOGIN.TITLE" description="AUTH.LOGIN.HINT">
    <Form onSubmit={handleSubmit(login)} className={styles.form}>
      <div className={styles.fields}>
        <Field
          autoFocus
          name="username"
          type="text"
          placeholder="COMMON.USERNAME"
          component={InputField}
          validate={[requiredValidator]}
        />

        <Field
          name="password"
          type="password"
          placeholder="COMMON.PASSWORD"
          component={PasswordInputField}
          validate={[requiredValidator]}
        />

        {error && <Errors errors={error} contained />}
      </div>

      <Button
        variant="contained"
        type="submit"
        className={styles.btn}
        disabled={invalid}
        isLoading={submitting}
      >
        <Trans i18nKey="AUTH.LOGIN.LOGIN" />
      </Button>
    </Form>
  </SimplePage>
);

const mapDispatchToProps = (dispatch, { history }) => ({
  login: bindActionCreators(loginCreator, dispatch),
  onSubmitSuccess: () => history.push(ROUTE_URLS.healthStatus),
});

const initialValues: Credentials = { username: '', password: '' };

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: 'login',
    initialValues,
  }),
  // @ts-ignore
)(Login);
