/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component, ReactNode } from 'react';
import classnames from 'classnames';
import { Dialog, DialogActions } from '@material-ui/core';
import { Trans } from 'react-i18next';
import CloseIcon from 'mdi-react/CloseIcon';
import { Grid } from '@material-ui/core';

import Button from '../../Button';

import styles from './DialogBoilerplate.module.scss';

const modalProps = {
  BackdropProps: {
    classes: {
      root: styles.backdrop,
    },
  },
};

type VoidAnyFunc = (e: any) => void | undefined;

export type CommonDialogPropTypes = {
  open?: boolean;
  title?: string;
  onConfirm?: VoidAnyFunc;
  onCancel?: VoidAnyFunc;
  confirmLabel?: string;
  cancelLabel?: string;
  dialogProps?: any;
  children: ReactNode;
};

type DialogBolerplate = CommonDialogPropTypes & {
  onConfirm: VoidAnyFunc;
  onCancel: VoidAnyFunc;
  confirmLabel?: string;
  cancelLabel?: string;
  withActions?: boolean;
  disableConfirm?: boolean;
  isSubmitting?: boolean;
};

class DialogBoilerplate extends Component<DialogBolerplate> {
  static defaultProps = {
    onCancel: () => {},
    onConfirm: () => {},
    confirmLabel: 'COMMON.OK',
    cancelLabel: 'COMMON.CANCEL',
    withActions: true,
    disableConfirm: false,
    isSubmitting: false,
    dialogProps: {},
  };

  handleCancel = () => this.props.onCancel('cancel');
  handleConfirm = () => this.props.onConfirm('confirm');

  render() {
    const {
      open,
      title,
      confirmLabel,
      cancelLabel,
      children,
      dialogProps: { contentClassName, ...rest },
      withActions,
      disableConfirm,
      isSubmitting,
    } = this.props;
    const contentClassNames = classnames(styles.content, contentClassName);

    return (
      <div>
        <Dialog
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          className={styles.wrapper}
          {...modalProps}
          {...rest}
          open={open}
          onClose={this.handleCancel}
        >
          <Grid container justify="space-between" alignItems="center" className={styles.header}>
            <div id="dialog-title" className={styles.title}>
              <Trans>{title}</Trans>
            </div>
            <div onClick={this.handleCancel}>
              <CloseIcon size={20} color="#999999" className={styles.closeIcon} />
            </div>
          </Grid>
          <section className={contentClassNames}>{children}</section>
          {withActions && (
            <DialogActions className={styles.actions}>
              <Button
                onClick={this.handleCancel}
                variant="outlined"
                color="primary"
                className={styles.btn}
              >
                <Trans i18nKey={cancelLabel} />
              </Button>
              <Button
                onClick={this.handleConfirm}
                variant="contained"
                color="primary"
                className={styles.btn}
                disabled={disableConfirm}
                isLoading={isSubmitting}
              >
                <Trans i18nKey={confirmLabel} />
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default DialogBoilerplate;
