/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { combineReducers } from 'redux';

import modal, { modalModuleName } from './modal';

export * from './modal';

export default combineReducers({
  [modalModuleName]: modal,
});
