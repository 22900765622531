/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import { Grid } from '@material-ui/core';

import { ServiceGroup } from 'types';
import { Table, TitleWithDescription } from 'components';
import { texts } from 'utils';

import ServicesList from '../ServicesList';
import Legend from '../../components/Legend';
import ServiceGroupInfo from '../../components/ServiceGroupInfo';
import columns from './columns';

import styles from './ServiceGroupsList.module.scss';

interface Props {
  isAuthorized: boolean;
  services: ServiceGroup[];
}

const ServiceGroupsList = ({ services = [], isAuthorized }: Props) => (
  <div className={styles.wrapper}>
    <Grid container justify="space-between" alignItems="center" className={styles.header}>
      <Grid item>
        <TitleWithDescription
          title={texts.healthStatusPage.list.title}
          description={texts.healthStatusPage.list.description}
        />
      </Grid>
    </Grid>

    {isAuthorized ? (
      <Table
        data={services}
        minRows={services.length}
        columns={columns}
        onTableStateChange={() => {}}
        SubComponent={({ original: { id: serviceGroup } }) => (
          <ServicesList serviceGroup={serviceGroup} />
        )}
      />
    ) : (
      <Grid container>
        {services.map(service => (
          <ServiceGroupInfo {...service} key={service.id} className={styles.item} />
        ))}
      </Grid>
    )}

    <Legend />
  </div>
);

export default ServiceGroupsList;
