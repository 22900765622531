/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { createSelector } from 'reselect';
import { get, isArray, uniqueId } from 'lodash';

import {
  makeApiAction,
  selectorMakers,
  getSuccessType,
  getFailedType,
  getFetchType,
  getStopFetchingType,
  getStartFetchingType,
  URLS,
} from 'utils';
import { Service } from 'types';

/*
 * TYPES
 * */
export const incidentAvailableServicesModuleName = 'incidentAvailableServices';
const descriptor = 'INCIDENT_AVAILABLE_SERVICES';

const FETCH_INCIDENT_AVAILABLE_SERVICES = getFetchType(descriptor);
const FETCH_INCIDENT_AVAILABLE_SERVICES_SUCCESS = getSuccessType(FETCH_INCIDENT_AVAILABLE_SERVICES);
const FETCH_INCIDENT_AVAILABLE_SERVICES_FAILED = getFailedType(FETCH_INCIDENT_AVAILABLE_SERVICES);
const STOP_INCIDENT_AVAILABLE_SERVICES_FETCHING = getStopFetchingType(descriptor);
const START_INCIDENT_AVAILABLE_SERVICES_FETCHING = getStartFetchingType(descriptor);

const INCIDENT_AVAILABLE_SERVICES_RESET = 'INCIDENT_AVAILABLE_SERVICES_RESET';
const INCIDENT_AVAILABLE_SERVICES_ADD = 'INCIDENT_AVAILABLE_SERVICES_ADD';

/*
 * ACTIONS
 * */

export const incidentAvailableServicesActions = {
  fetchIncidentAvailableServices: makeApiAction({
    baseUrl: URLS.incidents.availableServices,
    processingDescriptor: descriptor,
    initialActionType: FETCH_INCIDENT_AVAILABLE_SERVICES,
  }),
  addNewService: service => dispatch =>
    dispatch({
      type: INCIDENT_AVAILABLE_SERVICES_ADD,
      payload: service,
    }),
  resetNewService: () => dispatch =>
    dispatch({
      type: INCIDENT_AVAILABLE_SERVICES_RESET,
    }),
};

export interface IncidentAvailableServices {
  newServices: Service[];
  services: Service[];
  isLoading: boolean;
  error: any;
}

const initialValues = { services: [], newServices: [], isLoading: false, error: null };

export function incidentAvailableServicesReducer(
  state: IncidentAvailableServices = initialValues,
  { type, payload }: any,
) {
  switch (type) {
    case INCIDENT_AVAILABLE_SERVICES_ADD:
      if (isArray(payload)) {
        return {
          ...state,
          newServices: payload.map(service => ({ ...service, id: uniqueId('service_') })),
        };
      }

      const isNewService = !payload.id;

      if (isNewService) {
        return {
          ...state,
          newServices: [...state.newServices, { ...payload, id: uniqueId('service_') }],
        };
      }

      return {
        ...state,
        newServices: state.newServices.map((item: any) =>
          item.id === payload.id ? payload : item,
        ),
      };

    case INCIDENT_AVAILABLE_SERVICES_RESET:
      return {
        ...state,
        newServices: [],
      };

    case START_INCIDENT_AVAILABLE_SERVICES_FETCHING:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_INCIDENT_AVAILABLE_SERVICES_FETCHING:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_INCIDENT_AVAILABLE_SERVICES_SUCCESS:
      return {
        ...state,
        error: null,
        services: payload,
      };

    case FETCH_INCIDENT_AVAILABLE_SERVICES_FAILED:
      return {
        ...state,
        services: [],
        error: payload,
      };

    default:
      return state;
  }
}

/*
 * SELECTORS
 * */

const getState = selectorMakers.makeGetEntitiesState<IncidentAvailableServices>(
  incidentAvailableServicesModuleName,
);

const prepareList = createSelector(
  getState,
  state => {
    const { services } = state;
    const list = services.map(service => {
      const name = get(service, 'name');
      const region = get(service, 'region');
      const result = `(${region}) ${name}`;

      return {
        ...service,
        key: result,
        value: result,
      };
    });

    return {
      ...state,
      services: list,
    };
  },
);

export const getNewServices = createSelector(
  getState,
  state => state.newServices,
);

export const getIncidentAvailableServices = createSelector(
  prepareList,
  state => state,
);
