/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import classNames from 'classnames';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';

import styles from './Expander.module.scss';

type Props = {
  type?: 'chevron' | 'menu' | 'math';
  isExpanded?: boolean;
  className?: string;
  onClick?: any;
};

const types = {
  chevron: {
    expanded: <ChevronUpIcon />,
    collapsed: <ChevronDownIcon />,
  },
  menu: {
    expanded: <MenuUpIcon />,
    collapsed: <MenuDownIcon />,
  },
  math: {
    expanded: <MinusIcon />,
    collapsed: <PlusIcon />,
  },
};

function Expander({ className, isExpanded, type, onClick }: Props) {
  const expandedIcon = type && types[type].expanded;
  const collapsedIcon = type && types[type].collapsed;
  const classes = classNames(styles.wrapper, className);

  return (
    <div className={classes} onClick={onClick} role="presentation">
      {isExpanded ? expandedIcon : collapsedIcon}
    </div>
  );
}

Expander.defaultProps = {
  type: 'chevron',
  isExpanded: false,
  className: '',
  onClick: () => {},
};

export default Expander;
