/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */
import React, { ComponentType, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { compose } from 'redux';
import { Form, reduxForm, InjectedFormProps } from 'redux-form';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';

import {
  INCIDENT_STATUS_TYPES,
  urlValidator,
  requiredValidator,
  INCIDENT_GENERAL_FORM,
  maxLengthValidator,
  minLengthValidator,
} from 'utils';
import { getIncident } from 'state';
import { CustomField, Field, FormPanel, InputField, SelectField, TextareaField } from 'components';

import styles from './General.module.scss';

const max128 = maxLengthValidator(128);
const min5 = minLengthValidator(5);

const DESCRIPTION_VALUES = {
  [INCIDENT_STATUS_TYPES.INVESTIGATION]: 'INCIDENTS.STATUSES_DESCRIPTIONS.INVESTIGATION',
  [INCIDENT_STATUS_TYPES.IDENTIFIED]: 'INCIDENTS.STATUSES_DESCRIPTIONS.IDENTIFIED',
  [INCIDENT_STATUS_TYPES.RESOLVED]: 'INCIDENTS.STATUSES_DESCRIPTIONS.RESOLVED',
};

const General = ({ handleSubmit, change }: InjectedFormProps) => {
  const handleStatusChange = useCallback(
    value => {
      change('description', i18next.t(DESCRIPTION_VALUES[value]));
    },
    [change],
  );

  return (
    <FormPanel
      label="INCIDENT.GENERAL.TITLE"
      description="INCIDENT.GENERAL.HINT"
      classes={{
        root: styles.block,
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Field
          name="summary"
          type="text"
          label="INCIDENT.GENERAL.SUMMARY"
          component={InputField}
          validate={[requiredValidator, min5, max128]}
          maxLength={128}
          required
        />

        <Grid item xs={6}>
          <CustomField
            name="status"
            type="text"
            label="INCIDENT.GENERAL.STATUS"
            component={SelectField}
            validate={[requiredValidator]}
            onChange={handleStatusChange}
            required
            options={INCIDENT_STATUS_TYPES.LIST}
          />
        </Grid>

        <CustomField
          name="description"
          label="INCIDENT.GENERAL.DESCRIPTION"
          component={TextareaField}
          validate={[requiredValidator, min5]}
          maxLength={256}
          required
        />

        <Field
          name="jiraTicket"
          type="text"
          label="INCIDENT.GENERAL.JIRA_LINK"
          validate={[urlValidator]}
          component={InputField}
        />
      </Form>
    </FormPanel>
  );
};

const mapStateToProps = state => {
  const incident = getIncident(state);

  return {
    initialValues: {
      summary: get(incident, 'summary', ''),
      description: get(incident, 'description', ''),
      jiraTicket: get(incident, 'jiraTicket', ''),
      status: get(incident, 'status', ''),
    },
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: INCIDENT_GENERAL_FORM,
    enableReinitialize: true,
  }),
  // @ts-ignore
)(General) as ComponentType;
