/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component, ReactElement } from 'react';
import classNames from 'classnames';
import { compact, get, values, clone, isEqual } from 'lodash';

import { Meta } from 'types';
import { SORTING_STATES, TABLE_PAGE_SIZES, tryToInvoke } from 'utils';
import Table, { Props as TableProps } from '../Table';
import Search from '../../Search';

import styles from './PaginatedTable.module.scss';

const { ASC, DESC } = SORTING_STATES;
// https://github.com/facebook/flow/issues/2221
const pageSizeOptions = values(TABLE_PAGE_SIZES);

export type PaginatedTableProps = TableProps & {
  data: any[];
  defaultPageSize?: number;
  meta: Meta;
  onTableStateChange: (x: any) => any;
  onWillUnmount?: () => void;
  searchProps?: any;
  withSearch?: boolean;
  className?: string;
  isSubTable?: boolean;
  searchQuery?: string;
  queryParams?: Record<string, any>;
  // TODO reduce to one
  additionalTools?: () => ReactElement<any> | null; // top left
  entityTools?: () => any; // top right
  customTools?: () => any; // top bottom full width handled in Table
  showPageJump?: boolean;
  loading?: boolean;
  resizable?: boolean;
  classes?: {
    root?: string;
    table?: string;
    tools?: string;
    paginationRoot?: string;
    paginationControl?: string;
  };
};

type State = {
  searchQuery: string;
};

class PaginatedTable extends Component<PaginatedTableProps, State> {
  static defaultProps = {
    additionalTools: undefined,
    defaultPageSize: TABLE_PAGE_SIZES.DEFAULT,
    onWillUnmount: () => {},
    searchProps: {},
    withSearch: false,
    entityTools: undefined,
    className: '',
    isSubTable: false,
    classes: {},
    customTools: undefined,
    showPageJump: true,
    loading: false,
    getNoDataProps: undefined,
    searchQuery: undefined,
    queryParams: {},
    resizable: true,
  };

  queryParams: any;

  constructor(props: PaginatedTableProps) {
    super(props);

    // HACK to reset search query after table reinit
    this.queryParams = {
      search: undefined,
    };
  }

  state = {
    searchQuery: '',
  };

  componentDidUpdate(prevProps: PaginatedTableProps) {
    const { queryParams } = this.props;

    if (!isEqual(prevProps.queryParams, queryParams)) {
      this.updateParams({ pageNumber: 0 });
      this.props.onTableStateChange({ ...this.queryParams, ...queryParams });
    }
  }

  componentWillUnmount() {
    if (this.props.onWillUnmount) this.props.onWillUnmount();
  }

  onTableStateChange = (state: any) => {
    const { searchQuery } = this.state;
    const { pageSize, sorted, page: pageNumber } = state;
    const sort =
      (sorted && sorted.map(({ id, desc }) => `${id},${desc ? DESC : ASC}`)) || undefined;

    const prevParams = clone(this.queryParams);

    this.updateParams({ pageSize, pageNumber, sort, search: searchQuery || undefined });

    if (!isEqual(prevParams, this.queryParams)) {
      this.props.onTableStateChange({ ...this.queryParams, ...this.props.queryParams });
    }
  };

  onSearchSubmit = (query: string) => {
    this.updateParams({ search: query || undefined, pageNumber: 0 });
    this.setState({ searchQuery: query });
    this.props.onTableStateChange({ ...this.queryParams, ...this.props.queryParams });
  };

  get pageSizeOptions() {
    const {
      meta: { totalItemsCount },
    } = this.props;

    return compact([
      ...pageSizeOptions.filter((size: number) => size <= totalItemsCount),
      pageSizeOptions.find(size => size > totalItemsCount),
    ]);
  }

  get hasTools() {
    const { additionalTools, withSearch, entityTools } = this.props;
    const { searchQuery } = this.state;

    return additionalTools || withSearch || entityTools || searchQuery;
  }

  get actionButton() {
    const { getNoDataProps } = this.props;

    return getNoDataProps && get(getNoDataProps(), 'actionButton');
  }

  updateParams(params: any) {
    this.queryParams = {
      ...this.queryParams,
      ...params,
    };
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      meta: { totalPages, totalItemsCount },
      className,
      withSearch,
      searchProps,
      additionalTools,
      entityTools,
      data,
      classes,
      isSubTable,
      customTools,
    } = this.props;
    const { searchQuery } = this.state;
    const hasData = !!data.length;
    const searchClassNames = classNames(styles.search, searchProps && searchProps.className);
    const rootClasses = classNames(styles.wrapper, className, classes && classes.root, {
      [styles.noTools]: !this.hasTools && !isSubTable,
    });
    const tableClasses = classNames(className, classes && classes.table);
    const toolsClasses = classNames(
      styles.tools,
      classes && classes.tools,
      customTools && styles.withCustomTools,
    );

    return (
      <section className={rootClasses}>
        {this.hasTools && (
          <div className={toolsClasses}>
            {tryToInvoke(additionalTools)}
            {((hasData && withSearch) || searchQuery) && (
              <Search
                {...searchProps}
                onSubmit={this.onSearchSubmit}
                className={searchClassNames}
              />
            )}
            {(hasData || searchQuery || !this.actionButton) && tryToInvoke(entityTools)}
          </div>
        )}
        <Table
          manual
          sortable
          showPagination={hasData && totalItemsCount > TABLE_PAGE_SIZES.MIN}
          minRows={data.length}
          pages={totalPages}
          pageSizeOptions={this.pageSizeOptions}
          onFetchData={this.onTableStateChange}
          showPageJump
          getPaginationProps={() => ({
            totalItemsCount,
            isSubTable,
            classes,
            page: this.queryParams.pageNumber,
          })}
          className={tableClasses}
          {...this.props}
        />
      </section>
    );
  }
}

export default PaginatedTable;
