/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: functions.ts
 *
 * @author: Panasonic, developer
 */

import { isFunction } from 'lodash';

/**
 * checks if passed argument is function and invoke it or pass back if argument is not a function
 * main usage disabled as value or function
 *
 * @param func
 * @param args arguments to first argument if it function
 * @return {any} result of function execution or passed argument itself
 */
export const invokeOrGet = (func: any, ...args: any[]) => (isFunction(func) ? func(...args) : func);

export const stub = () => null;

/**
 * call func if it is of type "function"
 *
 * @param func - function to call
 * @return {any} - result of called function or null
 */
export const tryToInvoke = (func: any, ...rest: any[]): any => {
  if (isFunction(func)) return func(...rest);

  return null;
};

/**
 * add resolve/reject to action object to properly handle redux-form submit and completion if async operation
 *
 * depends on redux-thunk
 *
 * @param actionCreator - simple action creator
 * @return {Promise}
 */
export const wrapActionCreatorInPromise = (actionCreator: any) => (...args: any[]) => (
  dispatch: (x: any) => any,
) =>
  new Promise<any>((resolve, reject) => {
    const initialAction = actionCreator(...args);

    dispatch({ ...initialAction, resolve, reject });
  });
