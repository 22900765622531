/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { modalActions, getActiveModals } from 'state';
import { ConfirmDialog, PromptDialog, FormDialog } from 'components';
import { MODAL_TYPES } from 'utils';
import { ModalConfig, Id } from 'types';

const modalRegister = {
  [MODAL_TYPES.CONFIRM_DIALOG]: ConfirmDialog,
  [MODAL_TYPES.PROMPT_DIALOG]: PromptDialog,
  [MODAL_TYPES.FORM_DIALOG]: FormDialog,
};

type Props = {
  activeModals: ModalConfig[];
  actions: {
    closeModal: (id: Id, isConfirmClick: boolean, result: any) => void;
  };
};

class ModalRoot extends Component<Props> {
  onRequestClose(id, result, cb, isConfirmClick) {
    if (cb) cb(result);

    this.props.actions.closeModal(id, isConfirmClick, result);
  }

  render() {
    const {
      activeModals,
      actions: { closeModal },
    } = this.props;

    return activeModals.map(config => {
      const { modalType, id, onCancel, onConfirm } = config;
      const Modal = modalRegister[modalType];

      return (
        <Modal
          {...config}
          open
          key={id}
          modalId={id}
          closeModal={closeModal}
          onCancel={reason => this.onRequestClose(id, reason, onCancel, false)}
          onConfirm={result => this.onRequestClose(id, result, onConfirm, true)}
        />
      );
    });
  }
}

const mapStateToProps = state => ({
  activeModals: getActiveModals(state),
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators<any, any>(modalActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalRoot);
