/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component } from 'react';
import classNames from 'classnames';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';

import { removeTranslatorProps } from 'utils';
import Input from '../Input';

import styles from './Password.module.scss';

interface PasswordProps {
  placeholder?: string;
  name?: string;
  id?: string;
  error?: boolean | string;
}

interface State {
  type: string;
}

class PasswordInput extends Component<PasswordProps, State> {
  state = {
    type: 'password',
  };

  get isPasswordHidden() {
    return this.state.type === 'password';
  }

  togglePasswordVisibility = e => {
    e.stopPropagation();
    this.setState(() => ({ type: this.isPasswordHidden ? 'text' : 'password' }));
  };

  render() {
    const { placeholder = '', error, ...rest } = this.props;

    const { type } = this.state;
    const restProps = removeTranslatorProps(rest);
    const rootClassName = classNames(styles.inputWrapper, {
      [styles.error]: error,
    });

    return (
      <div className={rootClassName}>
        <Input
          {...restProps}
          placeholder={placeholder}
          className={styles.input}
          type={type}
          data-test="password-input"
        />

        <button
          type="button"
          className={styles.togglePassword}
          onClick={this.togglePasswordVisibility}
        >
          {this.isPasswordHidden ? (
            <EyeIcon size={20} color="#9B9B9B" />
          ) : (
            <EyeOffIcon size={20} color="#9B9B9B" />
          )}
        </button>
      </div>
    );
  }
}

export default PasswordInput;
