import React from 'react';
import { Error, CheckCircle } from '@material-ui/icons';

import { ServiceStatus, ServiceGroupStatus } from 'types';
import { UnderMaintenanceIcon } from 'media';
import { SERVICE_STATUS_TYPES, SERVICE_GROUP_STATUS_TYPES } from 'utils';

interface Props {
  status: ServiceStatus | ServiceGroupStatus | string;
  className?: string;
}

function StatusIndicator({ status, className }: Props) {
  switch (status) {
    case SERVICE_GROUP_STATUS_TYPES.OPERATIONAL:
    case SERVICE_STATUS_TYPES.OPERATIONAL:
      return <CheckCircle style={{ fill: '#21d488' }} className={className} />;

    case SERVICE_GROUP_STATUS_TYPES.OUTAGE:
    case SERVICE_STATUS_TYPES.OUTAGE:
      return <Error style={{ fill: '#ff5630' }} className={className} />;

    case SERVICE_GROUP_STATUS_TYPES.PARTIAL_OUTAGE:
      return <Error style={{ fill: '#ffc400' }} className={className} />;

    case SERVICE_GROUP_STATUS_TYPES.DEGRADED_PERFORMANCE:
      return <CheckCircle style={{ fill: '#999999' }} className={className} />;

    case SERVICE_GROUP_STATUS_TYPES.UNDER_MAINTENANCE:
    case SERVICE_STATUS_TYPES.UNDER_MAINTENANCE:
      return <UnderMaintenanceIcon style={{ fill: '#0041c0' }} className={className} />;

    default:
      return null;
  }
}

export default StatusIndicator;
