/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: incident.ts
 *
 * @author: Panasonic, developer
 */

import { Id } from './common';

export enum Region {
  US = 'US',
  EU = 'EU',
}

export enum ServiceStatus {
  Operational = 'UP',
  Outage = 'DOWN',
  UnderMaintenance = 'UNDER_MAINTENANCE',
}

export enum ServiceGroupStatus {
  Operational = 'OPERATIONAL',
  Outage = 'OUTAGE',
  PartialOutage = 'PARTIAL_OUTAGE',
  DegradedPerformance = 'DEGRADED_PERFORMANCE',
  UnderMaintenance = 'UNDER_MAINTENANCE',
}

export enum IncidentStatus {
  Investigation = 'INVESTIGATION',
  Identified = 'IDENTIFIED',
  Resolved = 'RESOLVED',
  Draft = 'DRAFT',
}

interface User {
  firstName: string;
  lastName: string;
}

export interface Service {
  name: string;
  region: Region;
  status: ServiceStatus;
  id?: Id;
}

export interface ServiceGroup {
  name: string;
  region: Region;
  status: ServiceGroupStatus;
  id?: Id;
  version?: string;
  updatedAt?: string;
}

export interface Incident {
  id?: number;
  draft: boolean;
  summary?: string;
  status?: IncidentStatus;
  createdBy?: User;
  createdAt?: string;
  description?: string;
  services?: Service[];
  updatedBy?: User;
  updatedAt?: string;
}
