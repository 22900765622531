/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { ReactNode } from 'react';
import classNames from 'classnames';

import Panel from './components/Panel';

import styles from './SummaryPanel.module.scss';

type Props = {
  data: any;
  i18nMapper: {
    // eslint-disable-next-line no-undef
    labels: object;
    // eslint-disable-next-line no-undef
    values?: object;
  };
  i18nNamespace: string;
  classes: {
    root?: string;
    panelItem?: string;
    panel?: string;
  };
  components: { [x: string]: ReactNode };
  // eslint-disable-next-line no-undef
  entity?: object;
};

function SummaryPanel(props: Props) {
  const { data, ...rest } = props;
  const wrapperClassName = classNames(styles.summaryPanel, props.classes.root);

  return (
    <div className={wrapperClassName}>
      {Object.entries(data).map(([key, fields]: [string, any]) => (
        <Panel key={key} title={key} fields={fields} {...rest} />
      ))}
    </div>
  );
}

SummaryPanel.defaultProps = {
  classes: {
    root: '',
    panelItem: '',
    panel: '',
  },
  components: {},
};

export default SummaryPanel;
