/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { RouteComponentProps } from 'react-router';
import { Trans } from 'react-i18next';
import { head, pick } from 'lodash';
import { compile } from 'path-to-regexp';

import { Meta, Id, Incident, Service } from 'types';
import {
  getIncidents,
  getIncidentsMeta,
  getIncidentsIsFetching,
  getIncidentsQueryParams,
  incidentActions,
  incidentsActions,
} from 'state';
import { ROUTE_URLS } from 'utils';
import { PageHeader, PaginatedTable, Button } from 'components';
import { EmptyStateIcon } from 'media';
import { useEnsureOperationalServices } from '../IncidentEdit/hooks';
import columns from './columns';
import IncidentsSubTable from './IncidentsSubTable';

import styles from './Incidents.module.scss';

const incidentPath = compile(ROUTE_URLS.incident);
const editIncidentPath = compile(ROUTE_URLS.incidentEdit);

type StateToProps = {
  meta: Meta;
  incidents: Incident[];
  isFetching: boolean;
  incidentsQueryParams: any;
};

type DispatchToProps = {
  actions: {
    fetchIncidents: (params: any) => void;
    deleteIncident: ({ id }: { id: Id }) => Promise<any>;
    archiveIncident: ({ id }: { id: Id }) => Promise<any>;
    updateIncident: ({ id: Id }) => Promise<any>;
  };
};

const Incidents = ({
  actions: { archiveIncident, deleteIncident, fetchIncidents, updateIncident },
  incidents,
  meta,
  isFetching,
  incidentsQueryParams,
  history: { push },
}: StateToProps & DispatchToProps & RouteComponentProps<any>) => {
  const ensureOperationalServices = useEnsureOperationalServices();

  const fetchData = tableState => {
    fetchIncidents({ queryParams: tableState });
  };

  const getIncidentTools = useCallback(
    () => (
      <Button
        variant="contained"
        onClick={() => push(ROUTE_URLS.incidentNew)}
        className={styles.createButton}
      >
        <Trans i18nKey="INCIDENT.BREADCRUMBS.CREATE" />
      </Button>
    ),
    [push],
  );

  const getNoDataProps = useCallback(
    () => ({
      label: 'INCIDENTS.NO_DATA.LABEL',
      description: 'INCIDENTS.NO_DATA.DESCRIPTION',
      icon: EmptyStateIcon,
      actionButton: (
        <Button
          onClick={() => push(ROUTE_URLS.incidentNew)}
          variant="contained"
          className={styles.button}
          type="button"
        >
          <Trans i18nKey="COMMON.CREATE_NEW" />
        </Button>
      ),
    }),
    [push],
  );

  const viewDetails = useCallback(({ id }) => push(incidentPath({ id })), [push]);

  const editIncident = useCallback(({ id }) => push(editIncidentPath({ id })), [push]);

  const deleteIncidentHandler = useCallback(
    ({ id }) => {
      deleteIncident({ id }).then(() => fetchIncidents({ queryParams: incidentsQueryParams }));
    },
    [fetchIncidents, deleteIncident, incidentsQueryParams],
  );

  const archiveIncidentHandler = useCallback(
    async ({ id, ...rest }) => {
      const formValues = {
        id,
        ...pick(head(rest.states), ['services', 'description']),
        ...pick(rest, ['summary', 'status', 'jiraTicket']),
      };

      const performArchive = () =>
        archiveIncident({ id }).then(() => fetchIncidents({ queryParams: incidentsQueryParams }));

      const performArchiveWithUpdate = (services?: Service[]) => {
        const values = services ? { ...formValues, services } : formValues;

        updateIncident({ id, ...values }).then(performArchive);
      };

      ensureOperationalServices(formValues, performArchiveWithUpdate, performArchive, false);
    },
    [
      fetchIncidents,
      archiveIncident,
      incidentsQueryParams,
      updateIncident,
      ensureOperationalServices,
    ],
  );

  return (
    <div className="full-width">
      <PageHeader
        label="INCIDENTS.PAGE_HEADER.LABEL"
        description="INCIDENTS.PAGE_HEADER.DESCRIPTION"
        showBreadcrumbs={false}
      />

      <div className="route-container">
        <PaginatedTable
          data={incidents}
          meta={meta}
          columns={columns}
          withSearch
          onTableStateChange={fetchData}
          loading={isFetching}
          getTdProps={() => ({
            viewDetails,
            deleteIncident: deleteIncidentHandler,
            archiveIncident: archiveIncidentHandler,
            editIncident,
          })}
          SubComponent={({ original: { states } }) => <IncidentsSubTable data={states} />}
          getNoDataProps={getNoDataProps}
          entityTools={getIncidentTools}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  incidents: getIncidents(state),
  meta: getIncidentsMeta(state),
  isFetching: getIncidentsIsFetching(state),
  incidentsQueryParams: getIncidentsQueryParams(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators<(dispatch: Dispatch) => void, any>(
    { ...incidentActions, ...incidentsActions },
    dispatch,
  ),
});

export default connect<StateToProps, DispatchToProps, RouteComponentProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Incidents);
