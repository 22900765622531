/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component } from 'react';
import { Field as ReduxField, FieldProps } from 'redux-form';
import { castArray } from 'lodash';

import { requiredValidator, includes, definedValidator, trim } from 'utils';

export type CustomFieldProps = FieldProps & {
  maxLength?: number;
  disabled?: boolean;
  validate?: (any) => any;
};

class Field extends Component<CustomFieldProps> {
  static defaultProps = {
    maxLength: undefined,
    normalize: trim,
    disabled: undefined,
  };

  get isRequired() {
    const { validate } = this.props;

    return includes(castArray(validate), [requiredValidator, definedValidator], false);
  }

  warning = () => {
    const { maxLength } = this.props;

    return maxLength && this.maxLengthWarning;
  };

  maxLengthWarning = (value: any) => {
    const { maxLength } = this.props;

    return maxLength && value && value.length >= maxLength && `ERRORS.ERR_10|${maxLength}`;
  };

  render() {
    return <ReduxField required={this.isRequired} warn={this.warning()} {...this.props} />;
  }
}

export default Field;
