/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: array.ts
 *
 * @author: Panasonic, developer
 *
 */

import { difference, intersection } from 'lodash';

export const includes = (
  collection: Array<any>,
  value: Array<any> | any,
  fullMatch: boolean = true,
): boolean => {
  if (Array.isArray(value)) {
    return fullMatch
      ? !difference(value, collection).length
      : !!intersection(collection, value).length;
  }

  return collection.includes(value);
};

export const toggleItemInArray = (arr: any[], item: any): any[] =>
  arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item];

export const notEmptyArray = (val = []) => !val.length && 'ERRORS.ERR_08';
