import { IS_DEV, IS_PROD } from './env';

declare global {
  interface Window {
    CONFIG: {
      API_URL: string;
    };
  }
}

const base = IS_DEV && 'https://gb-test-5.oc-status-api.us-east-1.qa.oneconnect.net';
const BASE_URL = (IS_PROD && window.CONFIG.API_URL) || base || '';

export default {
  auth: `${BASE_URL}/auth/token`,
  login: `${BASE_URL}/login`,
  logout: `${BASE_URL}/logout`,
  resetPassword: `${BASE_URL}/user/reset-password`,
  servicesInfo: `${BASE_URL}/public/service-groups`,
  servicesPrivateInfo: `${BASE_URL}/service-groups`,
  serviceDetails: `${BASE_URL}/services/:id`,
  incidents: {
    root: `${BASE_URL}/incidents`,
    public: `${BASE_URL}/public/incidents`,
    history: `${BASE_URL}/public/incidents/history`,
    incident: `${BASE_URL}/incidents/:id`,
    incidentCurrentState: `${BASE_URL}/incidents/:id/current-state`,
    archive: `${BASE_URL}/incidents/:id/archive`,
    publish: `${BASE_URL}/incidents/:id/publish`,
    availableServices: `${BASE_URL}/incidents/available-services`,
  },
};
