export default {
  root: '/',

  healthStatus: '/health-status',
  login: '/login',
  resetPassword: '/reset-password',
  incidentsHistory: '/incidents-history',

  // private
  incidents: '/incidents',
  incident: '/incidents/:id',
  incidentEdit: '/incidents/:id/edit',
  incidentNew: '/incidents/new/edit',
};
