/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import classnames from 'classnames';
import TimerSandEmptyIcon from 'mdi-react/TimerSandEmptyIcon';
import DeveloperBoardIcon from 'mdi-react/DeveloperBoardIcon';

import LabelWithDescription from '../LabelWithDescription';

import styles from './PanelWithIcon.module.scss';

const icons = {
  empty: () => null,
  timerSandEmpty: TimerSandEmptyIcon,
  developerBoard: DeveloperBoardIcon,
};

type Props = {
  label: string;
  description: any;
  labelArgs?: any;
  isReady?: boolean; // required to not render if loading indicator is shown
  descriptionArgs?: any;
  className?: string;
  iconName?: 'empty' | 'developerBoard';
  isIconWrapped?: boolean;
  children: any;
  classes: {
    root?: string;
    textWrapper?: string;
    label?: string;
    description?: string;
  };
};

function PanelWithIcon(props: Props) {
  const {
    className,
    iconName,
    isReady,
    isIconWrapped,
    children,
    classes: { root, label, description, textWrapper },
    ...rest
  } = props;

  const Icon = (iconName && icons[iconName]) || icons.empty;
  const wrapperClassName = classnames(styles.wrapper, className, root);
  const predefinedIcon = <Icon size={64} className={styles.icon} />;

  return isReady ? (
    <div className={wrapperClassName} data-test="panel-with-icon">
      {children || predefinedIcon}
      <LabelWithDescription
        {...rest}
        classes={{
          label: label || styles.label,
          description: description || styles.description,
          root: textWrapper,
        }}
      />
    </div>
  ) : null;
}

PanelWithIcon.defaultProps = {
  className: '',
  iconName: 'empty',
  labelArgs: {},
  isReady: true,
  children: null,
  descriptionArgs: {},
  isIconWrapped: true,
  classes: {
    label: styles.label,
    description: styles.description,
  },
};

export default PanelWithIcon;
