/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: columns.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';

import { Column } from 'types';

import ServiceGroupInfo from '../../components/ServiceGroupInfo';
import styles from './ServiceGroupsList.module.scss';
import { DateCellWithTime } from 'components';

const columns: Column[] = [
  {
    Header: 'SERVICES.COLUMNS.GROUP_NAME',
    id: 'name',
    width: undefined,
    Expander: ({ original: service }) => (
      <ServiceGroupInfo internal {...service} key={service.id} className={styles.item} />
    ),
    expander: true,
    withTruncate: false,
  },
  {
    Header: 'SERVICES.COLUMNS.UPDATED_ON',
    id: 'status',
    width: undefined,
    Expander: ({ original: updatedAt }) => DateCellWithTime({ value: updatedAt }),
    expander: true,
  },
  {
    expander: true,
    Header: '',
    width: 40,
    style: {
      minHeight: 40,
    },
    className: 'rt-button-cell',
  },
];

export default columns;
