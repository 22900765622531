/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Checklist.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';

import LabelWithDescription from '../LabelWithDescription';
import RuleItem, { Props as RuleProps } from './RuleItem';

import styles from './Checklist.module.scss';

type Props = {
  rulesSections: {
    label: string;
    labelParams: Object;
    rules: RuleProps[];
  }[];
};

export const Checklist = ({ rulesSections }: Props) => (
  <div className={styles.wrapper}>
    {rulesSections.map(({ label, labelParams, rules }) => (
      <div key={label}>
        <LabelWithDescription
          label={label}
          labelArgs={labelParams}
          classes={{ label: styles.label }}
        />

        {rules.map(rule => (
          <RuleItem key={rule.name} {...rule} />
        ))}
      </div>
    ))}
  </div>
);
