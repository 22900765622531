/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import ContentWithTooltip from '../../../../ContentWithTooltip';
import styles from './PanelItem.module.scss';

type Props = {
  label: string;
  value: string | number;
  className?: string;
  component?: any;
  parentValue?: any;
  entity?: any;
};

function PanelItem(props: Props) {
  const { label, value, className, component: Component, entity, parentValue } = props;
  const canCreateComponent = parentValue && !isEmpty(parentValue) && entity && !isEmpty(entity);

  return (
    <div className={classnames(styles.item, className)}>
      <div className={styles.key}>
        <ContentWithTooltip>
          <Trans i18nKey={label} />
        </ContentWithTooltip>
      </div>
      <div className={styles.value}>
        <ContentWithTooltip>
          {Component && canCreateComponent ? (
            <Component value={value} parentValue={parentValue} entity={entity} />
          ) : (
            <Trans defaults={String(value || '-')} i18nKey={String(value)} />
          )}
        </ContentWithTooltip>
      </div>
    </div>
  );
}

PanelItem.defaultProps = {
  className: '',
  component: null,
  parentValue: undefined,
  entity: undefined,
};

export default PanelItem;
