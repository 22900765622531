import { createSelector } from 'reselect';

import { ServiceGroup } from 'types';
import {
  URLS,
  makeApiAction,
  selectorMakers,
  getSuccessType,
  getFailedType,
  getFetchType,
  getStopFetchingType,
  getStartFetchingType,
} from 'utils';

/*
 * TYPES
 * */
const serviceGroupsModuleName = 'serviceGroups';
const descriptor = 'SERVICE_GROUPS';

const FETCH_SERVICE_GROUPS = getFetchType(descriptor);
const FETCH_SERVICE_GROUPS_SUCCESS = getSuccessType(FETCH_SERVICE_GROUPS);
const FETCH_SERVICE_GROUPS_FAILED = getFailedType(FETCH_SERVICE_GROUPS);
const STOP_SERVICE_GROUPS_FETCHING = getStopFetchingType(descriptor);
const START_SERVICE_GROUPS_FETCHING = getStartFetchingType(descriptor);

/*
 * ACTIONS
 * */
const fetchServiceGroups = makeApiAction({
  baseUrl: URLS.servicesInfo,
  processingDescriptor: descriptor,
  initialActionType: FETCH_SERVICE_GROUPS,
});

const fetchServiceGroupsPrivate = makeApiAction({
  baseUrl: URLS.servicesPrivateInfo,
  processingDescriptor: descriptor,
  initialActionType: FETCH_SERVICE_GROUPS,
});

/*
 * REDUCER
 * */

export interface ServiceGroups {
  services: ServiceGroup[];
  isLoading: boolean;
  error: any;
}

const initialValues = { services: [], isLoading: false, error: null };

function serviceGroupsReducer(state: ServiceGroups = initialValues, { type, payload }: any) {
  switch (type) {
    case START_SERVICE_GROUPS_FETCHING:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_SERVICE_GROUPS_FETCHING:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_SERVICE_GROUPS_SUCCESS:
      return {
        ...state,
        error: null,
        services: payload,
      };

    case FETCH_SERVICE_GROUPS_FAILED:
      return {
        ...state,
        services: [],
        error: payload,
      };

    default:
      return state;
  }
}

/*
 * SELECTORS
 * */

const getState = selectorMakers.makeGetState<ServiceGroups>(serviceGroupsModuleName);
const getServiceGroups = createSelector(
  getState,
  state => state,
);

export {
  serviceGroupsModuleName,
  serviceGroupsReducer,
  getServiceGroups,
  fetchServiceGroups,
  fetchServiceGroupsPrivate,
};
