/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import classNames from 'classnames';
import { last, get } from 'lodash';

import { BreadcrumbType } from 'types';
import Breadcrumbs from '../Breadcrumbs';
import LabelWithDescription from '../LabelWithDescription';

import styles from './PageHeader.module.scss';

type Props = {
  label?: string;
  description?: string;
  showBreadcrumbs?: boolean;
  isInSummary?: boolean;
  labelClassName?: string;
  descrClassName?: string;
  contentClassName?: string;
  labelArgs?: Object;
  descriptionArgs?: Object;
  withTitle?: boolean;
  breadcrumbs?: BreadcrumbType[];
  useTitleAsBreadcrumb?: boolean;
};

const PageHeader = ({
  label,
  description,
  showBreadcrumbs = true,
  isInSummary = false,
  descrClassName,
  contentClassName,
  labelArgs = {},
  descriptionArgs = {},
  withTitle = true,
  breadcrumbs,
  useTitleAsBreadcrumb = false,
  labelClassName,
}: Props) => {
  const contentClass = classNames(styles.content, contentClassName, {
    [styles.summary]: isInSummary,
  });
  const descrClass = classNames(styles.description, descrClassName, {
    [styles.summaryDescription]: isInSummary,
  });
  const labelClass = classNames(
    styles.label,
    { [styles.summaryLabel]: isInSummary },
    labelClassName,
  );
  const pageTitleText = label || get(last(breadcrumbs), 'title');

  return (
    <div className={classNames(styles.header, { [styles.isInSummary]: isInSummary })}>
      <div className={contentClass}>
        {showBreadcrumbs && (
          <Breadcrumbs
            useTitleAsBreadcrumb={useTitleAsBreadcrumb}
            pageTitle={pageTitleText}
            breadcrumbs={breadcrumbs}
            className={styles.breadcrumbs}
            color={isInSummary ? 'secondary' : 'primary'}
          />
        )}

        {withTitle && pageTitleText && (
          <LabelWithDescription
            label={pageTitleText}
            labelArgs={labelArgs}
            description={description}
            descriptionArgs={descriptionArgs}
            classes={{
              label: labelClass,
              description: descrClass,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PageHeader;
