/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React, { ReactElement } from 'react';
import Moment from 'react-moment';

import { CellProps } from 'types';

export const DateCell = ({ value }: Partial<CellProps>): ReactElement<any> | string =>
  value ? <Moment format="ll">{value}</Moment> : '-';

export const DateCellWithTime = ({ value }: Partial<CellProps>): ReactElement<any> | string =>
  value ? <Moment format="lll">{value}</Moment> : '-';
