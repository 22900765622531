/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: translations.ts
 *
 * @author: Panasonic, developer
 */

import i18next, { TFunction } from 'i18next';

export const translateIfExists = (
  t: TFunction,
  text: string,
  textParams: Record<string, any> = {},
) => (i18next.exists(String(text)) ? t(text, textParams) : text);
