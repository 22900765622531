/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Id } from 'types';
import {
  getIncidentMetaInfo,
  IncidentMetaInfo,
  incidentAvailableServicesActions,
  incidentActions,
} from 'state';
import { LoadingWrapper, PageHeader } from 'components';
import IncidentSummary from './components/Summary';
import IncidentServices from './components/Services';
import FooterActions from './components/Actions';
import General from './components/General';

const BREADCRUMBS = [
  {
    title: 'INCIDENT.BREADCRUMBS.ROOT',
    path: '/incidents',
  },
  {
    title: 'INCIDENT.BREADCRUMBS.CREATE',
  },
];

type Props = {
  actions: {
    fetchIncident: ({ id: Id }) => Promise<any>;
    resetNewService: () => any;
    incidentReset: () => any;
  };
  incidentId: Id;
  incidentInfo: IncidentMetaInfo;
};

const IncidentEdit = ({
  actions: { incidentReset, resetNewService, fetchIncident },
  incidentId,
  incidentInfo,
}: Props) => {
  useEffect(() => {
    if (incidentId !== 'new') {
      fetchIncident({ id: incidentId });
    }

    return () => {
      resetNewService();
      incidentReset();
    };
  }, [fetchIncident, incidentReset, incidentId, resetNewService]);

  return (
    <div className="full-width">
      <LoadingWrapper isLoading={incidentInfo.isLoading}>
        <PageHeader showBreadcrumbs description="INCIDENT.HINT" breadcrumbs={BREADCRUMBS} />

        <section className="route-container with-padding">
          <Grid container spacing={24}>
            <Grid item xs={8}>
              <General />
              <IncidentServices />
            </Grid>

            <Grid item xs={4}>
              <IncidentSummary />
            </Grid>
          </Grid>
        </section>

        <FooterActions />
      </LoadingWrapper>
    </div>
  );
};

const mapStateToProps = (state, { match }) => ({
  incidentId: get(match, 'params.id'),
  incidentInfo: getIncidentMetaInfo(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...incidentActions, ...incidentAvailableServicesActions },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(IncidentEdit);
