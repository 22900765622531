/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { ComponentType, useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { getFormValues, isValid, isPristine } from 'redux-form';
import { connect } from 'react-redux';
import { isEmpty, omit, get, unionBy } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, compose, Dispatch, AnyAction } from 'redux';
import { compile } from 'path-to-regexp';

import { Incident, Service, IncidentStatus } from 'types';
import {
  getIncidentMetaInfo,
  getNewServices,
  getIncident,
  IncidentMetaInfo,
  incidentActions,
} from 'state';
import { INCIDENT_STATUS_TYPES, INCIDENT_GENERAL_FORM, ROUTE_URLS } from 'utils';
import { FooterActions, Button, ButtonGroup, RouteChangeConfirm } from 'components';
import { useEnsureOperationalServices } from '../../hooks';

import styles from './Actions.module.scss';

const incidentPath = compile(ROUTE_URLS.incident);

type StateToProps = {
  values: {
    summary: string;
    status: IncidentStatus;
    jiraTicket?: string;
  };
  newServices: Service[];
  isFormValid: boolean;
  isFormPristine: boolean;
  incidentMetaInfo: IncidentMetaInfo;
  incident: Incident;
};

type DispatchToProps = {
  actions: {
    createIncident: (params: object) => Promise<any>;
    deleteIncidentAction: ({ id: Id }) => any;
    archiveIncident: ({ id: Id }) => any;
    updateIncident: ({ id: Id }) => any;
  };
};

const Actions = ({
  values,
  isFormPristine,
  isFormValid,
  newServices,
  incident,
  actions: { createIncident, updateIncident, deleteIncidentAction, archiveIncident },
  history: { push },
  incident: { id, services, draft, status },
  incidentMetaInfo: { isSaving = false, isDeleting = false },
  match: {
    params: { id: incidentId },
  },
}: StateToProps & DispatchToProps & RouteComponentProps<any>) => {
  // TODO: rework to use form submission and remove `shouldConfirmRouteChange`
  const [shouldConfirmRouteChange, setShouldConfirmRouteChange] = useState(true);
  const ensureOperationalServices = useEnsureOperationalServices();

  const getFormValues = useCallback(() => {
    const oldServices = get(incident, 'services', []);
    const combinedServices = unionBy<Service>(newServices, oldServices, 'name');

    return {
      ...values,
      services: combinedServices.map(service => omit(service, ['id'])),
    };
  }, [values, newServices, incident]);

  const saveAsDraft = useCallback(() => {
    const formValues = getFormValues();

    const performSaveDraft = (services?: Service[]) => {
      const values = services ? { ...formValues, services } : formValues;

      createIncident({ ...values, draft: true }).then(({ id }) => push(incidentPath({ id })));
    };

    setShouldConfirmRouteChange(false);
    ensureOperationalServices(formValues, performSaveDraft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, newServices, incident]);

  const publish = useCallback(() => {
    const formValues = getFormValues();

    const performPublish = (services?: Service[]) => {
      const values = services ? { ...formValues, services } : formValues;

      createIncident(values).then(({ id }) => push(incidentPath({ id })));
    };

    setShouldConfirmRouteChange(false);
    ensureOperationalServices(formValues, performPublish);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, newServices, incident]);

  const update = useCallback(() => {
    const formValues = getFormValues();

    const performUpdate = (services?: Service[]) => {
      const values = services ? { ...formValues, services } : formValues;

      updateIncident({ id, ...values }).then(() => push(incidentPath({ id })));
    };

    setShouldConfirmRouteChange(false);
    ensureOperationalServices(formValues, performUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, newServices, incident]);

  const deleteIncident = useCallback(() => {
    deleteIncidentAction({ id }).then(() => push(ROUTE_URLS.incidents));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const archive = useCallback(async () => {
    const formValues = getFormValues();

    const performArchive = () => archiveIncident({ id }).then(() => push(incidentPath({ id })));

    const performArchiveWithUpdate = (services?: Service[]) => {
      const values = services ? { ...formValues, services } : formValues;

      updateIncident({ id, ...values }).then(performArchive);
    };

    setShouldConfirmRouteChange(false);
    ensureOperationalServices(formValues, performArchiveWithUpdate, performArchive, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, values, newServices, incident]);

  const areServicesAdded = !isEmpty(newServices) || !isEmpty(services);
  const isNewIncident = !status;
  const isArchived = status === INCIDENT_STATUS_TYPES.ARCHIVED;
  const isDisabled = isSaving || isDeleting;

  return (
    <FooterActions>
      <div className={styles.container}>
        <div className={styles.buttonWrapper}>
          <ButtonGroup isHidden={draft} placement="top">
            <Button
              variant="outlined"
              className={styles.button}
              onClick={() => push(ROUTE_URLS.incidents)}
              isLoading={isSaving}
              disabled={isDisabled}
            >
              <Trans i18nKey="COMMON.CANCEL" />
            </Button>

            {draft && !isNewIncident && (
              <div className={styles.buttonWrapper}>
                <Button
                  variant="outlined"
                  className={styles.button}
                  onClick={deleteIncident}
                  isLoading={isDeleting}
                  disabled={isDisabled}
                >
                  <Trans i18nKey="COMMON.DELETE" />
                </Button>
              </div>
            )}
          </ButtonGroup>
        </div>

        {!isArchived && (
          <div className={styles.buttonWrapper}>
            {(draft || isNewIncident) && (
              <Button
                variant="outlined"
                className={styles.button}
                disabled={!isFormValid || isDisabled}
                onClick={saveAsDraft}
                isLoading={isSaving}
              >
                <Trans i18nKey="COMMON.SAVE_DRAFT" />
              </Button>
            )}

            {!draft && !isNewIncident && (
              <Button
                variant="outlined"
                className={styles.button}
                disabled={!isFormValid || isDisabled}
                onClick={archive}
                isLoading={isSaving}
              >
                <Trans i18nKey="COMMON.ARCHIVE" />
              </Button>
            )}

            <Button
              variant="contained"
              className={styles.button}
              disabled={!isFormValid || !areServicesAdded || isDisabled}
              onClick={() => {
                if (incidentId === 'new') {
                  publish();
                } else {
                  update();
                }
              }}
              isLoading={isSaving}
            >
              <Trans i18nKey="COMMON.PUBLISH" />
            </Button>
          </div>
        )}
      </div>

      <RouteChangeConfirm
        when={!isFormPristine && shouldConfirmRouteChange}
        title="COMMON.LEAVE_CONFIRM_TITLE"
        description="COMMON.LEAVE_CONFIRM_DESCRIPTION"
      />
    </FooterActions>
  );
};

const mapStateToProps = state => ({
  values: getFormValues(INCIDENT_GENERAL_FORM)(state),
  isFormValid: isValid(INCIDENT_GENERAL_FORM)(state),
  isFormPristine: isPristine(INCIDENT_GENERAL_FORM)(state),
  newServices: getNewServices(state),
  incidentMetaInfo: getIncidentMetaInfo(state),
  incident: getIncident(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators<(dispatch: Dispatch) => void, any>(incidentActions, dispatch),
});

export default compose<any>(
  withRouter,
  connect<StateToProps, DispatchToProps, RouteComponentProps>(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Actions) as ComponentType;
