/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { property } from 'lodash';

const TableWithScrollbar = styled.div`
  &::-webkit-scrollbar-track-piece {
    margin-right: ${property('offsetRight')}px;
    margin-left: ${property('offsetLeft')}px;
  }
`;

type Props = {
  className: string;
  children: any;
  stickyColumnsOffsetLeft: number;
  stickyColumnsOffsetRight: number;
};

function TableComponent(props: Props) {
  const { className, children, stickyColumnsOffsetLeft, stickyColumnsOffsetRight } = props;
  const tableClassNames = classnames(className, 'rt-table');

  return (
    // @ts-ignore
    <TableWithScrollbar
      className={tableClassNames}
      offsetLeft={stickyColumnsOffsetLeft}
      offsetRight={stickyColumnsOffsetRight}
    >
      {children}
    </TableWithScrollbar>
  );
}

TableComponent.defaultProps = {
  className: '',
  children: '',
};

export default TableComponent;
