/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: columns.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import { Trans } from 'react-i18next';
import { get, property } from 'lodash';

import { Column } from 'types';
import { formatName, INCIDENT_STATUS_TYPES } from 'utils';
import { DateCellWithTime, Text, ToolsWithActions } from 'components';

import styles from './Incidents.module.scss';

const ToolsCell = ({
  original,
  tdProps: {
    rest: { viewDetails, archiveIncident, deleteIncident, editIncident },
  },
}) => {
  const status = get(original, 'status');
  const isDraft = status === INCIDENT_STATUS_TYPES.DRAFT;
  const actions = [
    {
      text: 'COMMON.VIEW',
      onClick: () => viewDetails(original),
    },
  ];

  if (status !== INCIDENT_STATUS_TYPES.ARCHIVED) {
    actions.push({
      text: 'COMMON.EDIT',
      onClick: () => editIncident(original),
    });
  }

  if (!isDraft && status !== INCIDENT_STATUS_TYPES.ARCHIVED) {
    actions.push({
      text: 'COMMON.ARCHIVE',
      onClick: () => archiveIncident(original),
    });
  }

  if (isDraft) {
    actions.push({
      text: 'COMMON.DELETE',
      onClick: () => deleteIncident(original),
    });
  }

  return <ToolsWithActions actions={actions} />;
};

const columns: Column[] = [
  {
    Header: 'INCIDENTS.COLUMNS.CREATED_ON',
    accessor: 'createdAt',
    width: 200,
    expander: true,
    Expander: ({ original: { createdAt } }) => DateCellWithTime({ value: createdAt }),
  },
  {
    Header: 'INCIDENTS.COLUMNS.ID',
    width: 120,
    Cell: ({
      original,
      tdProps: {
        rest: { viewDetails },
      },
    }) => {
      return (
        <span className={styles.link} onClick={() => viewDetails(original)}>
          {get(original, 'id', '-')}
        </span>
      );
    },
  },
  {
    Header: 'INCIDENTS.COLUMNS.SUMMARY',
    accessor: 'summary',
    width: 400,
    expander: true,
    Expander: property('original.summary'),
  },
  {
    Header: 'INCIDENTS.COLUMNS.STATUS',
    id: 'status',
    Expander: ({ original: { status } }) => <Trans>{`INCIDENTS.STATUSES.${status}`}</Trans>,
    expander: true,
    width: undefined,
  },
  {
    Header: 'INCIDENTS.COLUMNS.CREATED_BY',
    id: 'createdBy',
    Expander: ({ original: { createdBy } }) => <Text>{formatName(createdBy)} </Text>,
    expander: true,
    width: undefined,
  },
  {
    Header: '',
    Cell: ToolsCell,
    width: 40,
    sortable: false,
    resizable: false,
    className: 'rt-button-cell',
  },
  {
    expander: true,
    Header: '',
    width: 40,
    style: {
      minHeight: 40,
    },
    className: 'rt-button-cell',
  },
];

export default columns;
