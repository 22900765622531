/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component } from 'react';
import { get, isNil } from 'lodash';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import PanelItem from '../PanelItem';
import styles from './Panel.module.scss';

type Props = {
  title: string;
  fields: { [x: string]: any };
  i18nMapper: {
    // eslint-disable-next-line no-undef
    labels: object;
    // eslint-disable-next-line no-undef
    values?: object;
  };
  i18nNamespace: string;
  classes: {
    panelItem?: string;
    panel?: string;
  };
  // eslint-disable-next-line no-undef
  entity?: object;
};

class Panel extends Component<Props> {
  static defaultProps = {
    classes: {
      panelItem: '',
      panel: '',
    },
  };

  static getValue(value: string | number | null) {
    return !isNil(value) ? String(value) : '-';
  }

  constructor(props: Props) {
    super(props);

    this.getSubPanel = this.getSubPanel.bind(this);
    this.getPanel = this.getPanel.bind(this);
  }

  getSubPanel(key: string, value: any) {
    const { title } = this.props;

    return (
      <div className={styles.subPanel} key={key}>
        {Object.entries(value).map(([subKey, subValue]: [string, any]) => {
          const label = `${title}.${subKey}`;

          return ['string', 'number'].includes(typeof subValue)
            ? this.getLocalizedPanelItem(label, String(subValue), value)
            : null;
        })}
      </div>
    );
  }

  getPanel([key, value]: [string, any]) {
    const { fields } = this.props;

    return value && typeof value === 'object'
      ? this.getSubPanel(key, value)
      : this.getLocalizedPanelItem(key, value, fields);
  }

  getLocalizedPanelItem(key: string, value: string, parentValue: any) {
    const {
      i18nMapper: { values, labels },
      classes,
      entity,
    } = this.props;
    const customizedValue = get(values, key);
    const label = get(labels, key);
    const ValueComponent = typeof customizedValue === 'function' ? customizedValue : null;
    const i18nKey =
      typeof customizedValue === 'string' && value
        ? `${customizedValue}.${value}`
        : Panel.getValue(value);

    return (
      label && (
        <PanelItem
          className={classes.panelItem}
          key={key}
          label={label}
          value={ValueComponent ? value : i18nKey}
          parentValue={parentValue}
          entity={entity}
          component={ValueComponent}
        />
      )
    );
  }

  render() {
    const { title, fields, i18nNamespace, classes } = this.props;
    const i18nKey = `${i18nNamespace}.${title.toUpperCase()}`;

    return (
      <div className={classnames(styles.wrapper, classes.panel)}>
        <div className={styles.title}>
          <Trans i18nKey={i18nKey} />
        </div>
        <div>{fields && Object.entries(fields).map(this.getPanel)}</div>
      </div>
    );
  }
}

export default Panel;
