/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { submit, hasSubmitFailed, getFormError, isDirty, isValid, isSubmitting } from 'redux-form';
import { get } from 'lodash';
import classNames from 'classnames';

import { Id } from 'types';
import Errors from '../../Forms/Errors';
import DialogBoilerplate, { CommonDialogPropTypes } from '../DialogBoilerplate';

import styles from './FormDialog.module.scss';

const dialogConfig = {
  fullWidth: true,
  maxWidth: false,
  contentClassName: styles.dialogContent,
  PaperProps: {
    classes: {
      root: styles.paperRoot,
    },
  },
};

const getFormName = ({ formName, defaultProps, WrappedComponent }: any) => {
  const name = formName;
  const reduxFormName = get(defaultProps, 'form');
  const connectedReduxFormName = get(WrappedComponent, 'defaultProps.form');

  return name || reduxFormName || connectedReduxFormName;
};

type Props = CommonDialogPropTypes & {
  modalId: Id;
  formComponent: ComponentType<any> & { formName: string };
  closeModal: (id: Id, isConfirmClick: boolean) => void;
  formProps: any;
  dialogProps: any;
  submitFailed: boolean;
  error: void | string;
  dirty: boolean;
  valid: boolean;
  submitting: boolean;
  submit: (form: string) => void;
  confirmLabel?: string;
  cancelLabel?: string;
  viewMode: boolean;
  classes: {
    formWrapper?: string;
  };
};

type State = {
  viewMode: boolean;
};

class FormDialog extends React.Component<Props, State> {
  state = {
    viewMode: this.props.viewMode,
  };

  static defaultProps = {
    confirmLabel: '',
    cancelLabel: '',
    classes: {},
    viewMode: false,
  };

  get buttonLabels() {
    const { confirmLabel, cancelLabel } = this.props;
    const { viewMode } = this.state;

    return {
      confirmLabel: confirmLabel || (viewMode ? 'COMMON.EDIT' : 'COMMON.SAVE'),
      cancelLabel: cancelLabel || 'COMMON.CANCEL',
    };
  }

  closeModal = () => {
    const { modalId, closeModal } = this.props;

    closeModal(modalId, true);
  };

  handleConfirmClick = () => {
    const { formComponent } = this.props;
    const { viewMode } = this.state;

    if (!viewMode) {
      this.props.submit(getFormName(formComponent));
    } else {
      this.setState(() => ({
        viewMode: false,
      }));
    }
  };

  render() {
    const {
      formProps,
      dialogProps,
      submitFailed,
      error,
      formComponent: FormComponent,
      classes: { formWrapper },
      dirty,
      valid,
      submitting,
    } = this.props;
    const { viewMode } = this.state;

    return (
      <DialogBoilerplate
        {...this.props}
        dialogProps={dialogConfig}
        {...dialogProps}
        confirmLabel={this.buttonLabels.confirmLabel}
        cancelLabel={this.buttonLabels.cancelLabel}
        onConfirm={this.handleConfirmClick}
        disableConfirm={!viewMode && !(dirty && valid)}
        isSubmitting={submitting}
      >
        <div className={classNames(styles.formWrapper, formWrapper)}>
          {submitFailed && error && <Errors errors={error} global className={styles.error} />}

          <div className={formWrapper}>
            <FormComponent {...formProps} viewMode={viewMode} onSubmitSuccess={this.closeModal} />
          </div>
        </div>
      </DialogBoilerplate>
    );
  }
}

const makeMapStateToProps = (_: undefined, { formComponent }: Props) => {
  const formName = getFormName(formComponent);
  const getErrors = getFormError(formName);
  const getSubmitFailed = hasSubmitFailed(formName);
  const getIsDirty = isDirty(formName);
  const getIsValid = isValid(formName);
  const getIsSubmitting = isSubmitting(formName);

  return (state: any) => ({
    error: getErrors(state),
    submitFailed: getSubmitFailed(state),
    dirty: getIsDirty(state),
    valid: getIsValid(state),
    submitting: getIsSubmitting(state),
  });
};

export default connect(
  makeMapStateToProps,
  { submit },
  // @ts-ignore
)(FormDialog);
