import { ROUTE_URLS } from 'utils';
import { withAuthorization } from 'HOCs';
import Login from './Login';
import ResetPassword from './ResetPassword';
import HealthStatus from './HealthStatus';
import IncidentsHistory from './IncidentsHistory';

export const defaultPublicRouteUrl = ROUTE_URLS.healthStatus;

export default [
  {
    // @ts-ignore
    component: withAuthorization(HealthStatus, { canViewRoute: true }),
    path: ROUTE_URLS.healthStatus,
    exact: true,
  },
  {
    // @ts-ignore
    component: withAuthorization(IncidentsHistory, { canViewRoute: true }),
    path: ROUTE_URLS.incidentsHistory,
    exact: true,
  },
  {
    // @ts-ignore
    component: withAuthorization(Login, { canViewRoute: true }),
    path: ROUTE_URLS.login,
    exact: true,
  },
  {
    // @ts-ignore
    component: withAuthorization(ResetPassword, { canViewRoute: true }),
    path: ROUTE_URLS.resetPassword,
    exact: true,
  },
];
