/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import styles from './LabelWithDescription.module.scss';

type Props = {
  className?: string;
  labelClassName?: string;
  descrClassName?: string;
  labelArgs?: Object;
  descriptionArgs?: Object;
  label?: any;
  description?: any;
  classes: {
    root?: string;
    label?: string;
    description?: string;
  };
  onClick?: () => void;
};

class LabelWithDescription extends Component<Props> {
  static defaultProps = {
    className: '',
    labelClassName: '',
    descrClassName: '',
    label: '',
    description: '',
    labelArgs: undefined,
    descriptionArgs: undefined,
    classes: {},
    onClick: undefined,
  };

  get label() {
    const { label, labelArgs, labelClassName, classes } = this.props;
    const labelClass = classnames(styles.label, labelClassName, classes.label);

    return (
      label && (
        <div className={labelClass}>
          {typeof label === 'string' && i18next.exists(label) ? (
            <Trans count={get(labelArgs, 'count')} values={labelArgs} i18nKey={label}>
              {label}
            </Trans>
          ) : (
            label
          )}
        </div>
      )
    );
  }

  get description() {
    const { description, descriptionArgs, descrClassName, classes } = this.props;
    const descriptionClass = classnames(styles.description, descrClassName, classes.description);

    return (
      description && (
        <div className={descriptionClass}>
          {typeof description === 'string' && i18next.exists(description) ? (
            <Trans count={get(descriptionArgs, 'count')} values={descriptionArgs}>
              {description}
            </Trans>
          ) : (
            description
          )}
        </div>
      )
    );
  }

  render() {
    const { className, classes, onClick } = this.props;
    const wrapperClass = classnames(styles.wrapper, className, classes.root);

    return (
      <div className={wrapperClass} onClick={onClick} role="presentation">
        {this.label}
        {this.description}
      </div>
    );
  }
}

export default LabelWithDescription;
