/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { createSelector } from 'reselect';
import { LOCATION_CHANGE } from 'connected-react-router';
import { v4 } from 'uuid';
import { takeEvery, take, call } from 'redux-saga/effects';

import { wrapActionCreatorInPromise } from 'utils';

/*
 * TYPES
 * */
export const modalModuleName = 'modal';
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

/*
 * ACTIONS
 * */
const openModal = wrapActionCreatorInPromise(config => ({
  type: OPEN_MODAL,
  payload: {
    ...config,
    id: v4(),
  },
}));

const closeModal = (id, isConfirmClicked, result) => ({
  type: CLOSE_MODAL,
  payload: { id, result, isConfirmClicked },
});

export const modalActions = {
  openModal,
  closeModal,
};

/*
 * REDUCER
 * */
type ModalState = {
  activeModals: any[];
  activeModalsCount: number;
};

const initialState = {
  activeModals: [],
  activeModalsCount: 0,
};

export default function modalReducer(state: ModalState = initialState, { type, payload }) {
  switch (type) {
    case OPEN_MODAL: {
      const activeModals = [...state.activeModals, payload];

      return {
        activeModals,
        activeModalsCount: activeModals.length,
      };
    }

    case CLOSE_MODAL: {
      const activeModals = state.activeModals.filter(({ id }) => id !== payload.id);

      return {
        activeModals,
        activeModalsCount: activeModals.length,
      };
    }

    case LOCATION_CHANGE:
      return {
        activeModals: [],
        activeModalsCount: 0,
      };

    default:
      return state;
  }
}

/*
 * SELECTORS
 * */
const getModalState = (state): ModalState => state.ui[modalModuleName];

export const getActiveModals = createSelector(
  getModalState,
  modals => modals.activeModals,
);

/*
 * SAGAS
 * */
function* openModalWorker({ payload: { id }, resolve, reject }) {
  const {
    payload: { isConfirmClicked, result },
  } = yield take(({ type, payload }) => type === CLOSE_MODAL && payload.id === id);

  if (isConfirmClicked) {
    yield call(resolve, result);
  } else {
    yield call(reject, result);
  }
}

export function* modalSaga() {
  // @ts-ignore
  yield takeEvery(OPEN_MODAL, openModalWorker);
}
