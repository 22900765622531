/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { ROUTE_URLS } from 'utils';
import { withAuthorization } from 'HOCs';
import Incidents from './Incidents';
import Incident from './Incident';
import IncidentEdit from './IncidentEdit';

export default [
  {
    // @ts-ignore
    component: withAuthorization(Incidents),
    path: ROUTE_URLS.incidents,
    exact: true,
  },
  {
    // @ts-ignore
    component: withAuthorization(Incident),
    path: ROUTE_URLS.incident,
    exact: true,
  },
  {
    // @ts-ignore
    component: withAuthorization(IncidentEdit),
    path: ROUTE_URLS.incidentEdit,
    exact: true,
  },
];
