/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { ReactNode, ReactElement } from 'react';
import classNames from 'classnames';

import Card from '../../Card';
import FormPanelHeader, { FormPanelHeaderProps } from './FormPanelHeader';

import styles from './FormPanel.module.scss';

type Props = {
  label: string;
  description?: string;
  children: ReactNode;
  isRequired?: boolean;
  tools?: () => ReactElement<any> | null;
  withMargins?: boolean;
  classes?: {
    root?: string;
    header?: string;
    content?: string;
    label?: string;
    description?: string;
  };
} & FormPanelHeaderProps;

function FormPanel(props: Props) {
  const { children, classes = {}, isRequired = false, withMargins = false, ...rest } = props;
  const { root, header, content, label, description } = classes;

  const rootClasses = classNames(root, { [styles.withMargins]: withMargins });
  const contentClass = classNames(styles.content, content);
  const labelClass = classNames(styles.label, isRequired && styles.required, label);
  const descriptionClass = classNames(styles.description, description);

  return (
    <Card className={rootClasses}>
      <FormPanelHeader
        {...rest}
        classes={{
          root: header || '',
          label: labelClass,
          description: descriptionClass,
        }}
      />
      <div className={contentClass}>{children}</div>
    </Card>
  );
}

export default FormPanel;
