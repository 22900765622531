import React from 'react';
import { isPlainObject } from 'lodash';
import { Route, Switch, Redirect } from 'react-router';

import publicRoutes, { defaultPublicRouteUrl } from './PublicRoutes/routes';
import privateRoutes from './PrivateRoutes';

const mapRoutes = (routes: any) =>
  Object.values(routes)
    .filter(isPlainObject)
    .map((config: any) => <Route {...config} key={config.path} />);

const mappedRoutes = mapRoutes([...publicRoutes, ...privateRoutes]);

function Routes() {
  return (
    <Switch>
      {mappedRoutes}

      <Redirect to={defaultPublicRouteUrl} from="*" />
    </Switch>
  );
}

export default Routes;
