import React from 'react';

import styles from './Main.module.scss';

interface Props {
  children: any;
}

function Main({ children }: Props) {
  return <div className={styles.wrapper}>{children}</div>;
}

export default Main;
