/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { Component, Fragment } from 'react';
import Grid, { GridItemsAlignment, GridDirection } from '@material-ui/core/Grid';
import classNames from 'classnames';
import { FieldProps } from 'redux-form';

import LabelWithDescription from '../../LabelWithDescription';
import Errors from '../Errors';

import styles from './FormField.module.scss';

export type FormFieldProps = FieldProps & {
  children: any;
  required?: boolean;
  fieldId?: string;
  label?: string;
  labelArgs: Object;
  description?: string;
  input: {
    name: string;
    value: string;
  };
  formError?: boolean;
  errorWithoutTouched?: boolean;
  shouldShowErrorMessage?: boolean;
  // deprecated
  className?: string;
  classes: {
    root?: string;
    labelRoot?: string;
    label?: string;
    description?: string;
    error?: string;
    errorRoot?: string;
  };
  gridContainerProps: {
    direction: GridDirection;
    alignItems: GridItemsAlignment;
  };
  viewMode: boolean;
  disabled?: boolean;
};

class FormField extends Component<FormFieldProps> {
  static defaultProps = {
    required: false,
    fieldId: '',
    className: '',
    label: '',
    description: '',
    meta: {},
    input: {},
    classes: {},
    labelArgs: {},
    viewMode: false,
    errorWithoutTouched: false,
    shouldShowErrorMessage: true,
    gridContainerProps: {
      direction: 'column',
      alignItems: 'flex-start',
    },
    formError: undefined,
  };

  get errors() {
    const {
      meta: { error, warning, touched, invalid, active },
      classes,
      formError,
      errorWithoutTouched,
    } = this.props;

    if (formError) return null;

    return (
      <Fragment>
        {(touched || errorWithoutTouched) && invalid && (
          <Errors className={classes.error} errors={error} contained />
        )}

        {active && warning && !error && (
          <Errors className={classes.error} errors={warning} contained />
        )}
      </Fragment>
    );
  }

  get requiredMarker() {
    const { required, viewMode } = this.props;

    return required && !viewMode && <span className={styles.asterisk}>*</span>;
  }

  get shouldShowError() {
    const {
      meta: { active, error, touched, warning, dirty },
      errorWithoutTouched,
    } = this.props;

    return (error && (touched || errorWithoutTouched)) || (warning && dirty && active);
  }

  get errorClasses() {
    const {
      classes: { errorRoot },
    } = this.props;

    return classNames(styles.container, this.shouldShowError && [styles.error, errorRoot]);
  }

  render() {
    const {
      className,
      fieldId,
      label,
      description,
      labelArgs,
      viewMode,
      classes: { root, label: labelClass },
      gridContainerProps,
      shouldShowErrorMessage,
      children,
    } = this.props;
    const wrapperClassName = classNames(styles.wrapper, className, root, {
      [styles.viewMode]: viewMode,
    });
    const labelClassName = classNames(styles.label, labelClass);

    return (
      <Grid container {...gridContainerProps} className={wrapperClassName}>
        {label && (
          <label htmlFor={fieldId} className={labelClassName}>
            <LabelWithDescription
              label={label}
              description={description}
              labelArgs={labelArgs}
              classes={{ label: styles.labelText, description: styles.descriptionText }}
            />

            {this.requiredMarker}
          </label>
        )}

        <div className={this.errorClasses} data-test={`form-field-${this.props.input.name}`}>
          {children}
        </div>

        {!viewMode && shouldShowErrorMessage && this.errors}
      </Grid>
    );
  }
}

export default FormField;
