/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useEffect } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import {
  AppState,
  fetchServiceDetails as fetchServiceDetailsCreator,
  getAuthorizationInfo,
  getServiceDetailsInfo,
  ServiceDetailsInfo,
} from 'state';
import { LoadingWrapper } from 'components';

import ServiceInfo from '../../components/ServiceInfo';

import styles from './ServicesList.module.scss';

interface Props {
  serviceGroup: number;
  serviceDetailsInfo: ServiceDetailsInfo;
  fetchServiceDetails: () => Promise<any>;
}

const ServicesList = ({
  serviceGroup,
  serviceDetailsInfo: { services, isLoading, error },
  fetchServiceDetails,
}) => {
  useEffect(() => {
    fetchServiceDetails({ id: serviceGroup });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceGroup]);

  return (
    <LoadingWrapper isLoading={isLoading[serviceGroup]}>
      {services[serviceGroup] ? (
        <Grid container className={styles.wrapper}>
          {services[serviceGroup].map(service => (
            <ServiceInfo {...service} key={service.id} className={styles.item} />
          ))}
        </Grid>
      ) : null}
    </LoadingWrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAuthorized: getAuthorizationInfo(state, 'isAuthorized'),
  serviceDetailsInfo: getServiceDetailsInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchServiceDetails: bindActionCreators<(dispatch: Dispatch) => void, any>(
    fetchServiceDetailsCreator,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServicesList);
