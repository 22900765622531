import moment from 'moment';

export default {
  navigation: {
    healthStatus: {
      navTitle: 'Health Status',
    },
  },
  footer: {
    copyright: `© ${moment().year()} Panasonic Corporation of North America. All Rights Reserved.`,
  },
  healthStatusPage: {
    banner: {
      titles: {
        success: 'OneConnect is up and running',
        failed: 'OneConnect is offline',
      },
      description:
        'Having trouble? To troubleshoot connection issues please email us at pasa.devops@us.panasonic.com',
    },
    tabs: {
      US: {
        title: 'North America',
        description: 'Status of end-user features and services in region',
      },
      EU: {
        title: 'Europe',
        description: 'Status of end-user features and services in region',
      },
    },
    list: {
      title: 'System Status by Service',
      description: 'Current status of OneConnect services',
      services: {
        FOTA: 'Firmware Over The Air',
        DATA_PROCESSING: 'Data Processing',
        MANAGEMENT_CONSOLE: 'Management Console',
        DEVICE_MANAGEMENT: 'Device Management',
        SERVICE_CONFIGURATION: 'Service Configuration',
        IOT_FILES_UPLOAD: 'IoT Files Upload',
        OEM_REPORTING: 'OEM Reporting',
        OC_FOTA: 'OneConnect Firmware Over the Air',
      },
    },
  },
  error: {
    title: 'Status Page is currently unavailable',
    description:
      'We track these errors automatically, but if the problem persists feel free to contact us at pasa.devops@us.panasonic.com. In the meantime, try refreshing.',
  },
};
