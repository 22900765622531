/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

type Key = string;

class LocalStorage {
  storage: {
    getItem?: (key: Key) => string;
    setItem?: (key: Key, value: string) => void;
    removeItem?: (key: Key) => void;
  };

  constructor() {
    // @ts-ignore
    this.storage = window && window.localStorage ? window.localStorage : {};
  }

  getItem(key: Key) {
    return this.storage.getItem ? this.storage.getItem(key) : this.storage[key];
  }

  setItem(key: Key, value: any) {
    if (this.storage.setItem) {
      this.storage.setItem(key, value);
    } else {
      this.storage[key] = value;
    }
  }

  removeItem(key: Key) {
    if (this.storage.removeItem) {
      this.storage.removeItem(key);
    } else {
      delete this.storage[key];
    }
  }
}

export default new LocalStorage();
