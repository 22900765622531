/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { get } from 'lodash';

import { REFRESHING_TOKEN } from '../../types';
import { fetchLAuthorizationCreator } from '../../authorization';

const createRefreshTokenMiddleware = () => {
  return ({ dispatch }) => next => action => {
    if (get(action, 'type') === REFRESHING_TOKEN) {
      // @ts-ignore
      dispatch(fetchLAuthorizationCreator());
    }

    return next(action);
  };
};

// @ts-ignore
const refreshToken = createRefreshTokenMiddleware();

export default refreshToken;
