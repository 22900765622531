import { get } from 'lodash';
import { SubmissionError } from 'redux-form';

import {
  URLS,
  makeApiAction,
  getSuccessType,
  getFetchType,
  getProfileDataAndProcessTokens,
  removeAuthTokensAndHeaders,
} from 'utils';

/*
 * TYPES
 * */
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

const FETCH_LOGIN = getFetchType(LOGIN);
export const FETCH_LOGOUT = getFetchType(LOGOUT);
export const FETCH_LOGIN_SUCCESS = getSuccessType(FETCH_LOGIN);
export const FETCH_LOGOUT_SUCCESS = getSuccessType(FETCH_LOGOUT);

/*
 * ACTIONS
 * */
export const loginCreator = makeApiAction({
  baseUrl: URLS.login,
  processingDescriptor: LOGIN,
  initialActionType: FETCH_LOGIN,
  method: 'post',
  shouldRefetchToken: false,
  transformResponse: response => {
    getProfileDataAndProcessTokens(response);
  },
  transformError: error => {
    const isUnauthorizedError = get(error, 'response.status') === 401;

    if (isUnauthorizedError) {
      throw new SubmissionError({ _error: 'ERRORS.ERR_07' });
    }
  },
});

export const logoutCreator = makeApiAction({
  baseUrl: URLS.logout,
  processingDescriptor: LOGOUT,
  initialActionType: FETCH_LOGOUT,
  method: 'post',
  transformResponse: () => {
    removeAuthTokensAndHeaders();
  },
});
