import React from 'react';
import classnames from 'classnames';

import styles from './Text.module.scss';

const variantsMapping = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  body1: 'span',
  body2: 'span',
  paragraph: 'p',
};

interface Props {
  children: any;
  variant: 'heading1' | 'heading2' | 'heading3' | 'heading4' | 'body1' | 'body2' | 'paragraph';
  className?: string;
  component?: any;
}

function Text({ children, className, variant, component }: Props) {
  const Cmp = component || variantsMapping[variant] || 'span';
  const classes = classnames(className, styles[variant]);

  return <Cmp className={classes}>{children}</Cmp>;
}

Text.defaultProps = {
  className: '',
  variant: 'body1',
  component: null,
};

export default Text;
