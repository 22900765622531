/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';

import { PaginatedTable } from 'components';
import columns from './columns';

type Props = {
  data: any[];
};

const IncidentsSubTable = (props: Props) => {
  const { data } = props;

  return (
    <PaginatedTable
      data={data}
      meta={{ totalItemsCount: data.length }}
      columns={columns}
      defaultPageSize={data.length}
      onTableStateChange={() => {}}
      isSubTable
    />
  );
};

export default IncidentsSubTable;
