import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, compose } from 'redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { fetchLAuthorizationCreator, getAuthorizationInfo } from 'state';
import { getAccessTokenExist } from 'utils';
import { LoadingWrapper } from '../LoadingWrapper';
import Header from '../Header';
import Main from '../Main';
import Footer from '../Footer';

import styles from './Layout.module.scss';

interface Props extends WithTranslation {
  children?: any;
  fetchLAuthorization: () => Promise<void>;
  isLoading: boolean;
}

class Layout extends Component<Props> {
  componentDidMount() {
    getAccessTokenExist() && this.props.fetchLAuthorization();
  }

  render() {
    const { children, isLoading, tReady } = this.props;

    return (
      <section className={styles.wrapper}>
        <LoadingWrapper isLoading={isLoading || !tReady}>
          <Header />

          <Main>{children}</Main>

          <Footer />
        </LoadingWrapper>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: getAuthorizationInfo(state, 'isLoading'),
});

const mapDispatchToProps = dispatch => ({
  fetchLAuthorization: bindActionCreators<(dispatch: Dispatch) => void, any>(
    fetchLAuthorizationCreator,
    dispatch,
  ),
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Layout) as ComponentType;
