/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 *
 */

import { ComponentType } from 'react';
import { omit, get, pick } from 'lodash';

import { translatePropsNames } from '../constants';
import { IStringMap, Option } from 'types';

export const removeTranslatorProps = (props: Object) => omit(props, translatePropsNames);

export const safelyParseJson = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

export const getToolsColumn = (Cell?: ComponentType<any>) => ({
  Cell,
  Header: '',
  id: 'tools',
  width: 40,
  sortable: false,
  resizable: false,
  className: 'rt-button-cell',
  isStickyLast: true,
});

export const getValueFromMixed = (src: any, path: string): any =>
  typeof src === 'object' ? get(src, path) : src;

export const getOptionParams = (
  option: Option,
): { label: string; description: string; value: any; disabled: boolean; children: any } => ({
  label: String(typeof option === 'object' ? get(option, 'key', '') : option),
  value: typeof option === 'object' ? option.value : option,
  description: String(typeof option === 'object' ? get(option, 'description', '') : ''),
  disabled: typeof option === 'object' && get(option, 'disabled'),
  children: typeof option === 'object' && get(option, 'children'),
});

export const makeValidate = (validators: any) => (value: any) => {
  let hasErrors = false;

  const result = validators.reduce((errors: IStringMap, validator: any) => {
    const error = validator(value);

    if (error) {
      hasErrors = true;
      errors[validator.name] = error; // eslint-disable-line
    }

    return errors;
  }, {});

  return hasErrors ? result : undefined;
};

export const createPropsGetter = (propName: string | string[], defaults?: any) => (source: any) =>
  Array.isArray(propName) ? pick(source, propName) : get(source, propName, defaults);
