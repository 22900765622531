/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useState } from 'react';
import { size } from 'lodash';

import { removeTranslatorProps } from 'utils';
import SelectWithLabel, { SelectWithLabelProps } from '../SelectWithLabel';
import FormField, { FormFieldProps } from '../FormField';
import OptionsPanel from '../../OptionsPanel';
import Option from './Option';

import styles from './SelectField.module.scss';

export type SelectFieldProps = FormFieldProps & SelectWithLabelProps;

const SelectField = (props: SelectFieldProps) => {
  const { input, children, label, classes, isFetching, options, ...rest } = props;
  const clearRest = removeTranslatorProps(rest);
  const [open, setOpen] = useState(false);

  return (
    <FormField label={label} classes={classes} {...rest} input={input}>
      <SelectWithLabel
        {...clearRest}
        {...input}
        options={options}
        isFetching={isFetching}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        data-test="select-field"
      >
        <OptionsPanel
          options={options}
          withSearch={size(options) > 5}
          onSelect={value => {
            input.onChange(value);
            setOpen(false);
          }}
          itemWrapper={Option}
          classes={{
            list: styles.list,
          }}
        />
      </SelectWithLabel>
    </FormField>
  );
};

export default SelectField;
