/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */
import React from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

type Props = {
  children: any;
  className?: string;
};

const Card = ({ children, className }: Props) => (
  <div className={classNames(styles.wrapper, className)}>{children}</div>
);

export default Card;
