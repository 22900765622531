/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { ComponentType, ReactElement } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import { invokeOrGet } from 'utils';
import PanelWithIcon from '../PanelWithIcon';

import styles from './EmptyState.module.scss';

type Props = {
  label: string;
  description: string;
  icon?: ComponentType<any>;
  isReady?: boolean;
  isIconWrapped?: boolean;
  classes?: {
    root?: string;
    label?: string;
    description?: string;
  };
  actionButton?: () => ReactElement;
};

const EmptyState = (props: Props) => {
  const { icon: Icon, label, description, classes, isReady, isIconWrapped, actionButton } = props;

  const action = invokeOrGet(actionButton);

  const panelClassNames = {
    root: classNames(get(classes, 'root'), styles.panel, { [styles.panelWithAction]: !!action }),
    label: classNames(get(classes, 'label'), styles.label),
    description: classNames(get(classes, 'description'), styles.description),
  };
  const wrapperClassName = classNames(
    'column',
    classNames(get(classes, 'root'), styles.root),
    styles.wrapper,
  );

  return isReady ? (
    <div className={wrapperClassName}>
      <PanelWithIcon
        classes={panelClassNames}
        label={label}
        description={description}
        isIconWrapped={isIconWrapped}
      >
        {Icon && (
          <div className={styles.icon}>
            <Icon />
          </div>
        )}
      </PanelWithIcon>

      {action}
    </div>
  ) : null;
};

EmptyState.defaultProps = {
  description: '',
  icon: undefined,
  classes: {},
  isReady: true,
  isIconWrapped: true,
  actionButton: undefined,
};

export default EmptyState;
