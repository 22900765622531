import React from 'react';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

interface Props {
  size?: number;
  isInBlock?: boolean;
  withBackdrop?: boolean;
}

export const Spinner = ({ size = 40, isInBlock = false, withBackdrop }: Props) => (
  <div
    className={classNames('centered-flex', {
      [styles.inBlock]: isInBlock,
      [styles.inBackdrop]: withBackdrop,
    })}
  >
    <CircularProgress size={size} />
  </div>
);
