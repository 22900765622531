import React from 'react';
import { connect } from 'react-redux';

import { texts, ROUTE_URLS } from 'utils';
import { AppState, getAuthorizationInfo } from 'state';
import Text from '../Text';
import NavigationItem from './NavigationItem';

import styles from './Navigation.module.scss';

const { healthStatus, incidents, incidentsHistory } = ROUTE_URLS;

type Props = {
  isAuthorized: boolean;
};

function Navigation(props: Props) {
  const { isAuthorized } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.currentPageWrapper}>
        <Text variant="heading3" className={styles.currentPageTitle}>
          {texts.navigation.healthStatus.navTitle}
        </Text>
      </div>

      <div className={styles.navItemsWrapper}>
        <NavigationItem to={healthStatus} label="NAVIGATION.SERVICES" />
        <NavigationItem
          to={isAuthorized ? incidents : incidentsHistory}
          label="NAVIGATION.INCIDENTS"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  isAuthorized: getAuthorizationInfo(state, 'isAuthorized'),
});

export default connect(mapStateToProps)(Navigation);
