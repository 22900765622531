import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { authorizationReducer, authorizationModuleName } from './authorization';
import { serviceGroupsReducer, serviceGroupsModuleName } from './serviceGroups';
import { serviceDetailsReducer, serviceDetailsModuleName } from './serviceDetails';
import { resetPasswordReducer, resetPasswordModuleName } from './resetPassword';
import { incidentsReducer, incidentsModuleName } from './incidents';
import ui from './ui';
import entities from './entities';

export default (history: History) =>
  combineReducers({
    [authorizationModuleName]: authorizationReducer,
    [serviceGroupsModuleName]: serviceGroupsReducer,
    [serviceDetailsModuleName]: serviceDetailsReducer,
    [resetPasswordModuleName]: resetPasswordReducer,
    [incidentsModuleName]: incidentsReducer,
    // should be `form` due to `redux-form` api
    form: reduxFormReducer,
    ui,
    entities,
    router: connectRouter(history),
  });
