import { get } from 'lodash';

const makeGetState = <T>(path: string) => (state: any): T => get(state, path);

const makeGetEntitiesState = <T>(path: string) => (state: any): T => get(state, ['entities', path]);

const makeGetValueFromProps = <T>(path: string = '') => (
  state: any,
  value: any,
  defaultValue?: any,
): T => get(state, [path, value], defaultValue);

export default {
  makeGetState,
  makeGetEntitiesState,
  makeGetValueFromProps,
};
