/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useState } from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classnames from 'classnames';
import { isUndefined, get } from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';

import { isEnterKeyPress, removeSpecialCharacters } from 'utils';

import styles from './Search.module.scss';

export type Props = WithTranslation & {
  initialQuery?: string;
  minLength?: number;
  maxLength?: number;
  className?: string;
  placeholder?: string;
  onChange?: (query: string) => void;
  onSubmit?: (query: string | void) => void | null;
};

const useSearchForm = ({ initialQuery, minLength = 2, maxLength, onChange, onSubmit }: Props) => {
  const [value, setValue] = useState(initialQuery);
  const [valid, setValid] = useState(true);
  const [submittedValue, setSubmittedValue] = useState(initialQuery);

  const handleChange = (e, definedValue?: string) => {
    const query = removeSpecialCharacters(
      isUndefined(definedValue) ? get(e, 'target.value') : definedValue,
    );

    setValid(
      !!(query && query.length >= minLength && (maxLength ? query.length <= maxLength : true)),
    );
    setValue(query);

    if (onChange) onChange(query);
  };

  const handleSubmit = (_e?, definedValue?: string) => {
    const query = removeSpecialCharacters(isUndefined(definedValue) ? value : definedValue);

    if (submittedValue === query || (!valid && query) || !onSubmit) return;

    onSubmit(query);
    setSubmittedValue(query);
  };

  const handleClear = () => {
    handleChange(null, '');
    handleSubmit(null, '');
  };

  const handleKeyPress = e => {
    if (isEnterKeyPress(e)) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return {
    handleSubmit,
    handleKeyPress,
    valid,
    input: { value, onChange: handleChange },
    handleClear,
    submittedValue,
  };
};

const SearchComponent = (props: Props) => {
  const { placeholder = 'COMMON.SEARCH', t, className, onSubmit } = props;
  const { handleSubmit, handleKeyPress, input, valid, handleClear, submittedValue } = useSearchForm(
    props,
  );

  const wrapperClassName = classnames(styles.search, className, {
    [styles.searchInvalid]: !valid,
  });

  const showClear = onSubmit ? submittedValue : input.value;

  return (
    <div className={wrapperClassName} onSubmit={handleSubmit}>
      <input
        type="text"
        {...input}
        placeholder={t(placeholder)}
        maxLength={props.maxLength}
        className={styles.input}
        onKeyPress={handleKeyPress}
        data-test="search-input"
      />
      {showClear ? (
        <div className={styles.btn} onClick={handleClear}>
          <CloseIcon />
        </div>
      ) : (
        <div className={styles.btn} onClick={handleSubmit}>
          <MagnifyIcon />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SearchComponent);
