/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { Trans } from 'react-i18next';

import DialogBoilerplate, { CommonDialogPropTypes } from '../DialogBoilerplate';
import styles from './ConfirmDialog.module.scss';

type ConfirmDialogProps = CommonDialogPropTypes & { description: string };

function ConfirmDialog(props: ConfirmDialogProps) {
  const { description, children, ...rest } = props;

  return (
    <DialogBoilerplate {...props}>
      {children || (
        <div className={styles.description}>
          <Trans i18nKey={description} values={rest} />
        </div>
      )}
    </DialogBoilerplate>
  );
}

ConfirmDialog.defaultProps = {
  description: '',
};

export default ConfirmDialog;
