/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import queryString from 'query-string';
import classNames from 'classnames';

import { Region } from 'types';
import {
  AppState,
  getServiceGroups,
  fetchServiceGroups as fetchServicesInfoCreator,
  fetchServiceGroupsPrivate as fetchServicesInfoCreatorPrivate,
  ServiceGroups,
  getAuthorizationInfo,
} from 'state';
import { texts } from 'utils';
import { LoadingWrapper, ErrorWrapper, PageHeader } from 'components';
import RegionTabs from './components/RegionTabs';
import ServiceGroupsList from './containers/ServiceGroupsList';
import Incidents from './containers/Incidents';

import styles from './HealthStatus.module.scss';

const useLocation = ({ location }) => {
  const parsedRegion: string = String(queryString.parse(location.search)['region']) || '';
  const region = Region[parsedRegion] || Region.US;
  const [currentRegion, setCurrentRegion] = useState<Region>(region);

  return {
    currentRegion,
    setCurrentRegion,
  };
};

interface Props extends RouteChildrenProps {
  servicesInfo: ServiceGroups;
  fetchServicesInfo: (params: { queryParams: { region: Region } }) => Promise<any>;
  fetchServicesInfoPrivate: (params: { queryParams: { region: Region } }) => Promise<any>;
  logout: () => Promise<any>;
  isAuthorized: boolean;
}

const HealthStatus = ({
  servicesInfo: { services, isLoading, error },
  fetchServicesInfo,
  fetchServicesInfoPrivate,
  location,
  isAuthorized,
}: Props) => {
  const { currentRegion, setCurrentRegion } = useLocation({ location });

  useEffect(() => {
    isAuthorized
      ? fetchServicesInfoPrivate({ queryParams: { region: currentRegion } })
      : fetchServicesInfo({ queryParams: { region: currentRegion } });
  }, [fetchServicesInfo, fetchServicesInfoPrivate, currentRegion, isAuthorized]);

  return (
    <div className="full-width">
      <PageHeader
        label="HEALTH_STATUS.PAGE_HEADER.LABEL"
        description="HEALTH_STATUS.PAGE_HEADER.DESCRIPTION"
        showBreadcrumbs={false}
      />

      <section className={classNames('route-container with-padding', styles.wrapper)}>
        <div>
          <RegionTabs
            currentTab={currentRegion}
            onChange={(_, value: Region) => setCurrentRegion(value)}
          />

          <LoadingWrapper isLoading={isLoading}>
            <ErrorWrapper
              hasError={!!error}
              title={texts.error.title}
              description={texts.error.description}
            >
              <ServiceGroupsList services={services} isAuthorized={isAuthorized} />
            </ErrorWrapper>
          </LoadingWrapper>
        </div>

        {!isAuthorized && <Incidents region={currentRegion} />}
      </section>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAuthorized: getAuthorizationInfo(state, 'isAuthorized'),
  servicesInfo: getServiceGroups(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchServicesInfo: bindActionCreators<(dispatch: Dispatch) => void, any>(
    fetchServicesInfoCreator,
    dispatch,
  ),
  fetchServicesInfoPrivate: bindActionCreators<(dispatch: Dispatch) => void, any>(
    fetchServicesInfoCreatorPrivate,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthStatus);
