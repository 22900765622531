/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.ts
 *
 * @author: Panasonic, developer
 */

import { combineReducers } from 'redux';

import {
  incidentAvailableServicesReducer,
  incidentAvailableServicesModuleName,
} from './incidentAvailableServices';

import { incidentReducer, incidentModuleName } from './incident';

export * from './incidentAvailableServices';
export * from './incident';

export default combineReducers({
  [incidentAvailableServicesModuleName]: incidentAvailableServicesReducer,
  [incidentModuleName]: incidentReducer,
});
