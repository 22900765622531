import React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import { ROUTE_URLS } from 'utils';
import { AppState, getAuthorizationInfo, logoutCreator } from 'state';
import { Logo } from 'media';
import Button from '../Button';
import Navigation from '../Navigation';

import styles from './Header.module.scss';

interface Props {
  isAuthorized: boolean;
  logout: () => Promise<any>;
}

const Header = ({ isAuthorized, logout }: Props) => (
  <header className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.left}>
        <Link to={ROUTE_URLS.root}>
          <Logo />
        </Link>

        <Navigation />
      </div>

      {isAuthorized && (
        <Button onClick={() => logout()} color="secondary" variant="outlined">
          <Trans i18nKey="AUTH.LOGOUT" />
        </Button>
      )}
    </div>
  </header>
);

const mapStateToProps = (state: AppState) => ({
  isAuthorized: getAuthorizationInfo(state, 'isAuthorized'),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  logout: bindActionCreators<(dispatch: Dispatch) => void, any>(logoutCreator, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
