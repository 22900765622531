/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteComponentProps, withRouter } from 'react-router';
import { LocationDescriptor } from 'history';

import { modalActions } from 'state';
import { MODAL_TYPES } from 'utils';

type Props = RouteComponentProps<any> & {
  actions: {
    openModal: (config: any) => void;
    push: (location: LocationDescriptor) => void;
  };
  when: boolean;
  title: string;
  description: string;
  onConfirm?: () => void;
};

class RouteChangeConfirm extends Component<Props> {
  static defaultProps = {
    title: 'title',
    description: 'description',
    onConfirm: () => {},
  };

  unblock: void | (() => void) | undefined;

  componentDidMount() {
    this.blockTransition();
  }

  componentWillUnmount() {
    if (this.unblock) this.unblock();
  }

  blockTransition = () => {
    const {
      title,
      description,
      actions: { openModal },
    } = this.props;

    this.unblock = this.props.history.block((nextLocation: LocationDescriptor): any => {
      if (this.props.when) {
        openModal({
          title,
          description,
          modalType: MODAL_TYPES.CONFIRM_DIALOG,
          onConfirm: () => {
            const { onConfirm } = this.props;

            if (onConfirm) onConfirm();

            this.moveForward(nextLocation);
          },
        });
      }

      return !this.props.when;
    });
  };

  moveForward = (nextLocation: LocationDescriptor) => {
    if (this.unblock) this.unblock();
    this.props.actions.push(nextLocation);
    this.blockTransition();
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators<any, any>(
    {
      ...modalActions,
      push,
    },
    dispatch,
  ),
});

export default compose<any>(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
)(RouteChangeConfirm);
