import React from 'react';

import { Spinner } from '../Spinner';

interface Props {
  children: any;
  isLoading: boolean;
}

export const LoadingWrapper = ({ children, isLoading }: Props) =>
  isLoading ? <Spinner /> : children;
