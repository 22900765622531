/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

export const passwordsMatchValidator = values => {
  const errors = {};
  const mismatchError = values && values.password !== values.confirmPassword && 'ERRORS.ERR_06';

  // @ts-ignore
  if (mismatchError) errors.confirmPassword = mismatchError;

  return errors;
};
