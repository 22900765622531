/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { invokeOrGet } from 'utils';

import styles from './NavigationItem.module.scss';

type Props = {
  label: string;
  to: string;
  active?: boolean;
  isAvailable?: boolean;
};

function NavigationItem({ label, to, active, isAvailable }: Props) {
  if (!invokeOrGet(isAvailable)) return null;

  return (
    <NavLink to={to} className={styles.link}>
      <Trans i18nKey={label} />
    </NavLink>
  );
}

NavigationItem.defaultProps = {
  isAvailable: true,
};

export default NavigationItem;
