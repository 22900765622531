/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { ReactNode, SyntheticEvent } from 'react';
import classnames from 'classnames';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';

import generalStyles from '../Table/Table.module.scss';
import styles from './ThComponent.module.scss';

type Sorting = {
  id: string;
  desc: boolean;
};

type SortIconProps = {
  sorting: Sorting;
  size?: number;
  color?: string;
  className?: string;
};
const SortIcon = ({ sorting, ...rest }: SortIconProps) =>
  sorting.desc ? <MenuDownIcon {...rest} /> : <MenuUpIcon {...rest} />;

SortIcon.defaultProps = {
  size: 16,
  color: '#999',
  className: styles.sortIcon,
};

type Props = {
  toggleSort?: (e: SyntheticEvent<any>) => void;
  className?: string;
  children?: ReactNode;
  sorting: Sorting;
  columnOffset: number;
  style: any;
};

function ThComponent(props: Props) {
  const { toggleSort, className, children, sorting, columnOffset, style, ...rest } = props;

  const thClassNames = classnames(
    className,
    generalStyles.tableCell,
    styles.th,
    'rt-th',
    '-outline-none',
    '-truncate',
    {
      [styles.sortAsc]: sorting && !sorting.desc,
      [styles.sortDesc]: sorting && sorting.desc,
    },
  );
  const left = columnOffset ? `${columnOffset}px` : undefined;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={thClassNames}
      onClick={e => toggleSort && toggleSort(e)}
      role="columnheader"
      tabIndex={-1} // Resolves eslint issues without implementing keyboard navigation incorrectly
      style={{ ...style, left }}
      {...rest}
    >
      <div className={styles.contentWrapper}>
        {children}
        {sorting && <SortIcon sorting={sorting} />}
      </div>
    </div>
  );
}

ThComponent.defaultProps = {
  toggleSort: () => {},
  className: '',
  children: '',
  sorting: null,
  columnOffset: undefined,
};

export default ThComponent;
