/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: serviceGroupStatusTypes.ts
 *
 * @author: Panasonic, developer
 */

const OPERATIONAL = 'OPERATIONAL';
const OUTAGE = 'OUTAGE';
const PARTIAL_OUTAGE = 'PARTIAL_OUTAGE';
const DEGRADED_PERFORMANCE = 'DEGRADED_PERFORMANCE';
const UNDER_MAINTENANCE = 'UNDER_MAINTENANCE';

const LIST = [
  {
    value: OPERATIONAL,
    key: 'SERVICES.STATUSES.OPERATIONAL',
  },
  {
    value: OUTAGE,
    key: 'SERVICES.STATUSES.OUTAGE',
  },
  {
    value: PARTIAL_OUTAGE,
    key: 'SERVICES.STATUSES.PARTIAL_OUTAGE',
  },
  {
    value: DEGRADED_PERFORMANCE,
    key: 'SERVICES.STATUSES.DEGRADED_PERFORMANCE',
  },
  {
    value: UNDER_MAINTENANCE,
    key: 'SERVICES.STATUSES.UNDER_MAINTENANCE',
  },
];

export const SERVICE_GROUP_STATUS_TYPES = {
  OPERATIONAL,
  OUTAGE,
  PARTIAL_OUTAGE,
  DEGRADED_PERFORMANCE,
  UNDER_MAINTENANCE,

  LIST,
};
