/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component, ReactNode, ReactElement } from 'react';
import { isUndefined } from 'lodash';

import { ToolAction } from 'types';
import MenuWithAnchor from '../MenuWithAnchor';
import MenuActionButton from '../MenuActionButton';

import styles from './ToolsWithActions.module.scss';

type Props = {
  actions: ToolAction[];
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
};

class ToolsWithActions extends Component<Props> {
  static defaultProps = {
    disabled: false,
    children: null,
    className: '',
  };

  get actions() {
    const { actions } = this.props;

    return (
      actions &&
      actions.map<ReactElement<any>>(action => <MenuActionButton {...action} key={action.text} />)
    );
  }

  get isEmpty() {
    const { actions, children } = this.props;

    return !children && !actions.some(({ isAvailable }) => isUndefined(isAvailable) || isAvailable);
  }

  render() {
    const { children, disabled, className } = this.props;

    return (
      !this.isEmpty && (
        <MenuWithAnchor
          disabled={disabled}
          className={className}
          classes={{ iconButton: styles.iconButton }}
        >
          {children || this.actions}
        </MenuWithAnchor>
      )
    );
  }
}

export default ToolsWithActions;
