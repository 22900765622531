/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import { Select, withStyles } from '@material-ui/core';

export default withStyles({
  root: {},
  select: {
    height: '40px',
    lineHeight: '27px',
    boxSizing: 'border-box',
    paddingLeft: '10px',
    fontSize: '13px',
  },
  icon: {
    color: 'black',
    top: 'calc(50% - 10px)',
    right: 10,
    width: 20,
    height: 20,
  },
})(Select);
