/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: columns.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import { Trans } from 'react-i18next';

import ServicesTools from './components/ServicesTools';

const statusAccessor = ({ status }) => <Trans i18nKey={`SERVICES.STATUSES.${status}`} />;

const columns = [
  {
    id: 'name',
    Header: 'COMMON.SERVICE',
    accessor: ({ name, region }) => `(${region}) ${name}`,
    width: 340,
  },
  {
    id: 'status',
    Header: 'COMMON.STATUS',
    accessor: statusAccessor,
  },
  {
    Header: '',
    sortable: false,
    Cell: ServicesTools,
    className: 'rt-button-cell',
    width: 40,
  },
];

export default columns;
