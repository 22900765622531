/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form, reduxForm, InjectedFormProps } from 'redux-form';
import { Grid } from '@material-ui/core';
import { find, omit, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { matchPath, withRouter } from 'react-router';

import {
  getIncidentAvailableServices,
  getNewServices,
  getIncident,
  incidentActions,
  incidentAvailableServicesActions,
} from 'state';
import { Id, Service } from 'types';
import { requiredValidator, SERVICE_STATUS_TYPES, ROUTE_URLS } from 'utils';
import { CustomField, SelectField } from 'components';

type Props = {
  params: any;
  newServices: [];
  incidentId: Id;
  isEdit: boolean;
  availableServicesInfo: {
    services: Service[];
    isLoading: boolean;
  };
  actions: {
    fetchIncidentAvailableServices: (payload?: any, meta?: any) => void;
    addNewService: (payload: any) => void;
  };
  onSubmitSuccess: () => any;
  handleSubmit: () => any;
} & InjectedFormProps;

const formName = 'servicesAddForm';

class ServicesAddForm extends Component<Props> {
  static formName = formName;

  componentDidMount() {
    const {
      actions: { fetchIncidentAvailableServices },
      incidentId,
    } = this.props;

    let params;

    if (incidentId !== 'new') {
      params = { queryParams: { incidentId } };
    }

    fetchIncidentAvailableServices(params);
  }

  handleSubmit = ({ name, status }) => {
    const {
      actions: { addNewService },
      availableServicesInfo: { services },
      initialValues,
    } = this.props;

    const result = omit(
      {
        ...find(services, { key: name }),
        status,
        id: get(initialValues, 'id'),
      },
      ['key', 'value'],
    );

    addNewService(result);
  };

  get services() {
    const {
      newServices,
      initialValues,
      availableServicesInfo: { services },
      t,
    } = this.props;

    return services.map(service => {
      const isServiceInUse =
        !!find(newServices, { name: service.name, region: service.region }) &&
        get(initialValues, 'name') !== service.key;

      return {
        ...service,
        key: isServiceInUse ? `${service.key} - ${t('COMMON.IN_USE')}` : service.key,
        disabled: isServiceInUse,
      };
    });
  }

  render() {
    const {
      availableServicesInfo: { isLoading },
      handleSubmit,
      isEdit,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <CustomField
              name="name"
              type="text"
              label="INCIDENT.SERVICES.MODAL.SERVICE_NAME"
              component={SelectField}
              validate={[requiredValidator]}
              required
              options={this.services}
              disabled={isLoading || isEdit}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomField
              name="status"
              type="text"
              label="INCIDENT.SERVICES.STATUS.TITLE"
              component={SelectField}
              validate={[requiredValidator]}
              required
              options={SERVICE_STATUS_TYPES.LIST}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state, { history }) => {
  const match = matchPath(history.location.pathname, { path: ROUTE_URLS.incident });
  const incidentId = get(match, 'params.id');

  return {
    incidentId,
    availableServicesInfo: getIncidentAvailableServices(state),
    newServices: getNewServices(state),
    incident: getIncident(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...incidentActions,
      ...incidentAvailableServicesActions,
    },
    dispatch,
  ),
});

export default compose(
  reduxForm({
    form: formName,
  }),
  withRouter,
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ServicesAddForm);
