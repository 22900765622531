/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: incidentStatuses.ts
 *
 * @author: Panasonic, developer
 */

const INVESTIGATION = 'INVESTIGATION';
const IDENTIFIED = 'IDENTIFIED';
const RESOLVED = 'RESOLVED';
const DRAFT = 'DRAFT';
const ARCHIVED = 'ARCHIVED';

const LIST = [
  {
    value: INVESTIGATION,
    key: 'INCIDENTS.STATUSES.INVESTIGATION',
  },
  {
    value: IDENTIFIED,
    key: 'INCIDENTS.STATUSES.IDENTIFIED',
  },
  {
    value: RESOLVED,
    key: 'INCIDENTS.STATUSES.RESOLVED',
  },
];

export const INCIDENT_STATUS_TYPES = {
  INVESTIGATION,
  IDENTIFIED,
  RESOLVED,
  DRAFT,
  ARCHIVED,

  LIST,
};
