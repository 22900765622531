/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';

import StickyWrapper from '../StickyWrapper';

import styles from './FooterActions.module.scss';

type Props = {
  children: any;
};

const FooterActions = ({ children }: Props) => (
  <StickyWrapper>
    <div className={styles.simpleActions}>
      <div className={styles.simpleActionsContent}>{children}</div>
    </div>
  </StickyWrapper>
);

export default FooterActions;
