/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { PaginatedTable, LabelWithDescription, Button } from 'components';
import { Meta, Region, Incident } from 'types';
import {
  incidentsActions,
  getIncidents,
  getIncidentsMeta,
  getIncidentsIsFetching,
  getIncidentsQueryParams,
} from 'state';
import { ROUTE_URLS } from 'utils';
import { EmptyStateIcon } from 'media';
import columns from './columns';
import IncidentsSubTable from '../../components/IncidentsSubTable';

import styles from './Incidents.module.scss';

type Props = RouteComponentProps<any> & {
  actions: {
    fetchPublicIncidents: (params: any) => void;
  };
  meta: Meta;
  incidents: Incident[];
  isFetching: boolean;
  incidentsQueryParams: any;
  isAuthorized: boolean;
  region: Region;
};

const Incidents = ({
  actions: { fetchPublicIncidents },
  incidents,
  meta,
  isFetching,
  incidentsQueryParams,
  history: { push },
  region,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const fetchData = tableState => {
    fetchPublicIncidents({ queryParams: { ...incidentsQueryParams, ...tableState, region } });
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLoaded) fetchPublicIncidents({ queryParams: { ...incidentsQueryParams, region } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  const getNoDataProps = () => ({
    label: 'INCIDENTS.NO_DATA.LABEL',
    description: 'INCIDENTS.NO_DATA.DESCRIPTION',
    icon: EmptyStateIcon,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <LabelWithDescription
          label="INCIDENTS.PUBLIC.HEADER.LABEL"
          description="INCIDENTS.PUBLIC.HEADER.DESCRIPTION"
          classes={{ label: styles.label, description: styles.description }}
        />
      </div>
      <div
        className={classNames({
          [styles.withBorder]: isEmpty(incidents),
        })}
      >
        <PaginatedTable
          data={incidents}
          meta={meta}
          columns={columns}
          onTableStateChange={fetchData}
          loading={isFetching}
          SubComponent={({ original: { states } }) => <IncidentsSubTable data={states} />}
          getNoDataProps={getNoDataProps}
          defaultPageSize={5}
          classes={{ root: styles.tableRoot }}
        />
      </div>
      <div className={styles.actions}>
        <Button
          variant="outlined"
          className={styles.actionButton}
          onClick={() => push(ROUTE_URLS.login)}
        >
          <Trans i18nKey="INCIDENTS.MANAGE_INCIDENTS" />
        </Button>
        <Button
          variant="contained"
          className={styles.actionButton}
          onClick={() => push(ROUTE_URLS.incidentsHistory)}
        >
          <Trans i18nKey="INCIDENTS.PUBLIC.SEE_FULL_HISTORY" />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  incidents: getIncidents(state),
  meta: getIncidentsMeta(state),
  isFetching: getIncidentsIsFetching(state),
  incidentsQueryParams: getIncidentsQueryParams(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators<(dispatch: Dispatch) => void, any>(incidentsActions, dispatch),
});

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Incidents);
