/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.js
 *
 * @author: Panasonic, developer
 *
 */

import React, { ReactNode } from 'react';
import classNames from 'classnames';

import ContentWithTooltip from '../../ContentWithTooltip';

import generalStyles from '../Table/Table.module.scss';
import styles from './TdComponent.module.scss';

type Props = {
  className: string;
  children?: ReactNode;
  emptyCellValue?: string;
  style: any;
  onClick: () => void;
  columnOffset: number;
  withTruncate?: boolean;
};

const TableCell = (props: Props) => {
  const { className, children, style, onClick, emptyCellValue, columnOffset, withTruncate } = props;
  const tdClassNames = classNames(className, generalStyles.tableCell, styles.td, 'rt-td');
  const left = columnOffset ? `${columnOffset}px` : undefined;

  return (
    /* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
    <div className={tdClassNames} role="gridcell" onClick={onClick} style={{ ...style, left }}>
      {withTruncate ? (
        <ContentWithTooltip withTruncate={withTruncate}>
          {children || emptyCellValue}
        </ContentWithTooltip>
      ) : (
        children || emptyCellValue
      )}
    </div>
  );
};

TableCell.defaultProps = {
  children: null,
  style: {},
  onClick: () => {},
  emptyCellValue: '-',
  columnOffset: undefined,
  withTruncate: true,
};

export default TableCell;
