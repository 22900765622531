/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: RuleItem.js
 *
 * @author: Panasonic, developer
 *
 */

import React from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import MinusIcon from 'mdi-react/MinusIcon';

import LabelWithDescription from '../LabelWithDescription';

import styles from './RuleItem.module.scss';

export type Props = {
  name: string;
  nameParams: Object;
  checked: boolean;
};

const RuleItem = ({ name, nameParams, checked }: Props) => (
  <div className={styles.wrapper}>
    {checked ? <CheckIcon color="#21d488" size={16} /> : <MinusIcon color="#999999" size={16} />}

    <LabelWithDescription label={name} labelArgs={nameParams} classes={{ label: styles.label }} />
  </div>
);

export default RuleItem;
