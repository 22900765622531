import {
  URLS,
  makeApiAction,
  getStartFetchingType,
  getStopFetchingType,
  getFetchType,
} from 'utils';

/*
 * TYPES
 * */
export const resetPasswordModuleName = 'resetPassword';
const descriptor = 'RESET_PASSWORD';

const FETCH_RESET_PASSWORD = getFetchType(descriptor);
const FETCH_RESET_PASSWORD_START = getStartFetchingType(FETCH_RESET_PASSWORD);
const FETCH_RESET_PASSWORD_STOP = getStopFetchingType(FETCH_RESET_PASSWORD);

/*
 * ACTIONS
 * */
export const resetPasswordCreator = makeApiAction({
  baseUrl: URLS.resetPassword,
  processingDescriptor: descriptor,
  initialActionType: FETCH_RESET_PASSWORD,
  method: 'post',
});

/*
 * REDUCER
 * */
const initialState = {
  isAuthorized: false,
  isLoading: false,
};

export const resetPasswordReducer = (state = initialState, { type }: any) => {
  switch (type) {
    case FETCH_RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_RESET_PASSWORD_STOP:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
