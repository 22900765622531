import React from 'react';
import classnames from 'classnames';

import { Trans } from 'react-i18next';

import styles from './TitleWithDescription.module.scss';

interface Props {
  title: string;
  description?: string;
  classes: {
    root?: string;
    title?: string;
    description?: string;
  };
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function TitleWithDescription({ title, description, onClick, classes }: Props) {
  const rootClasses = classnames(styles.root, classes.root);
  const titleClasses = classnames(styles.title, classes.title);
  const descriptionClasses = classnames(styles.description, classes.description);

  return (
    <div onClick={onClick} className={rootClasses}>
      <div className={titleClasses}>
        <Trans>{title}</Trans>
      </div>

      {description && (
        <div className={descriptionClasses}>
          <Trans>{description}</Trans>
        </div>
      )}
    </div>
  );
}

TitleWithDescription.defaultProps = {
  classes: {},
  onClick: undefined,
  description: undefined,
};

export default TitleWithDescription;
