import React from 'react';

import { FooterLogo } from 'media';
import { texts } from 'utils';

import Text from '../Text';

import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.content}>
        <FooterLogo />

        <Text className={styles.copyright}>{texts.footer.copyright}</Text>
      </div>
    </footer>
  );
};

export default Footer;
