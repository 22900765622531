/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */
import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { getIncident } from 'state';
import { FormPanel, LabelWithDescription } from 'components';

import styles from './General.module.scss';

const General = ({ incident }) => {
  const status = get(incident, 'status');
  const statusTitle = status ? `INCIDENTS.STATUSES.${status}` : '-';
  const link = get(incident, 'jiraTicket');

  return (
    <FormPanel
      label="INCIDENT.GENERAL.TITLE"
      description="INCIDENT.GENERAL.HINT"
      classes={{
        root: styles.block,
      }}
    >
      <LabelWithDescription
        label="INCIDENT.GENERAL.SUMMARY"
        description={get(incident, 'summary')}
        classes={{
          root: styles.block,
          label: styles.title,
          description: styles.description,
        }}
      />

      <LabelWithDescription
        label="INCIDENT.GENERAL.STATUS"
        description={statusTitle}
        classes={{
          root: styles.block,
          label: styles.title,
          description: styles.description,
        }}
      />

      <LabelWithDescription
        label="INCIDENT.GENERAL.DESCRIPTION"
        description={get(incident, 'description', '-')}
        classes={{
          root: styles.block,
          label: styles.title,
          description: styles.description,
        }}
      />

      <LabelWithDescription
        label="INCIDENT.GENERAL.JIRA_LINK"
        description={
          link ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          ) : (
            '-'
          )
        }
        classes={{
          label: styles.title,
          description: styles.description,
        }}
      />
    </FormPanel>
  );
};

const mapStateToProps = state => ({
  incident: getIncident(state),
});

export default connect(mapStateToProps)(General) as ComponentType;
