import { get } from 'lodash';

import {
  URLS,
  makeApiAction,
  selectorMakers,
  getSuccessType,
  getFailedType,
  getFetchType,
  getProfileDataAndProcessTokens,
  removeAuthTokensAndHeaders,
} from 'utils';
import { FETCH_LOGIN_SUCCESS, FETCH_LOGOUT, FETCH_LOGOUT_SUCCESS } from '../login';

/*
 * TYPES
 * */
const AUTHORIZATION = 'AUTHORIZATION';
export const authorizationModuleName = 'authorization';

export const FETCH_AUTHORIZATION = getFetchType(AUTHORIZATION);
const FETCH_AUTHORIZATION_SUCCESS = getSuccessType(FETCH_AUTHORIZATION);
const FETCH_AUTHORIZATION_FAILED = getFailedType(FETCH_AUTHORIZATION);

/*
 * ACTIONS
 * */
export const fetchLAuthorizationCreator = makeApiAction({
  baseUrl: URLS.auth,
  processingDescriptor: AUTHORIZATION,
  initialActionType: FETCH_AUTHORIZATION,
  method: 'post',
  shouldRefetchToken: false,
  transformResponse: response => {
    getProfileDataAndProcessTokens(response);
  },
  transformError: error => {
    const isTokenExpired = get(error, 'response.status') === 404;

    if (isTokenExpired) {
      removeAuthTokensAndHeaders();
    }
  },
});

/*
 * REDUCER
 * */
const initialState = {
  isAuthorized: false,
  isLoading: false,
};

export function authorizationReducer(state = initialState, { type, payload }: any) {
  switch (type) {
    case FETCH_LOGOUT:
    case FETCH_AUTHORIZATION:
      return {
        ...state,
        isLoading: !get(payload, 'shouldRefetchToken'),
      };

    case FETCH_AUTHORIZATION_SUCCESS:
    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        isLoading: false,
      };

    case FETCH_AUTHORIZATION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthorized: false,
        isLoading: false,
      };

    default:
      return state;
  }
}

/*
 * SELECTORS
 * */
export const getAuthorizationInfo = selectorMakers.makeGetValueFromProps<any>(
  authorizationModuleName,
);
