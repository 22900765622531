import axios from 'axios';

import { getAccessToken } from '../http';

const API = axios.create({});

/**
 * interceptor that remove Authorization header for external resources
 */
API.interceptors.request.use(config => {
  const token = getAccessToken();

  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: getAccessToken(),
      },
    };
  }

  return config;
});

export default API;
