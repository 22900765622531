/**
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 *                     All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 *
 */

import React, { ComponentType } from 'react';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';

import { toPascalCase } from 'utils';

import styles from './Button.module.scss';

export type ButtonProps = {
  variant?: 'default' | 'outlined' | 'contained' | 'fab' | 'inline';
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  fullSize?: boolean | string;
  upperCase?: boolean;
  children?: any;
  className?: string;
  centered?: boolean;
  type?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => any;
  component?: ComponentType<any>;
};

const Button = ({
  children,
  className,
  variant = 'default',
  color = 'primary',
  size = 'medium',
  fullSize = false,
  upperCase = true,
  centered = true,
  isLoading = false,
  ...rest
}: ButtonProps) => {
  const isFab = variant === 'fab';
  const classes = classNames(
    styles.wrapper,
    styles[isFab ? 'contained' : variant],
    styles[color],
    styles[size],
    {
      [styles.centered]: centered,
      [styles.fullSize]: fullSize,
      [styles.upperCase]: upperCase,
      [styles.fab]: isFab,
      [styles.withSpinner]: isLoading,
    },
    className,
  );

  return (
    <button {...rest} className={classes}>
      {isLoading ? (
        <CircularProgress
          size={22}
          classes={{
            root: classNames(styles[`spinner${toPascalCase(variant)}`], styles[color]),
            circleIndeterminate: styles.spinnerCircle,
          }}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
