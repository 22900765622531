/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router';

import {
  getIncidentMetaInfo,
  IncidentMetaInfo,
  incidentActions,
  incidentAvailableServicesActions,
} from 'state';
import { LoadingWrapper, PageHeader } from 'components';
import IncidentSummary from './components/Summary';
import IncidentServices from './components/Services';
import FooterActions from './components/Actions';
import General from './components/General';

const BREADCRUMBS = [
  {
    title: 'INCIDENT.BREADCRUMBS.ROOT',
    path: '/incidents',
  },
  {
    title: 'INCIDENT.BREADCRUMBS.DETAILS',
  },
];

type StateToProps = {
  incidentInfo: IncidentMetaInfo;
};

type DispatchToProps = {
  actions: {
    fetchIncident: ({ id: Id }) => Promise<any>;
    incidentReset: () => void;
    resetNewService: () => void;
  };
};

const Incident = ({
  actions: { fetchIncident, incidentReset, resetNewService },
  incidentInfo,
  match: {
    params: { id },
  },
}: StateToProps & DispatchToProps & RouteComponentProps<any>) => {
  useEffect(() => {
    fetchIncident({ id });

    return () => {
      resetNewService();
      incidentReset();
    };
  }, [fetchIncident, incidentReset, id, resetNewService]);

  const label = get(incidentInfo, 'incident.summary');

  return (
    <div className="full-width">
      <LoadingWrapper isLoading={incidentInfo.isLoading}>
        <PageHeader
          showBreadcrumbs
          label={label}
          description="INCIDENT.HINT"
          breadcrumbs={BREADCRUMBS}
        />

        <section className="route-container with-padding">
          <Grid container spacing={24}>
            <Grid item xs={8}>
              <General />

              <IncidentServices />
            </Grid>

            <Grid item xs={4}>
              <IncidentSummary />
            </Grid>
          </Grid>
        </section>

        <FooterActions />
      </LoadingWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  incidentInfo: getIncidentMetaInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators<(dispatch: Dispatch) => void, any>(
    { ...incidentActions, ...incidentAvailableServicesActions },
    dispatch,
  ),
});

export default connect<StateToProps, DispatchToProps, RouteComponentProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Incident);
