/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { Incident } from 'types';
import { getIncident } from 'state';
import { LabelWithDescription } from 'components';
import { FIELDS_MAP } from './helpers';

import styles from './Summary.module.scss';

type Props = {
  incident?: Incident;
};

const IncidentSummary = ({ incident }: Props) => {
  const status = get(incident, 'status');
  const isDraft = get(incident, 'draft');
  const statusLabel = !isDraft ? `INCIDENTS.STATUSES.${status}` : 'INCIDENTS.STATUSES.DRAFT';

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <LabelWithDescription
          label="INCIDENT.SUMMARY.TITLE"
          description="INCIDENT.SUMMARY.HINT"
          classes={{
            label: styles.cardTitle,
            description: styles.cardDescription,
          }}
        />
      </div>

      <div className={classNames(styles.card, styles.statusBlock)}>
        <LabelWithDescription
          label={statusLabel}
          description="COMMON.CURRENT_STATUS"
          classes={{
            label: styles.cardTitle,
            description: styles.cardDescription,
          }}
        />
      </div>

      <div className={styles.card}>
        <div className={styles.cardTitle}>
          <Trans i18nKey="INCIDENT.SUMMARY.OVERVIEW" />
        </div>

        <div>
          {FIELDS_MAP.map(({ name, trans, renderer }, index) => (
            <div className={styles.field} key={index}>
              <span className={styles.fieldName}>
                <Trans i18nKey={trans} />
              </span>

              <span className={styles.fieldValue}>
                {renderer ? renderer(get(incident, name)) : get(incident, name, '-')}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  incident: getIncident(state),
});

// @ts-ignore
export default connect(mapStateToProps)(IncidentSummary);
