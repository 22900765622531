/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: columns.tsx
 *
 * @author: Panasonic, developer
 */

import React from 'react';
import { Trans } from 'react-i18next';
import { property } from 'lodash';

import { Column } from 'types';
import { DateCellWithTime } from 'components';

const columns: Column[] = [
  {
    Header: 'INCIDENTS.COLUMNS.CREATED_ON',
    accessor: 'createdAt',
    width: 200,
    expander: true,
    Expander: ({ original: { createdAt } }) => DateCellWithTime({ value: createdAt }),
  },
  {
    Header: 'INCIDENTS.COLUMNS.SUMMARY',
    accessor: 'summary',
    minWidth: 400,
    width: undefined,
    expander: true,
    Expander: property('original.summary'),
  },
  {
    Header: 'INCIDENTS.COLUMNS.STATUS',
    id: 'status',
    Expander: ({ original: { status } }) => <Trans>{`INCIDENTS.STATUSES.${status}`}</Trans>,
    expander: true,
    width: undefined,
  },
  {
    expander: true,
    Header: '',
    width: 40,
    style: {
      minHeight: 40,
    },
    className: 'rt-button-cell',
  },
];

export default columns;
