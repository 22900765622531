/*
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: index.tsx
 *
 * @author: Panasonic, developer
 */

import React, { Component, ReactNode } from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { castArray, get, isEmpty, isUndefined, size, isNumber, isBoolean } from 'lodash';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';

import {
  getValueFromMixed,
  removeTranslatorProps,
  stub,
  getOptionParams,
  translateIfExists,
} from 'utils';
import Select from '../Select';
import MenuItem from '../../MenuItem';
import { Spinner } from '../../Spinner';
import ContentWithTooltip from '../../ContentWithTooltip';

import styles from './SelectWithLabel.module.scss';

// HACK to properly blur material ui select after menu close
// https://github.com/mui-org/material-ui/issues/11690
const onExited = () => {
  if (document.activeElement) {
    // @ts-ignore
    document.activeElement.blur();
  }
};

export type SelectWithLabelProps = WithTranslation & {
  name?: string;
  label?: string;
  options?: any[];
  defaultOption?: string;
  className?: string;
  keySuffix?: string;
  keyFormatter?: (key: string) => string;
  native?: boolean;
  multiple?: boolean;
  tReady?: any;
  i18n: any;
  children?: any;
  value?: any;
  labelArgs?: any;
  open?: boolean;
  onOpen: () => void;
  onClose: () => void;
  onBlur?: (e?: any) => void;
  classes?: {
    root?: string;
    select?: string;
    selectRoot?: string;
    selectMenuPaper?: string;
  };
  renderValue?: (x: any[]) => ReactNode;
  viewMode?: boolean;
  IconComponent?: any;
  error?: string;
  formError?: boolean | string;
  disabled?: boolean;
  isFetching?: boolean;
  onChange?: (v?: any) => void;
};

type State = {
  open: boolean;
};

class SelectWithLabel extends Component<SelectWithLabelProps, State> {
  static defaultProps = {
    label: '',
    className: '',
    keySuffix: '',
    keyFormatter: key => key,
    options: [],
    defaultOption: '',
    native: false,
    multiple: false,
    value: null,
    children: null,
    labelArgs: undefined,
    classes: {},
    renderValue: undefined,
    viewMode: false,
    IconComponent: undefined,
    error: undefined,
    formError: undefined,
    disabled: false,
    isFetching: false,
    onClose: () => {},
    onOpen: () => {},
  };

  static getDerivedStateFromProps({ open }: SelectWithLabelProps) {
    return !isUndefined(open) ? { open } : null;
  }

  state = {
    open: false,
  };

  get value() {
    const { value, multiple } = this.props;

    if (multiple) {
      switch (true) {
        case !!value && !Array.isArray(value):
          return [value];
        case !!value:
          return value;
        default:
          return [];
      }
    }

    return value;
  }

  get renderValue() {
    return this.props.renderValue || this.defaultRenderValue;
  }

  get itemWrapper() {
    const { native, multiple } = this.props;

    return native && !multiple ? 'option' : MenuItem;
  }

  get options() {
    const {
      t,
      options = [],
      keySuffix,
      keyFormatter,
      isFetching,
      children,
      viewMode,
      defaultOption = '',
      value,
    } = this.props;

    const ItemWrapper = this.itemWrapper;
    const defaultOptionName = viewMode ? '-' : defaultOption;

    if (isFetching) return <Spinner isInBlock />;

    return (
      children || [
        isUndefined(value) && (
          <ItemWrapper value={value} key={defaultOption}>
            <span className={styles.defaultOption}>{t(defaultOptionName)}</span>
          </ItemWrapper>
        ),
        size(options) ? (
          options.map(option => {
            const { label: key, value: optionValue } = getOptionParams(option);
            const optionDisabled = get(option, 'disabled', false);

            return (
              <ItemWrapper key={key} value={optionValue} disabled={optionDisabled}>
                {keyFormatter && keyFormatter(t(key))}
                {keySuffix}
              </ItemWrapper>
            );
          })
        ) : (
          <ItemWrapper disabled key="COMMON.NO_OPTIONS_AVAILABLE">
            <Trans i18nKey="COMMON.NO_OPTIONS_AVAILABLE" />
          </ItemWrapper>
        ),
      ]
    );
  }

  get icon() {
    const { IconComponent, viewMode } = this.props;
    const { open } = this.state;

    if (viewMode) return stub;
    if (IconComponent) return IconComponent;

    return open ? MenuUpIcon : MenuDownIcon;
  }

  handleClose = () => {
    this.setState(() => ({ open: false }));
    this.props.onClose();
  };

  handleOpen = () => {
    this.setState(() => ({ open: true }));
    this.props.onOpen();
  };

  defaultRenderValue = (selections: (string | number)[] | string | number) => {
    const { options = [], defaultOption = '', t } = this.props;
    const isSelectionsEmpty =
      !isNumber(selections) && !isBoolean(selections) && isEmpty(selections);

    if (isEmpty(options) || isSelectionsEmpty)
      return <span className={styles.defaultOption}>{translateIfExists(t, defaultOption)}</span>;

    const text = castArray(selections)
      .map(option => {
        const correspondingOption =
          options.find(o => getValueFromMixed(o, 'value') === option) || option;
        const value = getValueFromMixed(correspondingOption, 'key');

        return translateIfExists(t, value);
      })
      .join(', ');

    return <ContentWithTooltip>{text}</ContentWithTooltip>;
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      t,
      label,
      options,
      className,
      defaultOption,
      viewMode,
      labelArgs,
      IconComponent,
      classes = {},
      error,
      formError,
      disabled,
      keyFormatter,
      keySuffix,
      isFetching,
      ...rest
    } = this.props;
    const { select, root, selectRoot, selectMenuPaper } = classes;
    const { open } = this.state;

    const rootClassName = classNames(styles.wrapper, root, {
      [styles.wrapperViewMode]: viewMode,
      [styles.disabled]: disabled,
    });
    const selectClassName = classNames(styles.default, className, select, {
      [styles.viewMode]: viewMode,
      [styles.error]: error,
      [styles.open]: open,
    });
    const clearProps = removeTranslatorProps(rest);
    const combinedClasses = viewMode
      ? {
          select: styles.selectViewMode,
          root: styles.rootViewMode,
        }
      : { root: selectRoot, icon: disabled ? styles.disabledIcon : '', select: styles.select };

    const menuPaperClass = classNames(styles.selectMenuPaper, selectMenuPaper);

    return (
      <div className={rootClassName}>
        {label && (
          <label htmlFor={`select-${rest.name}`} className={styles.label}>
            {t(label, labelArgs)}
          </label>
        )}
        <Select
          disableUnderline
          displayEmpty
          {...clearProps}
          className={selectClassName}
          MenuProps={{
            onExited,
            classes: {
              paper: menuPaperClass,
            },
            MenuListProps: { disablePadding: true },
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
          }}
          IconComponent={this.icon}
          // @ts-ignore
          renderValue={this.renderValue}
          value={this.value}
          classes={combinedClasses}
          disabled={disabled}
          open={Boolean(open)}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
        >
          {this.options}
        </Select>
      </div>
    );
  }
}

export default withTranslation()(SelectWithLabel);
